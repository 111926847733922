import { useNode } from '@craftjs/core';
import { Slider, FormControl, FormLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ContentEditable from 'react-contenteditable';

export const HrLine = ({ height, width, ...props }) => {
  const {
    connectors: { connect, drag },
    selected,
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));

  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);

  return (
    <div
      {...props}
      ref={(ref) => connect(drag(ref))}
      onClick={() => selected && setEditable(true)}
    >
      <div className='w-42 m-2 h-2'>
      <hr
      className='h-px my-8 w-2vw bg-gray-200 border-0 dark:bg-gray-700' style={{height: `${height}%`, width: `${width}%`}}
      />
    </div>
    </div>
  );
};

export const HrSettings = () => {
  const {
    height,
    width,
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props,
    height: node.data.props.height,
    width: node.data.props.width,
  }));

  return (
    <div className='m-1 ml-4'>
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <FormLabel style={{fontWeight:"800",color:"white"}} component="legend">Height</FormLabel>
        <Slider
          key={`slider-${height}`}
          defaultValue={height}
          color="secondary"
          onChange={(_, value) =>
            setProp((props) => (props.height = value), 500)
          }
        />
      </FormControl>
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <FormLabel  style={{fontWeight:"800",color:"white"}} component="legend">Width</FormLabel>
        <Slider
          key={`slider-${width}`}
          defaultValue={width}
          color="secondary"
          onChange={(_, value) =>
            setProp((props) => (props.width = value), 500)
          }
        />
      </FormControl>
    </div>
  );
};

export const HrDefaultProps = {
  height: 10,
  width: 100,
};

HrLine.craft = {
  props: HrDefaultProps,
  related: {
    settings: HrSettings,
  },
};