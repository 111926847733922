import { useNode } from "@craftjs/core";
import {
  Button as MaterialButton,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@material-ui/core";
import React from "react";
import ContentEditable from "react-contenteditable";

export const Button = ({ size, variant, color, text, ...props }) => {
  const {
    connectors: { connect, drag },
  } = useNode();
  return (
    <MaterialButton
      ref={(ref) => connect(drag(ref))}
      style={{ margin: "5px" }}
      size={size}
      variant={variant}
      color={color}
      {...props}
    >
      {text}
    </MaterialButton>
  );
};

export const ButtonSettings = () => {
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props,
  }));

  return (
    <div className="ml-4 mt-1">
      <FormControl size="small" component="fieldset">
        <FormLabel style={{color:'white'}} component="legend">Size</FormLabel>
        <RadioGroup
          defaultValue={props.size}
          onChange={(e) => setProp((props) => (props.size = e.target.value))}
        >
          <FormControlLabel
          style={{color:'white'}}
            label="Small"
            value="small"
            control={<Radio size="small" color="secondary" />}
          />
          <FormControlLabel
          style={{color:'white'}}
            label="Medium"
            value="medium"
            control={<Radio size="small" color="secondary" />}
          />
          <FormControlLabel
          style={{color:'white'}}
            label="Large"
            value="large"
            control={<Radio size="small" color="secondary" />}
          />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset">
        <FormLabel style={{color:'white'}}  component="legend">Variant</FormLabel>
        <RadioGroup
          defaultValue={props.variant}
          onChange={(e) => setProp((props) => (props.variant = e.target.value))}
        >
          <FormControlLabel
          style={{color:'white'}}
            label="Text"
            value="text"
            control={<Radio size="small" color="secondary" />}
          />
          <FormControlLabel
          style={{color:'white'}}
            label="Outlined"
            value="outlined"
            control={<Radio size="small" color="secondary" />}
          />
          <FormControlLabel
          style={{color:'white'}}
            label="Contained"
            value="contained"
            control={<Radio size="small" color="secondary" />}
          />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset">
        <FormLabel style={{color:'white'}}  component="legend">Color</FormLabel>
        <RadioGroup
          defaultValue={props.color}
          onChange={(e) => setProp((props) => (props.color = e.target.value))}
        >
          <FormControlLabel
          style={{color:'white'}}
            label="Default"
            value="default"
            control={<Radio size="small" color="secondary" />}
          />
          <FormControlLabel
          style={{color:'white'}}
            label="Primary"
            value="secondary"
            control={<Radio size="small" color="secondary" />}
          />
          <FormControlLabel
          style={{color:'white'}}
            label="Secondary"
            value="secondary"
            control={<Radio size="small" color="secondary" />}
          />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset" className="flex flex-wrap">
        <FormLabel style={{color:'white'}}  component="legend">Edit button text</FormLabel>
        <ContentEditable
          html={props.text}
          className="my-2 p-2 w-24  text-white border border-gray-500 rounded-lg"
          onChange={(e) =>
            setProp(
              (props) =>
                (props.text = e.target.value.replace(
                  /<\/?[^>]+(>|$)/g,
                  "",
                  " "
                ))
            )
          }
          tagName="p"
        />
        
      </FormControl>
    </div>
  );
};

export const ButtonDefaultProps = {
  size: "small",
  variant: "contained",
  color: "secondary",
  text: "Submit",
};

Button.craft = {
  props: ButtonDefaultProps,
  related: {
    settings: ButtonSettings,
  },
};
