import { useEditor } from "@craftjs/core";
import { Button as MaterialButton, TextField } from "@material-ui/core";
import React from "react";
import { TbRectangleFilled, TbNumber } from "react-icons/tb";
import { RxContainer } from "react-icons/rx";
import { GoTextSize } from "react-icons/go";
import { MdEmail } from "react-icons/md";
import { BsCardText ,BsFillFileEarmarkImageFill,BsImageFill,BsTextareaT,BsCameraVideoFill,BsFillCheckSquareFill,BsCalendarDateFill,BsFillTelephoneFill} from "react-icons/bs";
import {FaUserCircle} from "react-icons/fa"
import {MdDriveFileRenameOutline,MdAudioFile,MdVideoFile,MdOutlineAccessTimeFilled} from "react-icons/md"
import {AiOutlineLine}from "react-icons/ai";
import { Button } from "./user/Button";
import { Card } from "./user/Card";
import { Text } from "./user/Text";
import { Name } from "./user/Name";
import { Email } from "./user/Email";
import { DateInput } from "./user/Date";
import { Time } from "./user/Time";
import { SampleImage } from "./user/SampleImage";
import { Image } from "./user/Image";
import { Input } from "./user/Input";
import {ImRadioChecked} from "react-icons/im"
import { Video } from "./user/Video";
import { HrLine } from "./user/HrLine";
import { SampleVideo } from "./user/SampleVideo";
import { Phone } from "./user/Phone";
import { Audio } from "./user/Audio";
import { SampleAudio } from "./user/SampleAudio";
import { Radio } from "./user/Radio";
import { CheckboxInput } from "./user/Checkbox";
import { NumberInput } from "./user/Number";
import { UserCard } from "./user/UserCard";
import { boldOnSearch } from "../../utils";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from '../UseWindowResize';

export const Toolbox = ({ isCollapsed }) => {
  const { width } = useWindowDimensions();
  const { connectors } = useEditor();
  const navigate = useNavigate();
  const list = [
    // {
    //   name: "Button",
    //   icon: (
    //     <TbRectangleFilled className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
    //   ),
    //   component: <Button text="Submit" size="small" />,
    //   color: "blue",
    //   styleColor : '#60a5fa'
    // },
    {
      name: "Text",
      icon: (
        <GoTextSize className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <Text text="Hi world" />,
      color: "pink",
      styleColor : '#f472b6'
    },
    {
      name: "Hrline",
      icon: (
        <AiOutlineLine className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <HrLine />,
      color: "gray",
      styleColor : '#9ca3af'
    },
    {
      name: "Email",
      icon: (
        <MdEmail className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <Email />,
      color: "green",
      styleColor:'#4ade80'
    },
    {
      name: "Name",
      icon: (
        <MdDriveFileRenameOutline className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <Name/>,
      color: "slate",
      styleColor:'#94a3b8'
    },
    {
      name: "Date",
      icon: (
        <BsCalendarDateFill className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <DateInput />,
      color: "yellow",
      styleColor:"#fbbf24"
    },
    {
      name: "Time",
      icon: (
        <MdOutlineAccessTimeFilled className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <Time />,
      color: "orange",
      styleColor : '#fb923c'
    },
    {
      name: "Number",
      icon: (
        <TbNumber className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <NumberInput />,
      color: "fuchsia",
      styleColor:'#2dd4bf'
    },
    {
      name: "S.Video",
      icon: (
        <MdVideoFile className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <SampleVideo />,
      color: "fuchsia",
      styleColor:'#2dd4bf'
    },
    {
      name: "S.Image",
      icon: (
        <BsFillFileEarmarkImageFill className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <SampleImage />,
      color: "fuchsia",
      styleColor:'#2dd4bf'
    },{
      name: "S.Audio",
      icon: (
        <MdAudioFile className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <SampleAudio />,
      color: "fuchsia",
      styleColor:'#2dd4bf'
    },
    {
      name: "Card",
      icon: (
        <BsCardText className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <Card />,
      color: "sky",
      styleColor:'#38bdf8'
    },
    {
      name: "UserCard",
      icon: (
        <FaUserCircle className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <UserCard  userData={{
        name:"John Doe",
        img: "https://source.unsplash.com/random/?music"
      }} 
      containerClass={'border-2 m-4 border-slate-500  bg-white rounded-lg p-2 justify-center inline-flex md:flex-row sm:flex-col flex-col lg:flex-col xl:flex-col 2xl:flex-auto'}
      />,
      color: "indigo",
      styleColor:'#818cf8'
    },
    {
      name: "radio",
      icon: (
        <ImRadioChecked className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <Radio/>,
      color: "gray",
      styleColor:"#94a3b8"
    },{
      name: "checkbox",
      icon: (
        < BsFillCheckSquareFill className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <CheckboxInput />,
      color: "rose",
      styleColor:"#fb7185"
    },{
      name: "Audio",
      icon: (
        <MdAudioFile className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <Audio />,
      color: "sky",
      styleColor:"#38bdf8"
    },{
      name: "phone",
      icon: (
        <BsFillTelephoneFill className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <Phone />,
      color: "green",
      styleColor:"#4ade80"
    },{
      name: "input",
      icon: (
        <BsTextareaT className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <Input />,
      color: "yellow",
      styleColor:'#facc15'
    },
    {
      name: "Video",
      icon: (
        <BsCameraVideoFill className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <Video />,
      color: "red",
      styleColor:'#f87171'
    },
    {
      name: "Image",
      icon: (
        <BsImageFill className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.25rem] 2xl:[3.5rem]" />
      ),
      component: <Image />,
      color: "violet",
      styleColor:'#a78bfa'
    },
  ];
  const [search, setSearch] = React.useState("");
  const [searchList, setSearchList] = React.useState(list);

  const onSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      setSearchList(list);
      return;
    }
    const filteredList = list.filter((item) => {
      return item.name.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setSearchList(filteredList);
  };

  return (
    <div className="overflow-hidden flex-auto w-full h-full flex-wrap">
      {!isCollapsed && (<>
        {width > 767 && 
        <div className="absolute top-20 left-4">
        <span
          onClick={() => navigate(-1)}
          className="cursor-pointer rounded-full bg-gray-400 p-2 top-0 left-0 w-8 h-8 flex flex-row items-center justify-center text-white border-2 border-white"
        >
          <i className="pi pi-angle-left" />
        </span>
      </div>}
        <div className="hidden flex-wrap text-cyan-600 justify-center w-full overflow-hidden text-xs font-bold sm:text-sm md:text-xl lg:text-2xl xl:text-2xl 2xl:text-3xl border-b-2 border-gray-300 py-4 md:flex lg:flex xl:flex 2xl:flex">
          Widgets
        </div>
      </>
      )}
      <div className="justify-center overflow-hidden mx-3 mt-2 p-2 mb-4 hidden md:flex lg:flex xl:flex 2xl:flex">
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          size="small"
          className="w-full"
          onChange={onSearch}
        />
      </div>
      <div className="flex flex-wrap justify-center overflow-auto h-auto py-2 gap-1 sm:gap-2 md:gap-2 xl:gap-3 2xl:gap-3 max-h-[100vh] sm:max-h-[100vh] md:max-h-[80vh] lg:max-h-[80vh] xl:max-h-[80vh] 2xl:max-h-[80vh]">
        {searchList?.map((item, index) => (
          <MaterialButton
            ref={(ref) => connectors.create(ref, item.component)}
            variant="outlined"
            color="default"
            data-cy="toolbox-button"
            key={index}
          >
            <div
              className={`flex flex-wrap text-xs rounded-md place-items-end justify-center w-8 h-10 md:w-10 md:h-12 lg:w-12 lg:h-16 xl:w-16 xl:h-18 2xl:w-18 2xl:h-20`}
              style={{color: `${item.styleColor}`}}
            >
              <div className="flex  bg-white rounded place-items-center justify-center">
                {item.icon}
              </div>
              <div className="text-[0.5rem] md:text-xs lg:text-sm xl:text-sm 2xl:text-sm">
                {boldOnSearch(item.name, search)}
              </div>
            </div>
          </MaterialButton>
        ))}
      </div>
    </div>
  );
};
