import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addUser } from "../../services/UserService";
import { Button } from "../Reusables/Button";
import { TextInput } from "../Reusables/TextInput";
import { allTask } from "../../services/ApiServices/TaskApiServices";
import { createUser } from "../../services/ApiServices/UserApiServices";
import { getCurrentUser, getUserByEmail, setCurrentUser } from "../../services/UserService";
import { arrangeTasks, saveAllTasks } from "../../services/UserTaskService";
import { FaList } from "react-icons/fa";
import './LoginUser.css'

export const LoginUser = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [isUserExists, setIsUserExists] = useState(false);
    const [isUserNotValid, setIsUserNotValid] = useState(false);

    const getTasksAPI = () => {
        allTask().then((data)=>{
          if(data.result){
            let tempData = data.result.sort((x,y)=> y.createdAt - x.createdAt).filter(dataTemp => dataTemp?.members?.some(data => data?.email.toLowerCase() === email.toLowerCase()) || dataTemp.owner.toLowerCase() === email.toLowerCase())
            let temp = arrangeTasks(tempData);
            saveAllTasks(temp)
          }
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const email = e.target.email.value
        let user = getUserByEmail(email);
        let userEmailId = user["email"];
        if (typeof (email) === "string" && email.length > 0) {
            let user = getUserByEmail(email);
            if (user.email !== undefined) {
                setCurrentUser(user);
                getTasksAPI();
                setTimeout(() => {
                navigate(`/task/${userEmailId}`);
                }, 200);
            }
            else {
                setIsUserNotValid(true);
                setEmail("");
            }
        }
    };

    return (
        <div className="flex flex-col items-center justify-center w-full h-screen bg-gradient-to-l from-slate-500 to-slate-100">
            <div className="container self-center rounded-md h-auto max-h-full bg-white justify-center p-4 overflow-auto w-auto min-w-[25%]">
            <div className="float-right"
            // className="absolute top-0 right-0 h-16 w-16"
            >
            <FaList title={'Select Users'} onClick={() => { navigate(`/user/select`)}}/>
            </div>
                <div className="flex flex-col items-center justify-center px-5 mx-auto my-8">
                    <div className="max-w-md text-center">
                        <p className="text-2xl font-semibold md:text-3xl">Login User</p>
                        <p className="mt-4 mb-8 dark:text-gray-500">
                            Please login a user to continue.
                        </p>
                        <form
                            onSubmit={onSubmit}
                            className="flex flex-col items-center justify-center"
                        >
                            <TextInput
                                label="Email"
                                placeholder="Enter User's Email"
                                type="email"
                                name="email"
                                mandatory={true}
                                id="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                isSearchBar={true}
                            />
                            <Button
                                type="submit"
                                label="Login"
                                disabled={!email?.length}
                            // children={<i className="mx-2 pi pi-plus" />}
                            />
                        </form>
                        {isUserExists && (
                            <p className="m-2 text-red-400 text-sm">User already exists.</p>
                        )}
                        {isUserNotValid && (
                            <p className="m-2 text-red-400 text-sm">Not a Valid User.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
