import constant from '../Constants';

// Task

export var allTask = () => {
    return fetch(constant.API_URL + '/api/tasks', {
        method: "GET",
        headers: { 'Content-Type': 'application/json' }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                console.log(">>Eror ", error)
                return error;
            }
        )
}

export var getTaskById = (taskId) => {
    return fetch(constant.API_URL + '/api/tasks/'+taskId, {
        method: "GET",
        headers: { 'Content-Type': 'application/json' }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                console.log(">>Eror ", error)
                return error;
            }
        )
}

export var createTask = (data) => {
    return fetch(constant.API_URL + '/api/tasks', {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                console.log(">>Eror ", error)
                return error;
            }
        )
}

export var updateTask = (id,data) => {
    return fetch(constant.API_URL + '/api/tasks/'+id, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                console.log(">>Eror ", error)
                return error;
            }
        )
}

export var deleteTask = (id) => {
    return fetch(constant.API_URL + '/api/tasks/'+id, {
        method: "DELETE",
        headers: { 'Content-Type': 'application/json' }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                console.log(">>Eror ", error)
                return error;
            }
        )
}