import { Editor, Element, Frame } from "@craftjs/core";
import { Card, CardBottom, CardTop } from "./user/Card";
import { Button } from "./user/Button";
import { Container } from "./user/Container";
import { UserCard } from "./user/UserCard";
import { Text } from "./user/Text";
import { Name } from "./user/Name";
import { NumberInput } from "./user/Number";
import { Email } from "./user/Email";
import { DateInput } from "./user/Date";
import { Time } from "./user/Time";
import { Phone } from "./user/Phone";
import { Image } from "./user/Image";
import { SampleImage } from "./user/SampleImage";
import { Input } from "./user/Input";
import { Video } from "./user/Video";
import { SampleVideo } from "./user/SampleVideo";
import { Audio } from "./user/Audio";
import { HrLine } from "./user/HrLine";
import { SampleAudio } from "./user/SampleAudio";
import { Radio } from "./user/Radio";
import { CheckboxInput} from "./user/Checkbox";

export const LayoutPreview = ({ layout, ...props }) => {
  return (
    <Editor
      resolver={{
        Card,
        Button,
        Text,
        Container,
        CardTop,
        CardBottom,
        UserCard,
        Name,
        NumberInput,
        Email,
        DateInput,
        Time,
        Image,
        SampleImage,
        Video,
        SampleVideo,
        Audio,
        SampleAudio,
        Phone,
        Input,
        CheckboxInput,
        Radio,
        HrLine
      }}
      enabled={false}
    >
      <Frame data={layout}>
        <Element />
      </Frame>
    </Editor>
  );
};
