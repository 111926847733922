import { useNode } from "@craftjs/core";
import React, { useState, useEffect, useRef } from "react";
import { MdOutlineClear } from "react-icons/md";
import { BsImageFill } from "react-icons/bs";
import { Slider, FormControl, FormLabel } from "@material-ui/core";

export const SampleImage = ({ Imagesize, ...props }) => {
  const {
    connectors: { connect, drag },
    selected,
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);
  return (
    <div
      {...props}
      ref={(ref) => connect(drag(ref))}
      onClick={() => selected && setEditable(true)}
    >
      <div>
        <div className="flex flex-wrap rounded-lg m-2  w-fit ">
         <img className="rounded-lg shadow-lg"
         src="https://media.istockphoto.com/id/455663609/photo/lion-sitting-looking-away-panthera-leo-10-years-old-isolated.jpg?s=612x612&w=0&k=20&c=H3wW7a0jAu-Y_hupVG0xwuP4EVZ4Gge2L5Y3gPN0jjE="
          style={{ width: `${Imagesize}px` }}
          alt=""
          ></img>
        </div>
      </div>
    </div>
  );
};
const ImageSettings = () => {
  const {
    actions: { setProp },
    Imagesize,
  } = useNode((node) => ({
    Imagesize: node.data.props.Imagesize,
  }));

  return (
    <div className="m-1 ml-4">
      <FormControl size="small" component="fieldset">
        <FormLabel style={{fontWeight:"800",color:"white" }} component="legend">Image size</FormLabel>
        <Slider
          value={Imagesize || 150}
          step={10}
          color="secondary"
          min={150}
          max={350}
          onChange={(_, value) => {
            setProp((props) => (props.Imagesize = value), 1000);
          }}
        />
      </FormControl>
    </div>
  );
};

export const ImageDefaultProps = {
  Imagesize: 150,
};

SampleImage.craft = {
  props: ImageDefaultProps,
  related: {
    settings: ImageSettings,
  },
};
