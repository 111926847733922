import React, { useState, useEffect } from "react";

export const LazyImage = ({ src, active, ...props }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImageSrc(src);
    };
  }, [src]);

  return (
    <img
      style={
        imageSrc
          ? active
            ? styles.loadedActive
            : styles.loaded
          : styles.loading
      }
      src={src}
      alt={props?.alt}
      {...props}
    />
  );
};
let styles = {
  loading: {
    filter: "blur(5px)",
    clipPath: "inset(0)",
  },
  loaded: {
    filter: "blur(0px)",
    transition: "filter 0.5s linear",
  },
  loadedActive: {
    filter: "blur(0px)",
    transition: "filter 0.5s linear",
    border: "2px solid #314361",
    boxShadow: "0px 0px 0px 6px #E2ECF9",
  },
};
