import { useEditor } from "@craftjs/core";
import { Button, Chip, Button as MaterialButton } from "@material-ui/core";
import { IoMdTrash, IoMdClose } from "react-icons/io";
import React, { useEffect } from "react";

export const SettingsPanel = ({setIsSettingsOpen}) => {
  const { actions, selected, isEnabled } = useEditor((state, query) => {
    const currentNodeId = query.getEvent("selected").last();
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable(),
      };
    }

    return {
      selected,
      isEnabled: state.options.enabled,
    };
  });

  useEffect(() => {
    if (selected) {
      setIsSettingsOpen(true);
    } else {
      setIsSettingsOpen(false);
    }
  }, [selected]);

  return isEnabled && selected ? (
    <div className="bg-cyan-800 border border-gray-500 w-full rounded-md p-2">
      <div className="flex flex-col">
        <div className="flex justify-between">
          <div className="flex">
            <span className="text-lg text-white " style={{fontSize:"1.5rem",fontWeight:"1000"}}>{selected.name}</span>
          </div>
          <div className="self-end">
          {selected?.isDeletable ? (
              <Button
                color="secondary"
                variant="contained"
                className="cursor-pointer"
                data-cy="chip-deletable"
                style={{marginRight:'0.3rem'}}
                onClick={() => actions.delete(selected.id)}
              >{<IoMdTrash className="" />}</Button>
            ) : null}
            <MaterialButton
              variant="contained"
              color="secondary"
              onClick={() => actions.selectNode(null)}
            >
              <IoMdClose />
            </MaterialButton>
          </div>
        </div>
        <span className="border-b border-white my-2" />
        <div className="flex flex-col">
          <div className="flex flex-auto my-2" data-cy="settings-panel">
            {selected.settings && React.createElement(selected.settings)}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
