import { Navigate } from "react-router-dom";
import { ErrorComp } from "../Components/ErrorComp";
import Main from "../Components/Main";
import { NotFound } from "../Components/NotFound";
import { AddUser } from "../Components/User/AddUser";
import { LoginUser } from "../Components/User/LoginUser"
import SelectUser from "../Components/User/SelectUser";
import UserCardDemo from "../Components/UserCardDemo";
import UserTasks from "../Components/Tasks/UserTasks";
import AllTasks from "../Components/Tasks/AllTasks";
import { TaskEditor } from "../Components/TaskMaker/TaskEditor";
import { TaskRead } from "../Components/TaskMaker/TaskRead";
import { Task } from "../Components/TaskMaker/Task";
import AllRoles from "../Components/Roles/AllRoles";
import {AddRole} from "../Components/Roles/AddRole";
import InviteUsers from "../Components/Roles/InviteUsers";
import { CheckUsersActivity } from "../Components/TaskMaker/CheckUsersActivity";
import CheckMembers from "../Components/Tasks/CheckMembers";
import AddDependency from "../Components/AddDependency";
import PreviewAllChildTasks from "../Components/Tasks/PreviewAllChildTasks";

/*react-router-dom-6 suppprts the following props:,
    path,
    id,
    loader,
    action,
    hasErrorBoundary,
    shouldRevalidate,
    handle,
    indexedDB,
    children,
    element,
    errorElement,
    Component,
    ErrorBoundary,
    lazy,
*/
const publicRoutes = [
  {
    path: "/",
    element: <LoginUser />,
    errorElement: <ErrorComp />,
  },
  // {
  //   path: "/",
  //   element: <Main />,
  //   children: [{ path: "/", element: <Main /> }],
  //   errorElement: <ErrorComp />,
  // },
  {
    path: "/task/:parentId/create",
    element: <TaskEditor />,
    errorElement: <ErrorComp />,
  },
  {
    path: "/task/create",
    element: <TaskEditor />,
    errorElement: <ErrorComp />,
  },
  {
    path: "/user/tasks",
    element: <UserTasks />,
    errorElement: <ErrorComp />,
  },
  {
    path: "/task/",
    element: <AllTasks />,
    errorElement: <ErrorComp />,
  },
  {
    path: "/task/:email",
    element: <AllTasks />,
    errorElement: <ErrorComp />,
  },
  {
    path: "/task/:taskId",
    // element: <PreviewAllChildTasks />,
    element: <AllTasks />,
    errorElement: <ErrorComp />,
  },
  {
    path: "/task/preview/:taskId",
    element: <PreviewAllChildTasks />,
    errorElement: <ErrorComp />,
  },
  {
    path: "/roles/invite/:role/:id",
    element: <InviteUsers/>,
    errorElement: <ErrorComp />,
  },
  {
    path: "/roles/invite/:role",
    element: <InviteUsers/>,
    errorElement: <ErrorComp />,
  },
  {
    path: "/roles/all/:id",
    element: <AllRoles/>,
    errorElement: <ErrorComp />,
  },
  {
    path: "/roles/all",
    element: <AllRoles/>,
    errorElement: <ErrorComp />,
  },
  {
    path: "/roles/add",
    element: <AddRole/>,
    errorElement: <ErrorComp />,
  },
  {
    path: "/roles/add/:id",
    element: <AddRole/>,
    errorElement: <ErrorComp />,
  },
  {
    path: "/task/view/:id",
    element: <TaskRead/>,
    errorElement: <ErrorComp />,
  },
  {
    path: "/depends/add/:parentId/:id",
    element: <AddDependency/>,
    errorElement: <ErrorComp />,
  },
  {
    path: "/task/:parentId/:id",
    element: <Task/>,
    errorElement: <ErrorComp />,
  },
  {
    path: "/task/members/:id",
    element: <CheckMembers/>,
    errorElement: <ErrorComp />,
  },
  {
    path: "/task/execution/:id",
    element: <CheckUsersActivity/>,
    errorElement: <ErrorComp />,
  },
  {
    path: "/user/create",
    element: <AddUser/>,
    errorElement: <ErrorComp />,
  },
  {
    path: "/user/card",
    element: <UserCardDemo />,
    errorElement: <ErrorComp />,
  },
  {
    path: "/user/select",
    element: <SelectUser />,
    errorElement: <ErrorComp />,
  },
  {
    path: "*",
    element: <Navigate to="404" />,
    errorElement: <ErrorComp />,
  },
  {
    path: "/404",
    element: <NotFound />,
    errorElement: <ErrorComp />,
  },
];

const protectedRoutes = [
  {
    path: "/test",
    element: <Main />,
    children: [{ path: "/", element: <Main /> }],
  },
];

export { publicRoutes, protectedRoutes };
