import constant from '../Constants';

// Role

export var allRole = () => {
    return fetch(constant.API_URL + '/api/roles', {
        method: "GET",
        headers: { 'Content-Type': 'application/json' }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                console.log(">>Eror ", error)
                return error;
            }
        )
}

export var createRole = (data) => {
    return fetch(constant.API_URL + '/api/roles', {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                console.log(">>Eror ", error)
                return error;
            }
        )
}

