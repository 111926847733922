const getDeviceBasedOnScreenSize = (height, width) => {
  const devices = ["mobile", "tablet", "laptop", "desktop", "largeDesktop"];
  const screenSize = Math.max(height, width);
  const device =
    screenSize < 600
      ? devices[0]
      : screenSize < 960
      ? devices[1]
      : screenSize < 1280
      ? devices[2]
      : screenSize < 1920
      ? devices[3]
      : devices[4];

  return device;
};

const boldOnSearch = (text, search) => {
  if (!search) {
    return text;
  }
  const index = text.toLowerCase().indexOf(search.toLowerCase());
  if (index === -1) {
    return text;
  }
  const first = text.slice(0, index);
  const middle = text.slice(index, index + search.length);
  const last = text.slice(index + search.length);
  return (
    <>
      {first}
      <b>{middle}</b>
      {last}
    </>
  );
};

const getChildTaskId = (childTaskId,taskId) => {
  if(!taskId){
      return childTaskId;
  }else{
      return `${taskId}-${childTaskId}`;
  }
}

const getEditTaskUrl = (childTaskId,taskId) => {
  if(!taskId){
      return `root/${childTaskId}`;
  }else{
      return `${taskId}/${childTaskId}`;
  }
}

export { getDeviceBasedOnScreenSize, boldOnSearch, getChildTaskId, getEditTaskUrl };
