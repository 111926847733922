import React, { useEffect, useState, useRef } from "react";
import  "./NavbarFooter.css";
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/5.0.0/normalize.min.css"></link>

const NavbarFooter = ({setSelectedRoleName, activeTab,setActiveTab,item,userEmailId}) => {
  
  const handleTabClick = (tabName) => {
    setActiveTab((prevTab) => (prevTab === tabName ? null : tabName));
    setSelectedRoleName(null)
  };

  return (
    <nav className="nav">
      {userEmailId === item.owner &&
      <button className={activeTab === 'roles' ? "nav-item is-active" : "nav-item"} active-color="orange"
      onClick={() => handleTabClick("roles")}>Roles</button>
      }
      <button className={activeTab === 'communication' ? "nav-item is-active" : "nav-item"} active-color="green"
      onClick={() => handleTabClick("communication")}
      >Communication</button>
      <button className={activeTab === 'status' ? "nav-item is-active" : "nav-item"} active-color="blue"
      onClick={() => handleTabClick("status")}
      >Status</button>
      {userEmailId === item.owner ?
      <><button className={activeTab === 'type' ? "nav-item is-active" : "nav-item"} active-color="red"
      onClick={() => handleTabClick("type")}>Type</button> </> : null
      }
      {/* <button className={activeTab === 'Menu 1' ? "nav-item is-active" : "nav-item"} active-color="red"
      onClick={() => handleTabClick("Menu 1")}>Menu1</button>
      <button className={activeTab === 'Menu 2' ? "nav-item is-active" : "nav-item"} active-color="red"
      onClick={() => handleTabClick("Menu 2")}>Menu2</button>*/}
      {/* <span className="nav-indicator"></span> */}
    </nav>
  );
};
<script src="../NavbarFooterscript.js"></script>
export default NavbarFooter;
