import { useNode } from "@craftjs/core";
import React, { useState, useEffect } from "react";
import { Slider, FormControl, FormLabel } from "@material-ui/core";

export const SampleAudio = ({ audioSize, ...props }) => {
  const {
    connectors: { connect, drag },
    selected,
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);

  return (
    <div
      {...props}
      ref={(ref) => connect(drag(ref))} 
      onClick={() => selected && setEditable(true)}
    >
      <div>
        <div className="flex flex-wrap rounded-lg m-2 w-fit ">
          <audio
            className="m-2"
            controls
            type="audio/mpeg,audio/ogg"
            src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
            style={{ width: `${audioSize}px` }}
          ></audio>
        </div>
      </div>
    </div>
  );
};
const AudioSettings = () => {
  const {
    actions: { setProp },
    audioSize,
  } = useNode((node) => ({
    audioSize: node.data.props.audioSize,
  }));

  return (
    <div className="m-1 ml-4">
      <FormControl size="small" component="fieldset">
        <FormLabel style={{ fontWeight: "800", color: "white" }} component="legend">Audio width</FormLabel>
        <Slider
          value={audioSize || 100}
          step={10}
          color="secondary"
          min={150}
          max={265}
          onChange={(_, value) => {
            setProp((props) => (props.audioSize = value), 1000);
          }}
        />
      </FormControl>
    </div>
  );
};

export const AudioDefaultProps = {
  audioSize: 220,
};

SampleAudio.craft = {
  props: AudioDefaultProps,
  related: {
    settings: AudioSettings,
  },
};

