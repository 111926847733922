import React, { useEffect, useState } from "react";
import {
  getCurrentUser,
  getUsers,
  setCurrentUser,
} from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import { Button } from "../Reusables/Button";
import { UserCard } from "../UserCard";
import DummyUser from "../../assets/Images/user.webp";
import { NavBar } from "../NavBar";
import { allUser } from "../../services/ApiServices/UserApiServices";

const SelectUser = () => {
  const navigate = useNavigate();
  // const users = getUsers() || [];
  const [users,setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(getCurrentUser());

  const onUserClick = (user) => {
    if (!user) return;
    //navigate("/user/tasks");
    setCurrentUser(user);
    navigate(`/task/${user.email}`);
  };

  const getAllUsers = () => {
    allUser().then((data)=>{
      if(data.result){
        setUsers(data.result)
        localStorage.setItem("users", JSON.stringify(data.result));
      }
    })
  }

  useEffect(()=> {
    getAllUsers()
  },[])

  return (
    <><NavBar />
    <div className="flex flex-col items-center justify-center w-full h-screen bg-gradient-to-l from-slate-500 to-slate-200">
      <div className="absolute top-20 left-4">
        <span
          onClick={() => navigate(-1)}
          className="cursor-pointer rounded-full bg-gray-400 p-2 top-0 left-0 w-8 h-8 flex flex-row items-center justify-center text-white border-2 border-white"
        >
          <i className="pi pi-angle-left" />
        </span>
      </div>
      <div className="container self-center rounded-md h-auto max-h-full bg-white justify-center p-4 overflow-auto w-auto min-w-[25%]">
        <div className="flex flex-col items-center justify-center overflow-auto">
          {users?.length > 0 ? (
            <>
              <div className="max-w-md text-center">
                <p className="text-2xl font-semibold md:text-3xl">
                  Select User
                </p>
                <p className="mt-4 mb-8 dark:text-gray-500">
                  Please select user to continue.
                </p>
              </div>
              {selectedUser && (
                <div className="flex flex-row items-center justify-center">
                  <div className="flex flex-row items-center justify-center">
                    <i className="pi pi-check-circle text-green-400 text-2xl" />
                    <p className="text-lg font-semibold ml-2">
                      {selectedUser?.email} &nbsp;
                    </p>
                  </div>
                  <Button
                    onClick={() => {
                      setCurrentUser(null);
                      setSelectedUser(null);
                    }}
                    children={<i className="mx-2 pi pi-trash" />}
                    className="w-auto text-red-400"
                  />
                </div>
              )}

              <div className="flex flex-wrap justify-center flex-auto">
                <Button
                  type="submit"
                  label="Add User"
                  onClick={() => navigate("/user/create")}
                  children={<i className="mx-2 pi pi-plus" />}
                />
              </div>
              <div className="flex flex-wrap justify-center flex-auto">
                {users?.map((user) => (
                  <UserCard
                    key={user?._id}
                    userData={{
                      id: user?._id,
                      name: user?.email,
                      email: user?.email,
                      img: DummyUser,
                      imgType: "Circle",
                    }}
                    onClick={() => onUserClick(user)}
                    containerClass={`m-4 bg-slate-300 rounded-lg p-2 justify-center flex md:flex-row sm:flex-col flex-col lg:flex-col xl:flex-col 2xl:flex-auto ${
                      selectedUser?._id === user?._id
                        ? "border-2 border-green-400"
                        : ""
                    }`}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="max-w-md text-center flex flex-auto justify-center flex-col">
              <p className="text-2xl font-semibold md:text-3xl">
                No users found.
              </p>
              <p className="mt-4 mb-8 dark:text-gray-500">
                Please add a user to continue.
              </p>
              <Button
                onClick={() => navigate("/user/create")}
                label="Add User"
                children={<i className="mx-2 pi pi-plus" />}
                className="w-auto justify-center"
              />
            </div>
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default SelectUser;
