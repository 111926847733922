import React from "react";
import { AiFillEdit, AiFillFolderOpen, AiFillDelete } from "react-icons/ai";
import { useParams } from "react-router-dom";

const DropdownMenu = ({ task, navigate, handleListDeleteTask, item, getChildTaskId, taskId, isGridView}) => {
  const checkChildParentId = () => {
    return item._id + "-" + task._id
  }
  return (
    <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1" style={{ width: '10rem', marginTop: (isGridView ? '9rem' : '') }}>
      <div className="py-1" role="none">
        <span className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-1" style={{ lineHeight: '1.50rem' }}>
          <div className="flex items-center">
            <span className="task-name" style={{ fontWeight: '900' }}>{task.name} :</span>
          </div>
          <div className="flex items-center dropdown-parent" onClick={() => { navigate(`/task/${item._id}/${task._id}`) }} >
            <AiFillEdit />
            <span className="dropdown-items">Edit Task</span>
          </div>
          <div className="flex items-center dropdown-parent" onClick={() => { item?.tasks?.length > 0 && navigate(`/task/preview/${getChildTaskId(checkChildParentId(),taskId)}`) }}>
            <AiFillFolderOpen />
            <span className="dropdown-items">Show Subtasks</span>
          </div>
          <div className="flex items-center dropdown-parent" onClick={() => handleListDeleteTask(item._id, task._id)}>
            <AiFillDelete onClick={() => handleListDeleteTask(item._id, task._id)} />
            <span className="dropdown-items">Delete Task</span>
          </div>
        </span>
      </div>
    </div>
  );
};

export default DropdownMenu;
