import React, { useState } from "react";
import './Snack.css';
import { HiInformationCircle } from "react-icons/hi";
import { BiUser } from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { HiUsers } from "react-icons/hi";
import { VscOutput } from "react-icons/vsc";
import { TbSubtask } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";

export const Snack = ({ item, show, outputoftask,setShowSnack, showSnack, setValue, setContentView,setChildView,
  arrowsetDropdownOpen}) => {
  const [close, setClose] = useState(false);
  const navigate = useNavigate();
  
  const checkResponse = () => {
    setValue(true)
    setShowSnack(!showSnack)
    setContentView(false)
    setChildView(true)
    arrowsetDropdownOpen(false)
  }

  return (
    <div
      className={`my-snack ${show ? "show" : ""} ${close ? "hide" : ""}`}
    >
    <div className="preview-list">
        <span type="span" className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-b-lg" 
        style={{fontWeight: '900',fontSize:'1.5rem'}}>
            Task Information
        </span>
        <span type="span" className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-b-lg">
            <BiUser aria-hidden="true" className="w-4 h-4 mr-2 fill-current"></BiUser>
            Owner Name :
            <span className="preview-data">{item ? item?.owner : ("No Name")}</span>
        </span>
        <span type="span" className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-b-lg">
            <HiUsers aria-hidden="true" className="w-4 h-4 mr-2 fill-current"></HiUsers>
            Total Member Assign :
            <span className="preview-data" onClick={() => { item?.tasks?.length > 0 && navigate(`/task/members/${item._id}`)}}>{item?.members?.length>0 ? item?.members?.length : 0}</span>
        </span>
        <span type="span" className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-b-lg">
            <VscOutput aria-hidden="true" className="w-4 h-4 mr-2 fill-current"></VscOutput>
            Total Output :
            <span className="preview-data" onClick={() => { item?.tasks?.length > 0 && navigate(`/task/execution/${item._id}`)}}>{outputoftask.length>0 ? outputoftask.length : 0}</span>
        </span>
        <span type="span" className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-b-lg">
            <TbSubtask aria-hidden="true" className="w-4 h-4 mr-2 fill-current"></TbSubtask>
            Total Subtask :
            <span className="preview-data" onClick={() => {item?.tasks?.length > 0 && checkResponse()}}>{item?.tasks?.length>0 ? item?.tasks?.length : 0}</span>
        </span>
    </div>

    </div>
  );
};
