import { getAllOutputs } from "./ApiServices/OutputApiServices";

let outputKeyName = 'output';

const getOutput = () => {
    const output = localStorage.getItem(outputKeyName) || [];
    if (output.length > 0) {
        return JSON.parse(output);
    }
    return [];
};

export const saveAllOutput = (userOutput)=>{
    localStorage.setItem(outputKeyName, JSON.stringify(userOutput));
}

export const saveOutput = (userOutput) => {
    let outputData = localStorage.getItem(outputKeyName) || [];
    outputData = JSON.parse(outputData);
    if(!userOutput._id){
        let data = {
            ...userOutput
        }
        data._id = generateId();
        addUserOutput(data)
    }else{
        let idFound = false
        const updatedOutput = outputData.map((item) => {
            if (item.taskId === userOutput.taskId && item.memberDetails.id === userOutput.memberDetails.id) {
                idFound = true;
                return userOutput;
            } else {
                return item;
            }
        })
        if(!idFound){
            updatedOutput.push(userOutput)
          }
        saveAllOutput(updatedOutput)
    }
}

export const addUserOutput = (userOutput) => {
    let userOutputs = getOutput();
    if (userOutputs && userOutputs.length) {
        userOutputs.push(userOutput);
    } else {
        userOutputs = [userOutput];
    }
    saveAllOutput(userOutputs);
};

export const getUserAllOutput = async(id) => {
    let data = await getAllOutputs();
    const tasks = data.result.filter((item) => item.taskId === id);
    return tasks;
};

export const getUserOutput = (id,userId) => {
    let userOutput = getOutput();
    const tasks = userOutput.find((item) => item.taskId === id && item.memberDetails.id === userId);
    return tasks;
};

export const generateId = (length = 10) => {
    const chars =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = "";
    for (let i = length; i > 0; --i)
        result += chars[Math.floor(Math.random() * chars.length)];
    return result;
};

const output = []

const initOutput = () => {
    if (!localStorage.getItem(outputKeyName)) {
        localStorage.setItem(outputKeyName, JSON.stringify(output));
    }
}
initOutput();
export { getOutput };
