import React from "react";
import { Checkbox } from "primereact/checkbox";

export const RolesCheckbox = ({ selectedRoles, setSelectedRoles }) => {
  const categories = [
    { name: "Owner", key: "O" },
    { name: "Executor", key: "E" },
    { name: "Viewer", key: "V" },
  ];
  const onRoleSelect = (e) => {
    if (!selectedRoles) return;
    let existingRoles = [...selectedRoles];
    if (e.checked) existingRoles.push(e.value);
    else existingRoles = existingRoles.filter((val) => val.key !== e.value.key);
    setSelectedRoles(existingRoles);
  };

  return (
    <div className="flex justify-content-center">
      <div className="flex flex-column gap-3">
        {categories.map((category) => {
          return (
            <div key={category.key} className="flex align-items-center">
              <Checkbox
                inputId={category.key}
                name="category"
                value={category}
                onChange={onRoleSelect}
                checked={selectedRoles.some(
                  (val) => val?.key === category?.key
                )}
              />
              <label htmlFor={category.key} className="ml-2">
                {category.name}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
