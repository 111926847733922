import { useNode } from "@craftjs/core";
import React, { useState, useEffect, useRef } from "react";
import { MdOutlineClear } from "react-icons/md";
import { BsImageFill } from "react-icons/bs";
import { Slider, FormControl, FormLabel } from "@material-ui/core";

export const Image = ({ Imagesize, imgSrc , ...props }) => {
  const [createBase64Preview ,setCreateBase64Preview] =useState([]);
  const {
    connectors: { connect, drag },
    selected,
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);

  const [imagePreview, setImagePreview] = useState(imgSrc || null);
  const isReadOnly = window.location.href.includes('view')

  // FIle Picker Ref because we are not useing the standard File picker input
  const filePicekerRef = useRef(null);

  function previewFile(e) {
    // Reading New File (open file Picker Box)
    const reader = new FileReader();

    // Gettting Selected File (user can select multiple but we are choosing only one)
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    // As the File loaded then set the stage as per the file type
    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes("image")) {
        setImagePreview(readerEvent.target.result);
        setProp(
          (props) =>
            (props.imgSrc = readerEvent.target.result),
          500
        )
      }
    };
  }

  function clearFiles() {
    setImagePreview(null);
  }

  return (
    <div
      {...props}
      ref={(ref) => connect(drag(ref))}
      onClick={() => selected && setEditable(true)}
    >
      <div>
        <div className="flex flex-col rounded-lg m-2  bg-gray-300 w-fit ">
          <div className="btn-container" style={{
            display: isReadOnly ? 'none' : 'block',
          }}>
            <input
              ref={filePicekerRef}
              accept="image/*"
              onChange={previewFile}
              type="file"
              hidden
            />
            <div className="flex flex-wrap">
              <button
                className="btn flex bg-white font-semibold text-gray-500 p-2 m-2 rounded"
                onClick={() => filePicekerRef.current.click()}
              >
                Choose Image
                <BsImageFill className="ml-2 mt-1 mr-1" />
              </button>
              {imagePreview && (
                <button
                  className="btn text-red-200 border bg-red-500 m-2 rounded p-2"
                  onClick={clearFiles}
                >
                  <MdOutlineClear />
                </button>
              )}
            </div>
          </div>
          {(imagePreview != null && (
              <img
                className="w-fit m-2 rounded-md shadow-lg"
                src={imagePreview}
                style={{ width: `${Imagesize}px` }}
                alt=""
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const ImageSettings = () => {
  const {
    actions: { setProp },
    Imagesize,
  } = useNode((node) => ({
    Imagesize: node.data.props.Imagesize
  }));

  return (
    <div className="m-1 ml-4">
      <FormControl size="small" component="fieldset">
        <FormLabel style={{fontWeight:"800",color:"white" }} component="legend">Image size</FormLabel>
        <Slider
          value={Imagesize || 200}
          step={10}
          min={200}
          max={500}
          color="secondary"
          onChange={(_, value) => {
            setProp((props) => (props.Imagesize = value), 1000);
          }}
        />
      </FormControl>
    </div>
  );
};

export const ImageDefaultProps = {
  Imagesize: 200
};

Image.craft = {
  props: ImageDefaultProps,
  related: {
    settings: ImageSettings,
  },
};
