import React from "react";
import { LazyImage } from "./Reusables/LazyImage";

export const UserCard = ({
  userData,
  imgClassName,
  imgProps,
  onClick,
  containerClass,
}) => {
  return (
    <div
      className={
        containerClass ||
        "border-2 m-4 border-slate-500  bg-white rounded-lg p-2 justify-center flex md:flex-row sm:flex-col flex-col lg:flex-col xl:flex-col 2xl:flex-auto"
      }
      style={{
        cursor: onClick ? "pointer" : "default",
      }}
      onClick={onClick}
    >
      <div className="relative flex flex-col justify-center items-center">
        <span
          className={`absolute animate-bounce z-10 right-0 top-0 items-center justify-center w-3 h-3 rounded-full ${
            userData?.activityStatus || "bg-yellow-500"
          }`}
        />
        <LazyImage
          src={userData?.img}
          alt=""
          className={
            imgClassName ||
            "w-[10vh] h-[10vh] object-cover rounded-full sm:w-20 sm:h-20 md:w-24 md:h-24 lg:w-32 lg:h-32 xl:w-40 xl:h-40 shadow-xl"
          }
          loading="lazy"
          {...imgProps}
        />
      </div>
      <div className="flex xl:ml-0 lg:m-0 2xl:m-0 md:m-0 sm:ml-0 -mt-1 text-center lg:text-md xl:text-base w-auto opacity-80 flex-wrap flex-col flex-auto bg-slate-600 p-2 rounded-lg self-center text-sm font-medium shadow-2xl max-w-[10rem] min-w-[8rem]">
        <label className="text-white font-bold">
          {userData?.name?.length > 10
            ? userData?.name?.slice(0, 10) + "..."
            : userData?.name}
        </label>
      </div>
    </div>
  );
};
