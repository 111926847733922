import { allRole } from "./ApiServices/RoleApiServices";

const getRoles = () => {
    const roles = localStorage.getItem("roles") || [];
    if (roles.length > 0) {
      return JSON.parse(roles);
    }
    return [];
};

export const getRoleByName = (name) => {
  let roles = getRoles();
  const role = roles.filter((item) => item.name === name);
  return role;
};

const addRole = (payload) => {
    let roles = localStorage.getItem("roles") || [];
    try {
      if (roles.length > 0) {
        roles = JSON.parse(roles);
        const role = roles.find((role) => role.name === payload.name);
        if (role) return false;
      }
      roles.push(payload);
      localStorage.setItem("roles", JSON.stringify(roles));
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };
  
  const dummyRoles = [
    { id: 0, name: 'Master', permissions: ["Owner"] },
    { id: 1, name: 'Student', permissions: ["Viewer"] },
    { id: 2, name: 'Principal', permissions: ["Owner","Executor"] }
  ]

  const initDummyRoles = async() => {
    let user = await allRole();
    if (!localStorage.getItem("roles")) {
      localStorage.setItem("roles", JSON.stringify(user.result));
    }
  }
  initDummyRoles();
  export { getRoles, addRole };
  