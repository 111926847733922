import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { addUser, getUsers, updateUser } from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import { Button } from "../Reusables/Button";
import { UserCard } from "../UserCard";
import DummyUser from "../../assets/Images/user.webp";
import { addUpdateTask, getUserTask } from "../../services/UserTaskService";
import { Snackbar, Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";
import { Slide } from "@material-ui/core";
import { getRoleByName } from "../../services/RoleService";
import { getCurrentUser } from "../../services/UserService";
import { FiUserCheck } from "react-icons/fi"
import { NavBar } from "../NavBar";
import "./InviteUsers.css"
import { getTaskById, updateTask } from "../../services/ApiServices/TaskApiServices";

const InviteUsers = () => {
  const navigate = useNavigate();
  const { role, id } = useParams();
  const currentUser = getCurrentUser();
  const currentUserEmail = currentUser !== null && currentUser["email"];
  let splitIds = id && id.split("-");
  splitIds = splitIds.pop(splitIds.length - 1);
  const users = getUsers() || [];
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const checkRole = getRoleByName(role);

  // const roleFromTaskByName = (name) => {
  //   const task = getUserTask(id);
  //   const role = task?.roleDef?.find((r) => r?.name?.toLowerCase() === name?.toLowerCase());
  //   return role;
  // };
  const onUserClick = (user) => {
    if (!user) return;
    let existingUser = selectedUsers?.find((u) => u?._id === user?._id);
    if (existingUser) {
      setSelectedUsers(
        selectedUsers?.filter((u) => u?._id !== existingUser?._id)
      );
    } else {
      setSelectedUsers([
        ...selectedUsers,
        {
          ...user,
          roleId: checkRole[0]._id
        },
      ]);
    }
  };

  const isMemberExists = (user) => {
    const task = getUserTask(id);
    const memberExists = task?.members?.find((m) => m?._id === user?._id);
    return memberExists;
  };

  const onAddUser = async() => {
    let checkId = false;
    if(id.includes('-')){
      checkId = true
    }
    if (!id) {
      updateUser(selectedUsers[0].email, { roleId: checkRole[0]._id })
      navigate(`/task/`);
    } else {
      const data = await getTaskById(checkId ? splitIds : id);
      const task = data.result;
      if (!task || !selectedUsers?.length) return;
      let members = selectedUsers.map(obj => ({ userId:obj._id, roleId: checkRole[0]._id, email:obj.email,status: 'I' }));
      const payload = {
        ...task,
        members: task?.members ? task?.members.concat(members) : []
      };
      let setId = splitIds.toString().replaceAll(',', '-');
      updateTask(checkId ? splitIds : id,payload)
      // addUpdateTask(payload,setId);
      setSnackbarMessage("Users added successfully");
      navigate(`/task/${currentUserEmail}`);
      // setTimeout(() => {
      //   navigate(`/task/${setId}`);
      // }, 3000);
    }
  };

  const onAddAllUser = () => {
    const task = getUserTask(id);
    if (!task || !users?.length) return;
    let members = users.map(obj => ({ ...obj, roleId: checkRole[0]._id, status: 'I' }));
    const payload = {
      ...task,
      members: task?.members ? task?.members.concat(members) : []
    };
    let setId = splitIds.toString().replaceAll(',', '-');
    addUpdateTask(payload, setId);
    setSnackbarMessage("All Users are added successfully");
    navigate(`/task/${currentUserEmail}`);
    // setTimeout(() => {
    //   navigate(`/task/${setId}`);
    // }, 3000);
  }

  return (
    <React.Fragment>
      <NavBar />
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={3000}
        onClose={() => setSnackbarMessage("")}
        message={snackbarMessage}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
      />
      <div className="flex flex-col items-center justify-center w-full h-screen bg-gradient-to-l from-slate-500 to-slate-200">
        <div className="absolute top-20 left-4">
          <span
            onClick={() => navigate(-1)}
            className="cursor-pointer rounded-full bg-gray-400 p-2 top-0 left-0 w-8 h-8 flex flex-row items-center justify-center text-white border-2 border-white"
          >
            <i className="pi pi-angle-left" />
          </span>
        </div>
        <div className="container self-center rounded-md h-auto max-h-full bg-white justify-center p-4 overflow-auto w-auto min-w-[25%]">
          <div className="flex flex-col items-center justify-center overflow-auto">
            {users?.length > 0 ? (
              <>
                <div className="max-w-md text-center">
                  <p className="text-2xl md:text-3xl">
                    Add to <b className="capitalize">{role}</b> role
                  </p>
                  <p className="mt-4 mb-8 dark:text-gray-500">
                    Please select users to continue.
                  </p>
                </div>
                {/* {selectedUser && (
                <div className="flex flex-row items-center justify-center">
                  <div className="flex flex-row items-center justify-center">
                    <i className="pi pi-check-circle text-green-400 text-2xl" />
                    <p className="text-lg font-semibold ml-2">
                      {selectedUser?.email} &nbsp;
                    </p>
                  </div>
                  <Button
                    onClick={() => {
                      setCurrentUser(null);
                      setSelectedUser(null);
                    }}
                    children={<i className="mx-2 pi pi-trash" />}
                  />
                </div>
              )} */}

                <div className="flex flex-wrap justify-center flex-auto">
                  <Button
                    type="submit"
                    label={
                      selectedUsers?.length
                        ? `Add ${selectedUsers?.length} ${selectedUsers?.length > 1 ? "users" : "user"
                        }`
                        : "Add user"
                    }
                    onClick={onAddUser}
                    children={<i className="mx-2 pi pi-plus" />}
                    disabled={!selectedUsers?.length}
                  />
                  {/* <FormGroup style={{margin:'auto'}}>
                    <FormControlLabel control={
                      <Checkbox
                        label={
                          // users?.length
                          //   ? `Add ${users?.length} ${
                          //     usersq?.length > 1 ? "users" : "user"
                          //     }`
                          //   : 
                          "Add All user"
                        }
                        onClick={onAddAllUser}
                        // children={<i className="mx-2 pi pi-plus" />}
                        disabled={selectedUsers?.length}
                      />}
                      label="Add all " />
                  </FormGroup> */}
                  </div>
                {/* <div className="flex flex-wrap justify-center flex-auto button-add-all" style={{right:'0', position:'relative'}}>
                <Button
                  type="submit"
                  label={
                    // users?.length
                    //   ? `Add ${users?.length} ${
                    //     usersq?.length > 1 ? "users" : "user"
                    //     }`
                    //   : 
                      "Add All user"
                  }
                  onClick={onAddAllUser}
                  children={<i className="mx-2 pi pi-plus" />}
                  disabled={selectedUsers?.length}
                />
              </div> */}
                <div className="flex flex-wrap justify-center flex-auto">
                  {users?.map((user) => (
                    <UserCard
                      key={user?._id}
                      userData={{
                        id: user?._id,
                        name: user?.email,
                        email: user?.email,
                        img: DummyUser,
                        imgType: "Circle",
                      }}
                      onClick={() => onUserClick(user)}
                      containerClass={`m-4 bg-slate-300 rounded-lg p-2 justify-center flex md:flex-row sm:flex-col flex-col lg:flex-col xl:flex-col 2xl:flex-auto ${selectedUsers?.find((u) => u?._id === user?._id)
                          ? "border-2 border-blue-500"
                          : ""
                        }`}
                    />
                  ))}
                </div>
              </>
            ) : (
              <div className="max-w-md text-center flex flex-auto justify-center flex-col">
                <p className="text-2xl font-semibold md:text-3xl">
                  No users found.
                </p>
                <p className="mt-4 mb-8 dark:text-gray-500">
                  Please add a user to continue.
                </p>
                <Button
                  onClick={() => navigate("/user/create")}
                  label="Add User"
                  children={<i className="mx-2 pi pi-plus" />}
                  className="w-auto justify-center"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InviteUsers;
