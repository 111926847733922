import "./App.css";
//core
import "primereact/resources/primereact.min.css";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//icons
import "primeicons/primeicons.css";
import Routes from "./routes/routes";

function App() {
  return (
    <div className="overflow-hidden">
      <Routes />
    </div>
  );
}

export default App;
