import { useNode } from "@craftjs/core";
import React, { useState, useEffect, useRef } from "react";
import { Slider, FormControl, FormLabel } from "@material-ui/core";

export const SampleVideo = ({ Imagesize, ...props }) => {
  const {
    connectors: { connect, drag },
    selected,
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);
  return (
    <div
      {...props}
      ref={(ref) => connect(drag(ref))}
      onClick={() => selected && setEditable(true)}
    >
      <div>
        <div className="flex flex-col rounded-lg m-2 w-fit ">
          <video
            src="http://media.developer.dolby.com/Atmos/MP4/shattered-3Mb.mp4"
            controls
            autoPlay
            poster="http://media.developer.dolby.com/Atmos/MP4/shattered-3Mb.mp4"
            className="shadow-2xl rounded-lg"
            style={{ width: `${Imagesize}px` }}
          ></video>
        </div>
      </div>
    </div>
  );
};
const ImageSettings = () => {
  const {
    actions: { setProp },
    Imagesize,
  } = useNode((node) => ({
    Imagesize: node.data.props.Imagesize,
  }));

  return (
    <div className="m-1 ml-4">
      <FormControl size="small" component="fieldset">
        <FormLabel style={{fontWeight:"800",color:"white" }} component="legend">Video size</FormLabel>
        <Slider
          value={Imagesize || 150}
          step={10}
          min={150}
          max={350}
          color="secondary"
          onChange={(_, value) => {
            setProp((props) => (props.Imagesize = value), 1000);
          }}
        />
      </FormControl>
    </div>
  );
};

export const ImageDefaultProps = {
  Imagesize: 250,
};

SampleVideo.craft = {
  props: ImageDefaultProps,
  related: {
    settings: ImageSettings,
  },
};
