import sammple1 from "../data/sample-post-554654.json";
import sammple2 from "../data/sample-post-556666.json";
import sammple3 from "../data/sample-post-554657.json";
import sammple4 from "../data/sample-post-554655.json";

const taskArray = [sammple1, sammple2, sammple3, sammple4];

export const addTaskToLocalStorage = () => {
  localStorage.setItem("tasks", JSON.stringify(taskArray));
};

const initData = () => {
  if (localStorage.getItem("tasks") == null) {
    addTaskToLocalStorage();
  }
};

export const addTask = (task) => {
  const taskData = getTasks();
  if (!taskData) return;
  taskData.push(task);
  localStorage.setItem("tasks", JSON.stringify(taskData));
};

export const updateTask = (task) => {
  const taskData = getTasks();
  if (!taskData) return;
  const index = taskData.findIndex((t) => t._id === task._id);
  if (index === -1) return;
  taskData[index] = task;
  localStorage.setItem("tasks", JSON.stringify(taskData));
};

export const addSubTaskToLastTask = (task) => {
  const taskData = getTasks();
  if (!taskData) return;
  taskData[taskData.length - 1].tasks.push(task);
  localStorage.setItem("tasks", JSON.stringify(taskData));
};

export const updateSubTask = (task) => {
  const taskData = getTasks();
  if (!taskData) return;
  const index = taskData.findIndex((t) => t._id === task.id);
  if (index === -1) return;
  const subTaskIndex = taskData[index]?.tasks?.findIndex(
    (t) => t._id === task._id
  );
  if (subTaskIndex === -1) return;
  taskData[index].tasks[subTaskIndex] = task;
  localStorage.setItem("tasks", JSON.stringify(taskData));
};

export const deleteTask = (id) => {
  const taskData = getTasks();
  if (!taskData) return;
  const index = taskData.findIndex((t) => t._id === id);
  if (index === -1) return;
  taskData.splice(index, 1);
  localStorage.setItem("tasks", JSON.stringify(taskData));
};

export const deleteSubTask = (id) => {
  const taskData = getTasks();
  if (!taskData) return;
  const index = taskData.findIndex((t) => t._id === id);
  if (index === -1) return;
  taskData[index].tasks.splice(index, 1);
  localStorage.setItem("tasks", JSON.stringify(taskData));
};

export const getTasks = () => {
  let taskData = [];
  try {
    taskData = JSON.parse(localStorage.getItem("tasks"));
  } catch (e) {
    console.error("post local storage parse data.");
  }
  return taskData;
};

//initData();
