import { useEditor } from "@craftjs/core";
import { useParams } from "react-router";
import { Snackbar, TextField } from "@material-ui/core";
import React, { useEffect, useState, useRef} from "react";
import { Button } from "../Reusables/Button";
import { FaUndo, FaRedo } from "react-icons/fa";
import { IoIosArrowDropdown } from "react-icons/io";
import { BsFillGridFill } from "react-icons/bs";
import { FaThList } from "react-icons/fa";
import { MdPreview } from "react-icons/md";

import { useNavigate, useLocation } from "react-router-dom";
import {
  getUserTask,
  addUpdateTask,
  generateId,
  arrangeTasks,
  saveAllTasks,
} from "../../services/UserTaskService";
import "./Topbar.css"
import { getCurrentUser } from "../../services/UserService";
import { SettingsPanel } from "./SettingsPanel";
import { allTask, createTask, updateTask } from "../../services/ApiServices/TaskApiServices";

export const Topbar = ({checkChangeEvent, isSettingsOpen, setIsSettingsOpen}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const parentRef = useRef(null);
  let { id,parentId} = useParams();
  if(parentId && parentId!='root'){
    id = `${parentId}-${id}`;
  }
  const { actions, query, enabled, canUndo, canRedo } = useEditor(
    (state, query) => ({
      enabled: state.options.enabled,
      canUndo: state.options.enabled && query.history.canUndo(),
      canRedo: state.options.enabled && query.history.canRedo(),
    })
  );
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [taskName, setTaskName] = useState("");
  const [currentTask, setCurrentTask] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isarrowDropdownOpen, arrowsetDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);


  const getAllTasks = () => {
    allTask().then((data)=>{
      if(data.result){
        let tempData = data.result.sort((x,y)=> y.createdAt - x.createdAt).filter(dataTemp => dataTemp?.members?.some(data => data?.email.toLowerCase() === getCurrentUser().email.toLowerCase()) || dataTemp.owner.toLowerCase() === getCurrentUser().email.toLowerCase())
        let temp = arrangeTasks(tempData);
        saveAllTasks(temp)
      }
    })
  }

  const handleSave = () => {
    let checkCurrentIds, checkParentIds;
    if(parentId !== 'root'){
      checkCurrentIds = id.split('-').length > 0 && id.split('-')[id.split('-').length - 1];
      checkParentIds = id.split('-').length > 0 && id.split('-')[id.split('-').length - 2];
    }
    const payload = {
      ...currentTask,
      parentId: id?.includes('undefined') || checkParentIds !== '' || checkParentIds !== undefined || checkParentIds !== null ? checkParentIds : null,
      name: taskName?.length ? taskName : `Task-${generateId(5)}`,
      layout: JSON.parse(query.serialize()),
      owner: JSON.parse(localStorage.getItem('currentUser')).email
    };
    if (id !== undefined || id?.includes('undefined')) {
      if(id?.includes('undefined')){
        createTask(payload).then((data)=>{
          if(data.result){
            let task = data.result;
            setCurrentTask(task);
            setTaskName(task.name);
            setIsEditMode(true);
          }
        })
      }else{
        updateTask(checkCurrentIds,payload).then((data)=>{
          if(data.result){
            let task = data.result;
            setCurrentTask(task);
            setTaskName(task.name);
            setIsEditMode(true);
          }
        })
      }
    }else{
    createTask(payload).then((data)=>{
      if(data.result){
        let task = data.result;
        setCurrentTask(task);
        setTaskName(task.name);
        setIsEditMode(true);
      }
    })
    }
    setTimeout(() => {
      getAllTasks()
    }, 1000);
    if (isEditMode) {
      setSnackbarMessage("Task updated successfully");
    } else {
      setSnackbarMessage("Task saved successfully");
    }
  };
  
  const arrowtoggleDropdown = () => {
    arrowsetDropdownOpen(!isarrowDropdownOpen);
  };

  const createSubTask = () => {
    const payload = {
      parentId: id,
      owner : getCurrentUser().email,
      name: `Task-${generateId(5)}`,
      layout: {ROOT : {
        "type": {
            "resolvedName": "Container"
        },
        "isCanvas": true,
        "props": {
            "background": "#ffffff",
            "padding": 3,
            "className": "overflow-auto flex-auto h-full w-full"
          },
          "displayName": "Container",
          "custom": {},
          "hidden": false,
          "nodes": [],
          "linkedNodes": {}
    }},
    };
    createTask(payload);
    setSnackbarMessage("Sub Task saved successfully");
    setTimeout(() => {
      getAllTasks()
    }, 500);
    checkChangeEvent(true);
  }

  useEffect(() => {
    if (id !== "create") {
      const task = getUserTask(id);
      if (task?.layout) {
        actions.deserialize(task.layout);
        setCurrentTask(task);
        setTaskName(task.name);
        setIsEditMode(true);
      }
    } else {
      setCurrentTask({
        owner: getCurrentUser(),
        status: "progress",
      });
      setTaskName("");
    }
  }, [isEditMode]);
  
  useEffect(() => {
    const task = getUserTask(id);
    setTaskName(task?.name);
    setCurrentTask(task);
  },[id])

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isarrowDropdownOpen && parentRef.current && !parentRef.current.contains(e.target)) {
        arrowsetDropdownOpen(null);
      }
    };

    document.addEventListener("click", checkIfClickedOutside);

    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
}, [isarrowDropdownOpen]);

  useEffect(() => {
    const checkIsMobile = () => {
      const mediaQuery = window.matchMedia('(max-width: 639px)');
      setIsMobile(mediaQuery.matches);
    };

    checkIsMobile();

    const resizeListener = () => {
      checkIsMobile();
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return (
    <>
      {/* <Dialog
        header="Select Layout"
        visible={isOpen}
        style={{ width: "50vw" }}
        onHide={closeModal}
        maskClassName="backdrop-blur-md"
      >
        <div className="flex flex-auto flex-wrap">
          {layouts?.map((layout) => (
            <div
              className={`flex m-2 h-[12rem] w-[12rem] rounded-md flex-col p-2 border-[1px] border-gray-300 ${
                layout?._id === currentLayout?._id
                  ? "border-2 border-blue-400"
                  : ""
              }`}
              key={layout?._id}
            >
              <div className="pointer-events-none opacity-75  overflow-hidden">
                <LayoutPreview layout={layout?.json} />
              </div>
              <div className="flex flex-row pt-1 h-full text-xs text-blue-600  place-items-end">
                <Button
                  label={
                    layout?.name?.length > 10
                      ? layout?.name?.slice(0, 10) + "..."
                      : layout?.name
                  }
                  className="text-xs bg-blue-400 hover:bg-blue-600 text-white font-bold p-2 rounded w-4/5 mr-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    actions.deserialize(JSON.parse(layout?.json));
                    setIsEditMode(true);
                    setCurrentLayout(layout);
                    setTaskName(layout?.name);
                    closeModal();
                    setSnackbarMessage("Layout loaded");
                  }}
                />
                <Button
                  label={<i className="pi pi-trash cursor-pointer text-sm" />}
                  className="text-xs bg-red-400 hover:bg-red-600 text-white font-bold p-2 rounded w-1/5"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    deleteLayout(layout?._id);
                    setSnackbarMessage("Layout deleted");
                    closeModal();
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </Dialog> */}
      <div
        className={`p-3 bg-white border-b-2 border-gray-300 overflow-auto parent-button ${isSettingsOpen ? "max-h-[50vh]" : "max-h-[20vh]"
          }`}
      >
        {!isSettingsOpen && (
          <div
            className={`grid grid-cols-2 gap-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 all-buttons ${isSettingsOpen ? "hidden lg:grid xl:grid 2xl:grid" : ""
              }`}
            style={{ overflow: `${isMobile === true ? "hidden" : "scroll"}`,maxHeight: `${isMobile === true ? "6rem" : "8rem"}` }}
          >
            <TextField
              placeholder={"Task Name"}
              variant="outlined"
              size="small"
              value={taskName}
              className="child-taskfield"
              onChange={(e) => setTaskName(e.target.value)}
              required
            />
            <Button
              className="text-xs bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed child-button-undo"
              onClick={() => actions.history.undo()}
              disabled={!enabled || !canUndo}
            >
              <FaUndo />
            </Button>
            <Button
              className="text-xs bg-red-500 hover:bg-red-700 text-white font-bold px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed child-button-redo"
              onClick={() => actions.history.redo()}
              disabled={!enabled || !canRedo}
            >
              <FaRedo />
            </Button>
            <Button
              onClick={handleSave}
              label={"Save"}
              className="text-xs bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed child-button-save"
              disabled={!canUndo || !taskName?.length}
            />
            <Button
              onClick={() => {
                navigate(`/view/${id}`);
              }}
              hidden={isMobile}
              label="Preview"
              className="text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed child-button-preview"
              disabled={!currentTask?._id}
            />
            {/* <Button
            onClick={openModal}
            label="Select Task"
            className="text-xs bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed mr-2"
            disabled={!layouts?.length}
          />
          <Button 
            label={"Load"}
            className="text-xs bg-green-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed mr-2"
            
            onClick={()=>{
              //const json = lz.decompress(lz.decodeBase64(stateToLoad));
              actions.deserialize(getUserTask('154654').layout);  
            }}
          /> */}
            <Button
              hidden={isMobile}
              label={"Add Roles"}
              className="text-xs bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed child-button-addroles"
              disabled={!currentTask?._id}
              onClick={() => {
                navigate(`/roles/all/${parentId + '-' + currentTask?._id}`)
              }}
            />
            {!location.pathname.includes('create') &&
              <Button
                hidden={isMobile}
                label={"Add Sub Task"}
                className="text-xs bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed child-button-addsubtask"
                disabled={!currentTask?._id}
                onClick={() => {
                  createSubTask();
                }}
              />}
            {/* <div className="flex flex-col ml-2">
            <FormControlLabel
              className="enable-disable-toggle"
              control={
                <Switch
                  checked={isEditMode}
                  onChange={(_, value) => setIsEditMode(value)}
                />
              }
              label={isEditMode ? "Edit Mode" : "Add Mode"}
            />
          </div> */}
            {isMobile && (
              // <div className="expand">
              <div className="text-xs dropdown-arrows" ref={parentRef}>
              <IoIosArrowDropdown onClick={() => { arrowtoggleDropdown(); }} />
              {isarrowDropdownOpen && (
                <div className="absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dropdown" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1" style={{ width: '8.5rem' }}>
                  <div className="py-1" role="none">
                    <span className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-1" style={{ lineHeight: '1.75rem' }}>
                      <div className="flex items-center dropdown-parents" style={{ color: "black" }} disabled={!currentTask?._id} onClick={() => { navigate(`/view/${id}`); }}>
                        {/* <MdPreview className="mr-2" style={{ margin: '0.2rem' }} /> */}
                        <span className="dropdown-item">Preview</span>
                      </div>
                      <div className="flex items-center dropdown-parents" style={{ color: "black" }} onClick={() => { navigate(`/roles/all/${parentId + '-' + currentTask?._id}`) }}>
                        {/* <BsFillGridFill className="mr-2" style={{ margin: '0.2rem' }} /> */}
                        <span className="dropdown-item">Add Roles</span>
                      </div>
                      <div className="flex items-center dropdown-parents" style={{ color: "black" }} onClick={() => { !location.pathname.includes('create') && createSubTask(); }}>
                        {/* <BsFillGridFill className="mr-2" style={{ margin: '0.2rem' }} /> */}
                        <span className="dropdown-item">Add Subtask</span>
                      </div>
                    </span>
                  </div>
                </div>
              )}
            </div>
            // </div>
            )}
            <Snackbar
              autoHideDuration={1000}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={!!snackbarMessage}
              onClose={() => setSnackbarMessage(null)}
              message={<span>{snackbarMessage}</span>}
              className="z-10"
            />
          </div>
        )}
        <div
          className={`${isSettingsOpen ? `md:py-2 lg:py-2 xl:py-2 2xl:py-2` : ''} origin-top transition-[transform] duration-300 relative ${isSettingsOpen ? "translate-y-[0%]" : "translate-y-[-100%]"
            }`}
        >
          <SettingsPanel setIsSettingsOpen={setIsSettingsOpen} />
        </div>
      </div>
    </>
  );
};
