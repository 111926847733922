import { getUserTask } from "../../services/UserTaskService";
import { useParams } from "react-router";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../Reusables/Button";
import { UserCard } from "../UserCard";
import DummyUser from "../../assets/Images/roles.png";
import { getRoles } from "../../services/RoleService";
import { NavBar } from "../NavBar";
import { allRole } from "../../services/ApiServices/RoleApiServices";

const AllRoles = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [roles,setRoles] = useState([]);
  const onUserClick = (role) => {
    if (!role) return;
    navigate(!id ? `/roles/invite/${role.name}` :`/roles/invite/${role.name}/${id}`);
  };

  const getAllRoles = () => {
    allRole().then((data)=>{
      if(data.result){
        setRoles(data.result)
        localStorage.setItem("roles", JSON.stringify(data.result));
      }
    })
  }

  useEffect(()=> {
    getAllRoles()
  },[])

  return (
    <><NavBar />
    <div className="flex flex-col items-center justify-center w-full h-screen bg-gradient-to-l from-slate-500 to-slate-200">
      <div className="absolute top-20 left-4">
        <span
          onClick={() => navigate(-1)}
          className="cursor-pointer rounded-full bg-gray-400 p-2 top-0 left-0 w-8 h-8 flex flex-row items-center justify-center text-white border-2 border-white"
        >
          <i className="pi pi-angle-left" />
        </span>
      </div>
      <div className="container self-center rounded-md h-auto max-h-full bg-white justify-center p-4 overflow-auto w-auto min-w-[25%]">
        <div className="flex flex-col items-center justify-center overflow-auto">
          <div className="max-w-md text-center">
            <p className="text-2xl font-semibold md:text-3xl">Add Roles</p>
            <p className="mt-4 mb-8 dark:text-gray-500">
              Please select role to invite users.
            </p>
          </div>
          <div className="flex flex-wrap justify-center flex-auto">
            <Button
              type="submit"
              label="Add Role"
              onClick={() => navigate(!id ? `/roles/add` : `/roles/add/${id}`)}
              children={<i className="mx-2 pi pi-plus" />}
            />
          </div>
          {roles?.length > 0 ? (
            <>
              <div className="flex flex-wrap justify-center flex-auto">
                {roles?.map((role) => (
                  <UserCard
                    key={role?.name}
                    userData={{
                      id: role?.name,
                      name: role?.name,
                      email: ``,
                      img: DummyUser,
                      imgType: "Circle",
                    }}
                    onClick={() => onUserClick(role)}
                    containerClass={`m-4 bg-slate-300 rounded-lg p-2 justify-center flex md:flex-row sm:flex-col flex-col lg:flex-col xl:flex-col 2xl:flex-auto`}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="max-w-md text-center flex flex-auto justify-center flex-col">
              <p className="text-2xl font-semibold md:text-3xl">
                No Roles found.
              </p>
              <p className="mt-4 mb-8 dark:text-gray-500">
                Please add a role to continue.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default AllRoles;
