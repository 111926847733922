import { allUser } from "./ApiServices/UserApiServices";

const getUsers = () => {
  const users = localStorage.getItem("users") || [];
  if (users.length > 0) {
    return JSON.parse(users);
  }
  return [];
};

const dummyUsers = [
  { name: 'Master', email: 'Master@gmail.com', id: 0},
  { name: 'Winter', email: 'Winter@gmail.com', id: 1},
  { name: 'Rock', email: 'Rock@gmail.com', id: 2},
  { name: 'Anny', email: 'Anny@gmail.com', id: 3},
]

const addUser = (payload) => {
  let users = localStorage.getItem("users") || [];
  try {
    if (users.length > 0) {
      users = JSON.parse(users);
      const user = users.find((user) => user.email === payload.email);
      if (user) return false;
    }
    users.push(payload);
    localStorage.setItem("users", JSON.stringify(users));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const setCurrentUser = (payload) => {
  localStorage.setItem("currentUser", JSON.stringify(payload));
};

const getUserByEmail = (email) => {
  if (!email) {
    return { name: '' };
  }
  const users = getUsers();
  const user = users.find((user) => user.email.toLowerCase() === email.toLowerCase());
  return user || '';
};

const getCurrentUser = () => {
  let user = null;
  try{
    user = JSON.parse(localStorage.getItem("currentUser"));
  }catch(e){
  }
  return user;
};

const updateUser = (email, updatedFields) => {
  let users = JSON.parse(localStorage.getItem('users')) || [];
  let userIndex = users.findIndex(user => user.email === email);

  if (userIndex !== -1) {
    users[userIndex] = { ...users[userIndex], ...updatedFields };
    localStorage.setItem('users', JSON.stringify(users));
    return true;
  }
  return false;
}

const initDummyUsers = async() => {
  let user = await allUser();
  if (!localStorage.getItem("users")) {
    localStorage.setItem("users", JSON.stringify(user.result));
  }
}
initDummyUsers();
export { getUsers, addUser, getUserByEmail, setCurrentUser, getCurrentUser, updateUser,initDummyUsers};
