import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TaskRead } from "../TaskMaker/TaskRead";
import TaskCreated from "../../assets/Images/taskCreated.png";
import TaskMembers from "../../assets/Images/taskMembers.png";
import TaskBlankImg from "../../assets/Images/task-blank.png";
import { getAllSubTasks, getAllTasks, getUserTask, deleteUserTask, saveAllTasks, updateUserTask, deleteUserTasks } from "../../services/UserTaskService";
import { getCurrentUser } from "../../services/UserService";
import { BiEdit } from "react-icons/bi";
import { getOutput } from "../../services/OutputService";
import { getRoles } from "../../services/RoleService";
import { FaTasks, FaUsers, FaUserPlus } from "react-icons/fa";
import { MdOutlineManageAccounts } from "react-icons/md";
import { MdQuestionAnswer, MdOutput } from "react-icons/md";
import { TbHierarchy } from "react-icons/tb"
import { GrStatusInfo, GrView } from "react-icons/gr"
import { AiOutlineArrowRight, AiFillEdit, AiFillSetting, AiFillFileAdd, AiFillFolderOpen, AiFillPlusCircle, AiFillDelete, AiOutlinePlus } from "react-icons/ai";
import { BiSitemap } from "react-icons/bi"
import { BsSendFill } from "react-icons/bs"
import { RxCross1, RxCross2, RxMagnifyingGlass } from "react-icons/rx"
import "react-tooltip/dist/react-tooltip.css";
import "./TaskCard.css";
import { getChildTaskId, getEditTaskUrl } from "../../utils";
import { Tooltip } from 'react-tooltip';
import { Snackbar } from "@material-ui/core";
import { Button } from "../Reusables/Button";
import { BsFillGridFill } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { FaThList, FaPlus } from "react-icons/fa";
import { HiArrowSmRight, HiArrowSmDown } from "react-icons/hi"
import { MdOutlineZoomOutMap } from "react-icons/md"
import { LayoutPreview } from "../TaskMaker/LayoutPrieview";
import { Container, Link, Button as Btn } from 'react-floating-action-button'
import TaskPreview from "../../assets/Images/taskPreview.png"
import { NavBar } from "../NavBar";
import { GoAlert } from "react-icons/go";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import "react-tooltip/dist/react-tooltip.css";
import TaskCard from "./TaskCard";
import PreviewTaskCard from "./PreviewTaskCard";


import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import AllTasks from "./AllTasks";

const getHeading = (task) => {
    if (task.name) {
        return task.name;
    } else {
        return "All Tasks";
    }
}

const AllChildTasks = (props) => {
    const [tasks, setTasks] = useState([]);
    const [isSelected, setSelected] = useState(false)
    const [currentTask, setCurrentTask] = useState({});
    const [count, setCount] = useState(0);
    const { taskId } = useParams();
    const navigate = useNavigate();
    const [isDeletedTask, setDeletedTask] = useState(false);
    
    useEffect(() => {
        let temp = [];
        if (taskId) {
            const task = getUserTask(taskId);
            setCurrentTask(task);
            if (task.tasks && Array.isArray(task.tasks)) {
                temp = task.tasks
            } else if (typeof (task) === "object") {
                temp = [task]
            }
        } else {
            temp = getAllTasks();
            setCurrentTask({});
        }

        let tempData = temp.sort((x, y) => y.createdAt - x.createdAt).filter(dataTemp => dataTemp?.members?.some(data => data?.email === getCurrentUser()?.email) || dataTemp.owner === getCurrentUser()?.email);
        setTasks(temp);
        setDeletedTask(false);
    }, [taskId, count, isSelected, isDeletedTask]);

    return (
        // <>
        //     <NavBar />
        //     <div className="flex flex-col items-center justify-center w-full h-screen bg-gradient-to-l from-slate-500 to-slate-200">
        //         <div className="absolute top-20 left-4">
        //             {/* <span
        //                 onClick={() => navigate(-1)}
        //                 className="cursor-pointer rounded-full bg-gray-400 p-2 top-0 left-0 w-8 h-8 flex flex-row items-center justify-center text-white border-2 border-white"
        //             >
        //                 <i className="pi pi-angle-left" />
        //             </span> */}
        //             {/* added from alltask - header */}
        //             {/* <div className="flex flex-wrap">
        //                 <nav className="flex items-center justify-between bg-gray-200 w-screen h-20">
        //                     <button onClick={() => navigate(-1)}><i className="ml-8 pi pi-angle-left" /></button>
        //                     <div className="flex items-center flex-shrink-0 text-left ml-4 text-3xl font-medium text-gray-900">{getHeading(currentTask)}</div>
        //                     <button
        //                         onClick={() => navigate("/task/root/create")}
        //                         className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 m-4 p-4 rounded"
        //                     >New Task
        //                     </button>
        //                 </nav></div> */}
        //         </div>

        //         <div className="container self-center rounded-md h-auto max-h-full bg-white justify-center p-4 overflow-auto w-auto min-w-[25%]" style={{margin:'2rem'}}>
        //         <div className="flex flex-wrap">
        //                 <nav className="flex items-center justify-between bg-gray-200 w-screen h-20">
        //                     <button onClick={() => navigate(-1)}><i className="ml-8 pi pi-angle-left" /></button>
        //                     <div className="flex items-center flex-shrink-0 text-left ml-4 text-3xl font-medium text-gray-900">{getHeading(currentTask)}</div>
        //                     <button
        //                         onClick={() => navigate("/task/root/create")}
        //                         className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 m-4 p-4 rounded"
        //                     >New Task
        //                     </button>
        //                 </nav></div>
        //             <div className="flex flex-col items-center justify-center overflow-auto">
        //             <div className={`flex flex-wrap ${tasks?.length === 0 ? 'h-screen' : ''}`}>
        //         {tasks.length > 0 ? tasks?.map((item) => (
        //             <TaskCard key={item._id} taskId={taskId} item={item} tasks={tasks} reload={()=>{setCount((val)=>(val+1))}} setSelected={setSelected} isSelected={isSelected} setDeletedTask={setDeletedTask}/>
        //             )) : <div className="justify-center"> <div className="text-semibold font-bold text-red-400 m-8 p-4 rounded-lg shadow-lg flex flex-wrap bg-gray-100  items-center">
        //             {" "}
        //             <GoAlert
        //               className="mr-2 mb-1  text-yellow-500"
        //               style={{ fontSize: "2rem" }}
        //             />
        //             No tasks found with user {getCurrentUser()?.email}.
        //           </div>
        //           </div>
        //           }
        //             </div>
        //                 {/* <TaskCard/> */}
        //                 {/* <AllTasks/> */}
        //             </div>
        //         </div>
        //     </div>
        // </>
        <>
        <NavBar/>
            <div className="flex flex-wrap">
            <nav className="flex items-center bg-gray-200 w-screen h-20">
                    <span
                        onClick={() => navigate(-1)}
                        className="cursor-pointer rounded-full ml-8 bg-gray-400 p-2 top-0 left-0 w-8 h-8 flex flex-row items-center justify-center text-white border-2 border-white"
                    >
                        <i className="pi pi-angle-left" />
                    </span>
                    <div className="flex items-center flex-shrink-0 text-left ml-4 text-3xl font-medium text-gray-900" style={{ marginLeft: '1%' }}>{getHeading(currentTask)}</div>
                    </nav></div>
            <div className={`flex flex-wrap ${tasks?.length === 0 ? 'h-screen' : ''}`}>
                {tasks.length > 0 ? tasks?.map((item) => (
                    <TaskCard key={item._id} taskId={taskId} item={item} tasks={tasks} reload={()=>{setCount((val)=>(val+1))}} setSelected={setSelected} isSelected={isSelected} setDeletedTask={setDeletedTask}/>
                    // <PreviewTaskCard key={item._id} taskId={taskId} item={item} tasks={tasks} reload={()=>{setCount((val)=>(val+1))}} setSelected={setSelected} isSelected={isSelected} setDeletedTask={setDeletedTask}/>
                    )) : <div className="justify-center"> <div className="text-semibold font-bold text-red-400 m-8 p-4 rounded-lg shadow-lg flex flex-wrap bg-gray-100  items-center">
                    {" "}
                    <GoAlert
                      className="mr-2 mb-1  text-yellow-500"
                      style={{ fontSize: "2rem" }}
                    />
                    No tasks found with user {getCurrentUser()?.email}.
                  </div>
                  </div>
                  }
            </div>
            <ReactTooltip id="my-tooltip" place="bottom" variant="dark"/>
        </>
    );
};

export default AllChildTasks;