import { Editor, Frame, Element } from "@craftjs/core";
import { useEffect, useState } from "react";
import { SettingsPanel } from "./SettingsPanel";
import { Toolbox } from "./Toolbox";
import { Topbar } from "./Topbar";
import { Button } from "./user/Button";
import { Card, CardBottom, CardTop } from "./user/Card";
import { Container } from "./user/Container";
import { Text } from "./user/Text";
import { Name } from "./user/Name";
import { NumberInput } from "./user/Number";
import { Email } from "./user/Email";
import { DateInput } from "./user/Date";
import { Time } from "./user/Time";
import { Phone } from "./user/Phone";
import { Image } from "./user/Image";
import { SampleImage } from "./user/SampleImage";
import { Input } from "./user/Input";
import { Video } from "./user/Video";
import { SampleVideo } from "./user/SampleVideo";
import { Audio } from "./user/Audio";
import { HrLine } from "./user/HrLine";
import { SampleAudio } from "./user/SampleAudio";
import { Radio } from "./user/Radio";
import { CheckboxInput} from "./user/Checkbox";
import { UserCard } from "./user/UserCard";
import { NavBar } from "../NavBar";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getAllSubTasks, getUserTask } from "../../services/UserTaskService";
import { TaskPreview } from "./TaskPreview";
import useWindowDimensions from '../UseWindowResize';

export const TaskEditor = () => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const { width } = useWindowDimensions();
  let { id,parentId} = useParams();
  if(parentId && parentId!='root'){
    id = `${parentId}-${id}`;
  }
  const navigate = useNavigate();
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [subTaskList, setSubTask] = useState([]);
  const [isChange, setChangeEvent] = useState(false);
  const task = getUserTask(id);
  const subTasks = task.tasks?task.tasks:[];
  
  const checkChangeEvent = (data) => {
    setChangeEvent(data)
  }
  const showSubTaskButton = () =>{
    setSubTask(subTasks)
  }
  useEffect(()=>{
    showSubTaskButton()
    setChangeEvent(false)
  },[isChange,id])

  return (
    <div>
    <NavBar title={task.name} />
    <div className="w-full h-auto bg-slate-200 overflow-auto">
      <div className="flex flex-wrap flex-auto">
        <Editor key={id}
          resolver={{
            Card,
            Button,
            Text,
            Container,
            CardTop,
            CardBottom,
            UserCard,
            Name,
            NumberInput,
            Email,
            DateInput,
            Time,
            Image,
            SampleImage,
            Video,
            SampleVideo,
            Audio,
            SampleAudio,
            Phone,
            Input,
            CheckboxInput,
            Radio,
            HrLine
          }}
        >
          <div
            className={`w-1/5 h-auto bg-white border-r-2 border-slate-300 transition-all duration-300 ease-in-out ${
              isCollapsed
                ? "md:w-1/12 lg:w-1/12 xl:w-1/12 2xl:w-1/12"
                : "md:w-1/5 lg:w-1/4 xl:w-1/4 2xl:w-1/4"
            }`}
          >
            <div className="relative">
              <div className="top-[50vh] opacity-75 -right-4 absolute bg-white w-8 h-8 text-slate-600 text-xs font-bold p-1 rounded-full hidden md:block lg:block xl:block 2xl:block">
                <span
                  className="text-white bg-slate-600 rounded-full cursor-pointer float-right flex items-center p-1 justify-center"
                  onClick={() => setIsCollapsed(!isCollapsed)}
                >
                  <i
                    className={`pi pi-arrow-left ${
                      isCollapsed ? "rotate-180" : ""
                    }`}
                    style={{
                      transition: "transform 0.3s ease-in-out",
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="flex flex-col h-screen">
              <Toolbox isCollapsed={isCollapsed} />
            </div>
          </div>
          <div className="flex-1 overflow-auto h-auto rounded-md">
            <Topbar checkChangeEvent={checkChangeEvent} task={task} isSettingsOpen={isSettingsOpen} setIsSettingsOpen={setIsSettingsOpen} />
            {!isSettingsOpen && !location.pathname.includes('create') &&
            <div className="grid grid-cols-2 gap-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 block p-2 bg-white border border-gray-200 rounded-lg shadow dark:border-gray-700" style={{margin: width < 630 ? '1rem 0.3rem 0' : '1rem 1rem 0',maxHeight: '6rem', overflow: 'scroll'}}>
            {subTaskList?.length > 0 ? subTaskList?.map((data)=> {
              return (
                    <a key={data?._id} onClick={() => { 
                      navigate(`/task/${id}/${data?._id}`);
                    }} className="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700" 
                    style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', display: 'block'}}>
                      <div className="text-left">
                          <div className="-mt-1 font-sans text-sm font-semibold">{data?.name}</div>
                      </div>
                  </a>
              )
            }) : 'No Subtask Found !!'}
            </div>}
            {/* {task?.layout && <TaskPreview layout={task?.layout} />} */}
            <div className="flex flex-wrap h-full overflow-auto p-1 xm:p-2 sm:p-3 md:p-3 lg:p-4 xl:p-4 2xl:p-5">
              <Frame>
                <Element
                  // background="#e2e8f0"
                  is={Container}
                  className="overflow-auto flex-auto h-full w-full"
                  canvas
                />
              </Frame>
            </div>
          </div>
        </Editor>
      </div>
    </div>
    </div>
  );
};
