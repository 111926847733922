import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import TaskCreated from "../../assets/Images/taskCreated.png";
import TaskMembers from "../../assets/Images/taskMembers.png";
import TaskPreview from "../../assets/Images/taskPreview.png";
import { arrangeTasks, getAllSubTasks, getAllTasks, getUserTask, saveAllTasks } from "../../services/UserTaskService";
import { getCurrentUser, getUserByEmail, initDummyUsers, setCurrentUser } from "../../services/UserService";
import { BiEdit } from "react-icons/bi";
import { NavBar } from "../NavBar";
import { getOutput } from "../../services/OutputService";
import { getRoles } from "../../services/RoleService";
import { GoAlert } from "react-icons/go";
import { FaTasks, FaUsers } from "react-icons/fa";
import { MdQuestionAnswer } from "react-icons/md";
import { AiFillFileAdd, AiOutlinePlus } from "react-icons/ai";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import "react-tooltip/dist/react-tooltip.css";
import TaskCard from "./TaskCard";
import { allTask } from "../../services/ApiServices/TaskApiServices";
import { BsSearch } from "react-icons/bs";
import DebounceInput from 'react-debounce-input';
import "./AllTasks.css";

const getHeading = (task) => {
    if (task.name) {
        return task.name;
    } else {
        return getCurrentUser()?.name + `'s` + ' ' + 'Tasks';
    }
}
const AllTasks = () => {
    const [tasks, setTasks] = useState([]);
    const [isSelected, setSelected] = useState(false)
    const [currentTask, setCurrentTask] = useState({});
    const [count, setCount] = useState(0);
    const { taskId, email } = useParams();
    const navigate = useNavigate();
    const [isDeletedTask, setDeletedTask] = useState(false);
    let user = getUserByEmail(email);
    let userEmailId = user["email"];
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setfilteredData] = useState(null);
    const [isCopied, setCopyTask] = useState(false)

    useEffect(()=>{
        if(isCopied){
            setTimeout(() => {
                getTasksAPI();
            }, 500);
        }
    },[isCopied])

    const getTasksAPI = () => {
        allTask().then((data) => {
            if (data.result) {
                let tempData = data.result.sort((x, y) => y.createdAt - x.createdAt).filter(dataTemp => dataTemp?.members?.some(data => data?.email.toLowerCase() === email.toLowerCase()) || dataTemp.owner.toLowerCase() === email.toLowerCase())
                let temp = arrangeTasks(tempData);
                saveAllTasks(temp)
                setTasks(temp)
            }
            setCopyTask(false)
        })
    }

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);

        const filteredData = tasks.filter((data) => {
            return data?.name?.toLowerCase().includes(event.target.value.toLowerCase())
        });
        setfilteredData(filteredData)
    };

    useEffect(() => {
        getTasksAPI();
        let temp = [];
        if (taskId) {
            const task = getUserTask(taskId);
            setCurrentTask(task);
            if (task.tasks && Array.isArray(task.tasks)) {
                temp = task.tasks
            } else if (typeof (task) === "object") {
                temp = [task]
            }
        } else {
            temp = getAllTasks();
            setCurrentTask({});
        }

        if (typeof (email) === "string" && email.length > 0) {
            let userEmail = getUserByEmail(email);
            if (user.email !== undefined) {
                setCurrentUser(userEmail);
                let tempData = temp.sort((x, y) => y.createdAt - x.createdAt).filter(dataTemp => dataTemp?.members?.some(data => data?.email.toLowerCase() === email.toLowerCase()) || dataTemp.owner.toLowerCase() === email.toLowerCase());
                if (searchTerm === '') {
                    setTasks(tempData);
                }
                else if (searchTerm !== '') {
                    setTasks(filteredData);
                }
            }
            else {
                navigate('/');
                initDummyUsers();
            }
        }
        else {
            navigate('/');
            initDummyUsers();
        }
    }, [searchTerm, taskId, count, isSelected, isDeletedTask]);


    return (
        <>
            <NavBar />
            <div className="flex flex-wrap">
                <nav className="flex items-center bg-gray-200 w-screen h-20">
                    <span
                        onClick={() => navigate(-1)}
                        className="cursor-pointer rounded-full ml-8 bg-gray-400 p-2 top-0 left-0 w-8 h-8 flex flex-row items-center justify-center text-white border-2 border-white"
                    >
                        <i className="pi pi-angle-left" />
                    </span>
                    <div className="flex items-center flex-shrink-0 text-left ml-4 text-3xl font-medium text-gray-900 main-heading" style={{ marginLeft: '1%' }}>{getHeading(currentTask)}</div>
                    <button
                        onClick={() => navigate("/task/root/create")}
                        style={{ position: 'absolute', right: '0', backgroundColor: '#155f75' }}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 m-4 p-4 rounded"
                    >+ Add New
                    </button>
                </nav></div>
            {tasks.length > 3 &&
            <div className="backdrop-blur-sm bg-black/70 flex flex-wrap w-full h-16 sticky top-0 z-20 justify-between">
                <div className="flex flex-wrap w-[95%] sm:w-[85%] md:w-[99%]">
                    <DebounceInput
                        minLength={2}
                        debounceTimeout={100}
                        onChange={(e) => handleInputChange(e)}
                        value={searchTerm}
                        className=" flex flex-wrap m-4 mr-0 p-1 pl-12 font-thin  bg-white shadow-inner text-black  outline-0 rounded-full w-full"
                        placeholder="Search Task..."
                    />
                    <BsSearch className="flex flex-wrap absolute m-4 top-2 left-3 text-black" />
                </div>
            </div>
            }
            <div className={`flex flex-wrap ${tasks?.length === 0 ? 'h-screen' : ''}`}>
                {tasks.length > 0 ? tasks?.map((item) => (
                    <TaskCard userEmailId={userEmailId} key={item._id} taskId={taskId} item={item} tasks={tasks} reload={() => { setCount((val) => (val + 1)) }} setSelected={setSelected} isSelected={isSelected} setDeletedTask={setDeletedTask} setCopyTask={setCopyTask} isCopied={isCopied} />
                )) : <div className="justify-center"> <div className="text-semibold font-bold text-red-400 m-8 p-4 rounded-lg shadow-lg flex flex-wrap bg-gray-100  items-center">
                    {" "}
                    <GoAlert
                        className="mr-2 mb-1  text-yellow-500"
                        style={{ fontSize: "2rem" }}
                    />
                    No tasks found with user {getCurrentUser()?.email}.
                </div>
                </div>
                }
            </div>
            <ReactTooltip id="my-tooltip" place="bottom" variant="dark" />
        </>
    );
};

export default AllTasks;