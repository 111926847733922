import { useNode } from "@craftjs/core";
import React, { useState, useEffect } from "react";
import { FormControl, FormLabel } from "@material-ui/core";
import ContentEditable from "react-contenteditable";

export const Email = ({ text, nametag, ...props }) => {
  const {
    connectors: { connect, drag },
    selected,
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);

  return (
    <div
      {...props}
      ref={(ref) => connect(drag(ref))}
      onClick={() => selected && setEditable(true)}
    >
      <div className="w-full m-2 px-2">
        <label className="block  tracking-wide text-gray-700 text-md font-bold ">
          {nametag}
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-md py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="number"
          type="email"
          defaultValue={text}
          placeholder="winter000@gmail.com"
          onChange={(e) =>
            setProp(
              (props) =>
                (props.text = e.target.value.replace(/<\/?[^>]+(>|$)/g, "")),
              500
            )
          }
        />
      </div>
    </div>
  );
};
export const ButtonSettings = () => {
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props,
  }));

  return (
    <div className='m-1 ml-4'>
    <FormControl component="fieldset" className="flex flex-wrap">
      <FormLabel style={{fontWeight:"800",  color:'white'}} component="legend">Edit tag text</FormLabel>
        <ContentEditable
          html={props.nametag}
          className="my-2 p-2 text-white border border-white rounded-lg"
          onChange={(e) =>
            setProp(
              (props) =>
                (props.nametag = e.target.value.replace(
                  /<\/?[^>]+(>|$)/g,
                  "",
                  " "
                ))
            )
          }
          tagName="p"
        />
      </FormControl>
    </div>
  );
};

export const ButtonDefaultProps = {
  nametag: "Email",
};

Email.craft = {
  props: ButtonDefaultProps,
  related: {
    settings: ButtonSettings,
  },
};
