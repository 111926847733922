import { Editor, Element, Frame, useEditor } from "@craftjs/core";
import { Card, CardBottom, CardTop } from "./user/Card";
import { Text } from "./user/Text";
import { Button } from "./user/Button";
import { Container } from "./user/Container";
import { Name } from "./user/Name";
import { NumberInput } from "./user/Number";
import { Email } from "./user/Email";
import { DateInput } from "./user/Date";
import { Time } from "./user/Time";
import { Phone } from "./user/Phone";
import { Image } from "./user/Image";
import { SampleImage } from "./user/SampleImage";
import { Input } from "./user/Input";
import { Video } from "./user/Video";
import { SampleVideo } from "./user/SampleVideo";
import { Audio } from "./user/Audio";
import { HrLine } from "./user/HrLine";
import { SampleAudio } from "./user/SampleAudio";
import { Radio } from "./user/Radio";
import { CheckboxInput} from "./user/Checkbox";
import { UserCard } from "./user/UserCard";
import {
  Button as MaterialButton
} from '@material-ui/core';
import { getAllTasks, getUserTask, saveAllTasks } from "../../services/UserTaskService";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getCurrentUser } from "../../services/UserService";
import { getUserOutput, saveAllOutput, saveOutput } from "../../services/OutputService";
import { createOutput, getAllOutputs, updateOutputData } from "../../services/ApiServices/OutputApiServices";

export const SaveButton = () => {
  const navigate = useNavigate();
  const { query } = useEditor();
  const { id } = useParams();
  const data = getUserTask(id);
  const outputData = getUserOutput(id,getCurrentUser()?._id)
  const savePost = async() => {
    const userTasks = getAllTasks();
    const updatedTasks = userTasks.map((item) => {
      if (item._id === id) {
        let outputData = {
          ...data
        }
        return outputData;
      } else {
        return item;
      }
    })
    let updateOutput = {};
    if(outputData?.taskId === id && outputData?.memberDetails?.email === getCurrentUser().email){
      updateOutput = {
        ...outputData,
        "layout": JSON.parse(query.serialize())
      }
      updateOutputData(outputData._id,updateOutput)
    }else{
      updateOutput = {
        "taskId": id,
        "memberDetails": {
          "id" : getCurrentUser()._id,
          "email" : getCurrentUser().email
        },
        "layout": JSON.parse(query.serialize())
      }
      createOutput(updateOutput)
      let data = await getAllOutputs();
      saveAllOutput(data.result)
    }
    setTimeout(() => {
      navigate(`/task/${getCurrentUser().email}`)
    }, 500);
  }
  return (
    <MaterialButton
      onClick={() => savePost()}
      style={{ margin: '5px', float: 'right', background: '#359935', color: '#fff' }}
    >
      Submit
    </MaterialButton>
  );
};

export const TaskPreview = ({ layout, ...props }) => {
  const location = useLocation();
  const { id } = useParams();
  const data = getUserTask(id);
  const allAssignedTask = data?.tasks?.length > 0 ? data?.tasks?.map((item)=> {return {layout: item?.layout, name: item?.name, createdAt: item?.createdAt}}) : [];
  allAssignedTask.push({layout:data?.layout,name:data?.name, createdAt: data?.createdAt,members: data?.members});
  allAssignedTask.sort((x,y)=> x.createdAt - y.createdAt);
  if (!layout) return console.warn("Please provide a layout");
  return (
    <>
    <div className="flex flex-wrap">
    {allAssignedTask?.length > 0 && allAssignedTask?.map((data,index)=> {
      return (
        <>
        {data?.members?.length > 0 && data?.members?.some(el => el.email === getCurrentUser()?.email) &&
        <div className="flex flex-wrap h-screen" key={index} >
        <div className="flex flex-wrap justify-center cursor-pointer ">
          <div className="overflow-scroll no-scrollbar rounded-3xl justify-center border border-gray-400 shadow-2xl   m-2  w-[83vw] h-[60vh] sm:w-[88vw] md:w-[45vw] lg:w-[47vw]">
            <div
              className="flex flex-wrap justify-between sticky top-0 overflow-clip z-10 bg-cyan-700 shadow-2xl  border border-black rounded-t-3xl backdrop-blur-sm bg-cyan-900/40"
            >
              <div className=" text-white backdrop-blur-sm text-lg border border-gray-700 rounded-xl bg-black/10 shadow-inner p-2 font-semibold m-4 mb-1 sm:mb-4 ">
                {" " + data.name}
              </div>
              <div className=" italic items-center m-4 mt-0 font-thin text-sm text-gray-900 sm:mt-4  ">
                <b>{"" + new Date(data.createdAt).toLocaleString()}</b>
              </div>
            </div>
            <div className="hover:boder-black shadow-lg overflow-hidden bg-white ">
              <Editor
                resolver={{
                  Card,
                  Button,
                  Text,
                  Container,
                  CardTop,
                  CardBottom,
                  UserCard,
                  Name,
                  NumberInput,
                  SampleImage,
                  SampleVideo,
                  SampleAudio,
                  Email,
                  DateInput,
                  Time,
                  Image,
                  Video,
                  Audio,
                  Phone,
                  Input,
                  CheckboxInput,
                  Radio,
                  HrLine,
                }}
                enabled={false}
                {...props}
              >
                <Frame data={data.layout}>
                  <Element />
                </Frame>
                <div>
                {location.pathname.includes('task') && 
                <SaveButton />
                }
                </div>
              </Editor>
            </div>
          </div>
        </div></div>}
        </>);
      })}
      </div>
    </>
  );
};
