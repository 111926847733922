import { useNode } from '@craftjs/core';
import React, { useState, useEffect } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import {FormControl,FormLabel} from '@material-ui/core';
import ContentEditable from 'react-contenteditable';
        

export const Radio = ({option,option2,option3,option4, ...props}) => {
  const [ingredient, setIngredient] = useState(props.selectedValue);
  
    const {
        connectors: { connect, drag },
        selected,
        actions: { setProp },
      } = useNode((state) => ({
        selected: state.events.selected,
        dragged: state.events.dragged,
      }));
    const [editable, setEditable] = useState(false);

    const selectedValue = (e) => {
      setIngredient(e.value)
      setProp(
        (props) =>
          (props.selectedValue = e.value),
        500
      )
    }

  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);

  return (
    <div
      {...props}
      ref={(ref) => connect(drag(ref))}
      onClick={() => selected && setEditable(true)}
    >
     <div className="card m-2 flex justify-content-center">
            <div className="flex flex-wrap gap-3">
                <div className="flex align-items-center">
                    <RadioButton inputId={option} name={option} value="option" onChange={(e) => selectedValue(e)} checked={ingredient === 'option'} />
                    <label html={option} className="ml-2">{option}</label>
                </div>
                <div className="flex align-items-center">
                    <RadioButton defaultValue={option2} inputId={option2} name={option2} value="option2" onChange={(e) => selectedValue(e)} checked={ingredient === 'option2'} />
                    <label html={option2} className="ml-2">{option2}</label>
                </div>
                <div className="flex align-items-center">
                    <RadioButton defaultValue={option3} inputId={option3} name={option3} value="option3" onChange={(e) => selectedValue(e)} checked={ingredient === 'option3'} />
                    <label html={option3} className="ml-2">{option3}</label>
                </div>
                <div className="flex align-items-center">
                    <RadioButton defaultValue={option4} inputId={option4} name={option4} value="option4" onChange={(e) => selectedValue(e)} checked={ingredient === 'option4'} />
                    <label html={option4} className="ml-2">{option4}</label>
                </div>
            </div>
        </div>
    </div>
  );
};
export const ButtonSettings = () => {
  const {
    actions: { setProp },
    option,
    option2,
    option3,
    option4
  } = useNode((node) => ({
    option: node.data.props.option,
    option2: node.data.props.option2,
    option3: node.data.props.option3,
    option4: node.data.props.option4,
  }));

  return (
    <div >
      <div className="flex flex-wrap">
      <div className='flex flex-wrap m-4'>
      <FormControl component="fieldset" >
      <FormLabel style={{fontWeight:"800", color:'white'}} component="legend">Edit option 1</FormLabel>
        <ContentEditable
          html={option}
          className='mt-2 w-24 text-white border border-gray-400 rounded-md p-2'
            onChange={(e) =>
              setProp(
                (props) =>
                  (props.option = e.target.value.replace(
                    /<\/?[^>]+(>|$)/g,
                    "",
                    " "
                  ))
              )
            }
            tagName="p"
          />
      </FormControl>
      </div>

      <div className='flex flex-wrap m-4'>
      <FormControl component="fieldset">
      <FormLabel style={{fontWeight:"800", color:'white'}} component="legend">Edit option 2</FormLabel>
        <ContentEditable
          html={option2}
          className='mt-2 w-24 text-white border border-gray-400 rounded-md p-2'
            onChange={(e) =>
              setProp(
                (props) =>
                  (props.option2 = e.target.value.replace(
                    /<\/?[^>]+(>|$)/g,
                    "",
                    " "
                  ))
              )
            }
            tagName="p"
          />
      </FormControl>
      </div>

      <div className='flex flex-wrap m-4'>
      <FormControl component="fieldset" className='m-4'>
      <FormLabel style={{fontWeight:"800", color:'white'}} component="legend">Edit option 3</FormLabel>
        <ContentEditable
          html={option3}
          className='mt-2 w-24 text-white border border-gray-400 rounded-md p-2'
            onChange={(e) =>
              setProp(
                (props) =>
                  (props.option3 = e.target.value.replace(
                    /<\/?[^>]+(>|$)/g,
                    "",
                    " "
                  ))
              )
            }
            tagName="p"
          />
      </FormControl>
      </div>

<div className='flex flex-wrap m-4'>
      <FormControl component="fieldset" className='m-8'>
      <FormLabel style={{fontWeight:"800", color:'white'}} component="legend">Edit option 4</FormLabel>
        <ContentEditable
          html={option4}
          className='mt-2 w-24 text-white border border-gray-400 rounded-md p-2'
            onChange={(e) =>
              setProp(
                (props) =>
                  (props.option4 = e.target.value.replace(/<\/?[^>]+(>|$)/g, '',' '))
              )
            }
            tagName="p"
          />
      </FormControl>
      </div>
    </div>
    </div>
  );
};

export const ButtonDefaultProps = {
option:'Lion',
option2:'Tiger',
option3:'Jaguar',
option4:'Elephant'

};

Radio.craft = {
  props: ButtonDefaultProps,
  related: {
    settings: ButtonSettings,
  },
};

     