import React, { useEffect, useState } from "react";
import TaskImg from "../../assets/Images/task.png";
import { getCurrentUser } from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import { getTasks } from "../../services/TaskService";
import { Button } from "../Reusables/Button";
import { NavBar } from "../NavBar";

const UserTasks = () => {
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const temp = getTasks();
    let filteredPost =
      temp.filter((post) => post.name === getCurrentUser()?.email) || [];
    setTasks(filteredPost);
  }, []);
  return (
    <>
    <NavBar/>
      {/* <div className="flex flex-auto ml-10 mt-8">
        <i className="pi pi-undo  text-gray-500" />
        <div className="text-left ml-2 text-xl leading-none font-medium text-gray-500 ">
          Recent Tasks
        </div>
      </div>
      <hr className="h-px ml-10 mt-1 w-[93%] content-center bg-gray-400 border-0 "></hr> */}
      <div className="flex flex-wrap justify-center">
        { getCurrentUser() !== null ?  tasks.map((item) => (
          <div
            key={item._id}
            className="max-w-sm  border m-8 p-8  rounded-lg shadow-lg bg-gray-200 "
          >
            <div className="px-6 pt-4 pb-2">
              <span className="block bg-gray-100 rounded-full px-3 py-1 text-lg font-bold text-green-600 text-center capitalize">
                {item.status}
              </span>
            </div>
            <img
              onClick={() => {
                navigate("/task/" + item._id);
              }}
              className="rounded-t-lg p-3 border-white-1"
              src={TaskImg}
              alt="Form"
            />
            <div className="p-5">
              <h5 className="text-center text-2xl font-semibold tracking-tight text-[#484848]  ">
                {tasks ? item.name : "No Name"}
              </h5>
              <h2 className="text-center text-1xl font-regular tracking-tight text-gray-400  ">
                {tasks ? item._id : "No Name"}
              </h2>
            </div>
          </div>
        )) : <div className="flex flex-auto bg-gray-200 justify-center mt-4 w-20 h-fit" >
          <div className="content-center mt-6 text-gray-500"> <i className="pi pi-exclamation-triangle  " style={{ fontSize:'20px', color: '#ff5c5c' }}></i> Please select the user first! </div>
          <Button
          label="Select "
          children={<i className="ml-2 pi pi-user"/>}
          onClick={() => navigate("/select-user")}
          />
          </div>}
          
      </div>
    </>
  );
};

export default UserTasks;
