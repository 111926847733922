import React from 'react';
import './Switch.css';

export const Switch = ({ isOn, handleToggle, handleContentViewClick, handleChildViewClick, keyValue, disabled}) => {

  const handleEvent = () => {
    if(disabled === true){
    }
    else{
    handleToggle();
    handleContentViewClick();
    handleChildViewClick();
    }
  }
  return (
    <>
      <input
        checked={isOn}
        onChange={()=>handleEvent()}
        className="react-switch-checkbox"
        id={`react-switch-new` + keyValue}
        type="checkbox"
        disabled={disabled}
      />
      <label
        style={{ background: isOn && 'linear-gradient( 267deg, #bbbbbb 6.5%,#2e8da9 93.2% )' }}
        className="react-switch-label"
        htmlFor={`react-switch-new` + keyValue}>
          {isOn?
          <>
          <span style={{marginLeft:4,color:"#fff",fontSize:11,fontWeight:'800'}}>
          Content
          </span>
          </>:
          <>
          <span style={{marginLeft:33,color:"#fff",fontSize:11,fontWeight:'800'}}>
          Subtask
          </span>
          </>}
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

