import { useNode } from "@craftjs/core";
import React, { useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { FormControl, FormLabel } from "@material-ui/core";
import ContentEditable from "react-contenteditable";

export const DateInput = ({ text,nametag, ...props }) => {
  const [date, setDate] = useState(new window.Date(props.selectedvalue));
  const {
    connectors: { connect, drag },
    selected,
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));
  const [editable, setEditable] = useState(false);

  const selectedValue = (e) => {
    setDate(e.value)
    setProp(
      (props) =>
        (props.selectedvalue = e.value),
      500
    )
  }

  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);

  return (
    <div
      {...props}
      ref={(ref) => connect(drag(ref))}
      onClick={() => selected && setEditable(true)}
    >
      <div className="w-full md:w-1/4 m-2 px-2">
        <label className="block  tracking-wide text-gray-700 text-md font-bold ">{nametag}</label>
        <Calendar
          value={date}
          type="text"
          onChange={(e) =>
            selectedValue(e)
          }
        />
      </div>
    </div>
  );
};
export const ButtonSettings = () => {
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props,
  }));

  return (
    <div className="ml-4 mt-1">
      <FormControl component="fieldset" className="flex flex-wrap">
        <FormLabel style={{color:'white'}} component="legend">Edit tag text</FormLabel>
        <ContentEditable
          html={props.nametag}
          className="my-2 p-2 w-20 text-white border border-gray-500 rounded-lg"
          onChange={(e) =>
            setProp(
              (props) =>
                (props.nametag = e.target.value.replace(
                  /<\/?[^>]+(>|$)/g,
                  "",
                  " "
                ))
            )
          }
          tagName="p"
        />
      </FormControl>
    </div>
  );
};

export const ButtonDefaultProps = {
  nametag: "Date of Birth",
};

DateInput.craft = {
  props: ButtonDefaultProps,
  related: {
    settings: ButtonSettings,
  },
};

