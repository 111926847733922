import React from "react";
import { useParams } from 'react-router';
import { getUserTask } from "../../services/UserTaskService";
import { getUserOutput } from "../../services/OutputService";
import { TaskPreview } from "./TaskPreview";
import { NavBar } from "../NavBar";
import { getCurrentUser } from "../../services/UserService";

export const TaskRead = () => {
  const { id } = useParams();
  const data = getUserTask(id);
  const outputData = getUserOutput(id,getCurrentUser()?._id);
  return (
    <div><NavBar/>
      <div className="w-full h-full bg-slate-200 overflow-auto">
        {!data || !data.layout ? 
          <div>Task is not available.</div>
        : <TaskPreview layout={outputData?.taskId === id && outputData?.memberDetails?.email === getCurrentUser().email ? outputData.layout : data.layout} />}
      </div>
    </div>
  );
};
