import React, { useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { UserCard } from "../UserCard";
import DummyUser from "../../assets/Images/user.webp";
import { getUserTask } from "../../services/UserTaskService";
import { Button } from "../Reusables/Button";
import { NavBar } from "../NavBar";
import { getChildTaskId } from "../../utils";

const CheckMembers = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const users = getUserTask(id) || [];

  return (
    <React.Fragment>
    <NavBar />
    <div className="flex flex-col items-center justify-center w-full h-screen bg-gradient-to-l from-slate-500 to-slate-200">
      <div className="absolute top-20 left-4">
        <span
          onClick={() => navigate(-1)}
          className="cursor-pointer rounded-full bg-gray-400 p-2 top-0 left-0 w-8 h-8 flex flex-row items-center justify-center text-white border-2 border-white"
        >
          <i className="pi pi-angle-left" />
        </span>
      </div>
      <div className="container self-center rounded-md h-auto max-h-full bg-white justify-center p-4 overflow-auto w-auto min-w-[25%]">
        <div className="flex flex-col items-center justify-center overflow-auto">
          {users?.members && users?.members?.length > 0 ? (
            <>
              <div className="max-w-md text-center">
                <p className="text-xl mt-4 mb-4 dark:text-gray-500">
                  Assigned {users?.members.length > 1 ? 'members' : 'member'}
                </p>
                <button
                    onClick={() => navigate(`/roles/all/${getChildTaskId(id)}`)}
                    className="text-white font-bold py-2 m-4 p-4 rounded bg-slate-500 hover:bg-slate-600"
                > Add Member
                </button>
              </div>

              <div className="flex flex-wrap justify-center flex-auto">
                {users?.members && users?.members?.map((user) => (
                  <UserCard
                    key={user?._id}
                    userData={{
                      id: user?._id,
                      name: user?.email,
                      email: user?.email,
                      img: DummyUser,
                      imgType: "Circle",
                    }}
                    containerClass={`m-4 bg-slate-300 rounded-lg p-2 justify-center flex md:flex-row sm:flex-col flex-col lg:flex-col xl:flex-col 2xl:flex-auto`}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="max-w-md text-center flex flex-auto justify-center flex-col">
              <p className="text-2xl font-semibold md:text-3xl">
                No members found.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
    </React.Fragment>
  );
};

export default CheckMembers;
