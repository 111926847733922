import React, { useEffect } from "react";
import { Editor, Element, Frame } from "@craftjs/core";
import { useNavigate } from "react-router-dom";
import { Card, CardBottom, CardTop } from "./user/Card";
import { Text } from "./user/Text";
import { Button } from "./user/Button";
import { Container } from "./user/Container";
import { Name } from "./user/Name";
import { NumberInput } from "./user/Number";
import { Email } from "./user/Email";
import { SampleImage } from "./user/SampleImage";
import { DateInput } from "./user/Date";
import { Time } from "./user/Time";
import { Phone } from "./user/Phone";
import { Image } from "./user/Image";
import { Input } from "./user/Input";
import { Video } from "./user/Video";
import { Audio } from "./user/Audio";
import { Radio } from "./user/Radio";
import { HrLine } from "./user/HrLine";
import { SampleVideo } from "./user/SampleVideo";
import { SampleAudio } from "./user/SampleAudio";
import { CheckboxInput } from "./user/Checkbox";
import { UserCard } from "./user/UserCard";
import { useParams } from "react-router-dom";
import { getUserAllOutput } from "../../services/OutputService";
import { NavBar } from "../NavBar";
import { GoAlert } from "react-icons/go";
import { BsFillGridFill } from "react-icons/bs";
import { FaThList } from "react-icons/fa";
import { useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { colors } from "@material-ui/core";
import { BsSearch } from "react-icons/bs";
import { getUserTask } from "../../services/UserTaskService";
import { getChildTaskId } from "../../utils";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export const CheckUsersActivity = () => {
  const [viewmode, setViewmode] = useState(true);
  const navigate = useNavigate();
  let { id } = useParams();
  const taskDetails = getUserTask(id);
  const [task, setTasks] = useState([]);
  
  const checkOutput = async() => {
    new Promise(async(resolve, reject) => {
      let data = await getUserAllOutput(id);
      setTasks(data)
    });
  }

  useEffect(()=>{
    checkOutput();
  },[])

  const GridLayout = () => {
    return (
      <div className="flex flex-wrap  mt-2">
        {task?.length > 0 ?
          task?.map((data, index) => {
            return (
              <div 
              key={index} className="flex flex-wrap justify-center cursor-pointer ">
                <div className="  overflow-scroll no-scrollbar rounded-3xl justify-center border border-gray-400 shadow-2xl   m-2  w-[83vw] h-[60vh] sm:w-[88vw] md:w-[45vw] lg:w-[47vw]">
                  <div
                    className="flex flex-wrap justify-between sticky top-0 overflow-clip z-10 bg-cyan-700 shadow-2xl  border border-black rounded-t-3xl backdrop-blur-sm bg-cyan-900/40"
                  >
                    <div className=" text-white backdrop-blur-sm text-lg border border-gray-700 rounded-xl bg-black/10 shadow-inner p-2 font-semibold m-4 mb-1 sm:mb-4 ">
                      {" " + data.memberDetails.email}
                    </div>
                    <div className=" italic items-center m-4 mt-0 font-thin text-sm text-gray-900 sm:mt-4  ">
                      <b>{"" + new Date(data.createdAt).toLocaleString()}</b>
                    </div>
                  </div>
                  <div className="hover:boder-black shadow-lg overflow-hidden bg-white ">
                    <Editor
                      resolver={{
                        Card,
                        Button,
                        Text,
                        Container,
                        CardTop,
                        CardBottom,
                        UserCard,
                        Name,
                        NumberInput,
                        SampleImage,
                        SampleVideo,
                        SampleAudio,
                        Email,
                        DateInput,
                        Time,
                        Image,
                        Video,
                        Audio,
                        Phone,
                        Input,
                        CheckboxInput,
                        Radio,
                        HrLine,
                      }}
                      enabled={false}
                    >
                      <Frame data={data.layout}>
                        <Element />
                      </Frame>
                    </Editor>
                  </div>
                </div>
              </div>
            );
          }) : 
                <div>
                  <div className="text-semibold font-bold text-red-400 m-8 p-4 rounded-lg shadow-lg flex flex-wrap bg-gray-100 justify-center items-center">
                    {" "}
                    <GoAlert
                      className="mr-2 mb-1  text-yellow-500"
                      style={{ fontSize: "2rem" }}
                    />
                    No tasks found with id: {id}.
                  </div>
                </div>
            }
      </div>
    );
  };

  const ListLayout = () => {
    return (
      <div className="justify-center mt-6">
        <hr className="h-px w-2vw bg-gray-200 border-0 dark:bg-gray-700 justify-center" />
        <div className="flex flex-wrap items-center p-1">
          <div className="flex flex-wrap justify-center text-sm w-64 mt-1 mb-1">
            <span className="font-bold text-gray-500">User Emails</span>
          </div>
          <div className="flex flex-wrap  justify-center w-60 text-xs sm:text-md md:text-sm">
            <span className="font-semibold text-gray-500">Date/Time</span>
          </div>
        </div>
        <hr className="h-px  w-2vw bg-gray-200 border-0 dark:bg-gray-700" />
        <ul className="max-w-sm overflow-visible divide-y divide-gray-900 dark:divide-gray-300">
          {task?.length &&
            task?.map((data, index) => {
              return (
                <li className="p-2 w-screen ">
                  <div className="flex items-center ">
                    {/* <div className="flex flex-wrap rounded-2xl justify-center border border-gray-400 shadow-2xl bg-cyan-700 m-2 w-64 "> */}
                    <div key={index} className="flex flex-wrap ">
                      {/* <div className="flex flex-wrap">
                        <div className="text-gray-200 text-xl font-bold m-1 cursor-pointer">
                          {" " + data.memberDetails.email}
                        </div>
                      </div> */}

                      <div className="p-2 m-1 items-center flex flex-wrap rounded-lg  justify-between">
                        <div className="flex flex-wrap">
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button className="inline-flex w-60 justify-center text-ellipsis overflow-hidden gap-x-1.5 text-white rounded-md bg-cyan-700 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-cyan-800">
                                {" " + data.memberDetails.email}
                                <MdKeyboardArrowDown
                                  className="-mr-1 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                className="absolute  z-10 mt-2 origin-top-right rounded-md border border-black shadow-lg ring-1 backdrop-blur-sm bg-white/30 ring-black ring-opacity-5 focus:outline-none overflow-hidden "
                                style={{ width: "80vw" }}
                              >
                                <div className="py-1 ml-2 mr-2  ">
                                  <Menu.Item>
                                    {/* {({ active }) => (
                    <a
                      onClick={() => {
                        navigate("/user/select");
                      }}
                      className={classNames(
                        active ? "flex bg-gray-100  text-gray-900" : "flex text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    > */}
                                    <Editor
                                      resolver={{
                                        Card,
                                        Button,
                                        Text,
                                        Container,
                                        CardTop,
                                        CardBottom,
                                        UserCard,
                                        Name,
                                        NumberInput,
                                        SampleImage,
                                        SampleVideo,
                                        SampleAudio,
                                        Email,
                                        DateInput,
                                        Time,
                                        Image,
                                        Video,
                                        Audio,
                                        Phone,
                                        Input,
                                        CheckboxInput,
                                        Radio,
                                        HrLine,
                                      }}
                                      enabled={false}
                                    >
                                      <Frame data={data.layout}>
                                        <Element />
                                      </Frame>
                                    </Editor>
                                    {/* </a> */}
                                    {/* )} */}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                        <div className="flex flex-wrap justify-center w-60 font-light text-xs sm:text-sm italic  text-gray-600">
                          {"" + new Date(data.createdAt).toLocaleString()}
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    );
  };

  const onSearch = (e) => {
    const filteredList = getUserAllOutput(id).filter((item) => {
      return item?.memberDetails?.email?.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setTasks(filteredList);
  };

  return (
    <div>
      <NavBar />
      <nav className="flex flex-wrap justify-between bg-gray-300 w-screen p-4 ">
        <button onClick={() => navigate(-1)}>
          <i className="p-2 pi flex flex-wrap justify-start pi-angle-left" />
        </button>
        <div className="flex flex-wrap items-center justify-start ml-8 text-sm sm:text-lg md:text-xl font-bold text-gray-700">
          <a onClick={()=>{ navigate(`/task/${getChildTaskId(taskDetails._id)}`)}}>{taskDetails?.name}</a>
        </div>
        {/* <div className="flex flex-wrap">
          <div className="bg-white rounded">
            <BsFillGridFill
              className=" m-1 flex flex-wrap"
              style={{
                fontSize: "1.5rem",
                color: `${viewmode === true ? "#107785" : "lightgrey"}`,
              }}
              onClick={() => setViewmode(true)}
            />
          </div>
          <div className="bg-white rounded ml-2">
            <FaThList
              className="flex flex-wrap text-gray-300 m-1 hover:text-gray-400 "
              style={{
                fontSize: "1.5rem",
                color: `${viewmode === true ? "lightgrey" : "#107785"}`,
              }}
              onClick={() => setViewmode(false)}
            />
          </div>
        </div> */}
        <div className="w-14 flex flex-wrap"></div>
      </nav>

      <div className="mt-0 pt-0 h-screen justify-center overflow-scroll">
        <div className="backdrop-blur-sm bg-black/70 flex flex-wrap w-full h-16 sticky top-0 z-20 justify-between">
          <div className="flex flex-wrap w-[72%] sm:w-[85%] md:w-[90%]">
            <input
              className=" flex flex-wrap m-4 mr-0 p-1 pl-12 font-thin  bg-white shadow-inner text-black  outline-0 rounded-full w-full"
              type="text"
              placeholder="Find user"
              onChange={onSearch}
            ></input>
            <BsSearch className="flex flex-wrap absolute m-4 top-2 left-3 text-black" />
          </div>
          <div className="flex flex-wrap mr-2">
            <div className=" pt-4   rounded">
              <BsFillGridFill
                className=" m-1 flex flex-wrap"
                style={{
                  fontSize: "1.5rem",
                  color: `${viewmode === true ? "#107785" : "lightgrey"}`,
                }}
                onClick={() => setViewmode(true)}
              />
            </div>
            <div className="pt-4 rounded">
              <FaThList
                className="flex flex-wrap text-gray-300 m-1 hover:text-gray-400 "
                style={{
                  fontSize: "1.5rem",
                  color: `${viewmode === true ? "lightgrey" : "#107785"}`,
                }}
                onClick={() => setViewmode(false)}
              />
            </div>
          </div>
        </div>

        {viewmode ? <GridLayout /> : <ListLayout />}
      </div>
    </div>
  );
};
