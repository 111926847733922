const taskKeyName = "userTasks";
const sampleTasks = [
 
 
 //start
  {"id":"Xv2T14SF9l",
  "status":"completed",
  "owner":"Winter@gmail.com","name":"Select Subjects","createdAt":1681670917779,"layout":{"ROOT":{"type":{"resolvedName":"Container"},"isCanvas":true,"props":{"background":"#ffffff","padding":3,"className":"overflow-auto flex-auto h-full w-full"},"displayName":"Container","custom":{},"hidden":false,"nodes":["XYtmFwIfYJ","5djyrXSaOE"],"linkedNodes":{}},"XYtmFwIfYJ":{"type":{"resolvedName":"Text"},"isCanvas":false,"props":{"text":"Select your Subject?","fontSize":25},"displayName":"Text","custom":{},"parent":"ROOT","hidden":false,"nodes":[],"linkedNodes":{}},"5djyrXSaOE":{"type":{"resolvedName":"Radio"},"isCanvas":false,"props":{"option":"Maths","option2":"English","option3":"General Studies","option4":"Science"},"displayName":"Radio","custom":{},"parent":"ROOT","hidden":false,"nodes":[],"linkedNodes":{}}},
  "members":[],
  "tasks":[
    {
      "id":"ZXYW", "isReadyToDo":()=>{console.log(this.id)},  "status":"completed","owner":"Winter@gmail.com","name":"Task 1","createdAt":1681670917779,"layout":{"ROOT":{"type":{"resolvedName":"Container"},"isCanvas":true,"props":{"background":"#ffffff","padding":3,"className":"overflow-auto flex-auto h-full w-full"},"displayName":"Container","custom":{},"hidden":false,"nodes":["XYtmFwIfYJ","5djyrXSa0q"],"linkedNodes":{}},"XYtmFwIfYJ":{"type":{"resolvedName":"Text"},"isCanvas":false,"props":{"text":"Select your Maths Area?","fontSize":25},"displayName":"Text","custom":{},"parent":"ROOT","hidden":false,"nodes":[],"linkedNodes":{}},"5djyrXSa0q":{"type":{"resolvedName":"Radio"},"isCanvas":false,"props":{"option":"Metal Maths","option2":"Numbers","option3":"Trigonometry","option4":"Algebra"},"displayName":"Radio","custom":{},"parent":"ROOT","hidden":false,"nodes":[],"linkedNodes":{}}},"members":[],
      "id":"ABCD", "isReadyToDo":()=>{console.log(this.id)},  "status":"completed","owner":"Winter@gmail.com","name":"Maths Types","createdAt":1681670917779,"layout":{"ROOT":{"type":{"resolvedName":"Container"},"isCanvas":true,"props":{"background":"#ffffff","padding":3,"className":"overflow-auto flex-auto h-full w-full"},"displayName":"Container","custom":{},"hidden":false,"nodes":["XYtmFwIfYJ","5djyrXSaOE"],"linkedNodes":{}},"XYtmFwIfYJ":{"type":{"resolvedName":"Text"},"isCanvas":false,"props":{"text":"Select your Maths Area?","fontSize":25},"displayName":"Text","custom":{},"parent":"ROOT","hidden":false,"nodes":[],"linkedNodes":{}},"5djyrXSaOE":{"type":{"resolvedName":"Radio"},"isCanvas":false,"props":{"option":"Metal Maths","option2":"Numbers","option3":"Trigonometry","option4":"Algebra"},"displayName":"Radio","custom":{},"parent":"ROOT","hidden":false,"nodes":[],"linkedNodes":{}}},"members":[],
      "tasks":[
        {"id":"EFGH","status":"completed","owner":"Winter@gmail.com","name":"Question Types ","createdAt":1681670917779,"layout":{"ROOT":{"type":{"resolvedName":"Container"},"isCanvas":true,"props":{"background":"#ffffff","padding":3,"className":"overflow-auto flex-auto h-full w-full"},"displayName":"Container","custom":{},"hidden":false,"nodes":["XYtmFwIfYJ","5djyrXSaOE"],"linkedNodes":{}},"XYtmFwIfYJ":{"type":{"resolvedName":"Text"},"isCanvas":false,"props":{"text":"Select Your Question Type ","fontSize":25},"displayName":"Text","custom":{},"parent":"ROOT","hidden":false,"nodes":[],"linkedNodes":{}},"5djyrXSaOE":{"type":{"resolvedName":"Radio"},"isCanvas":false,"props":{"option":"Sum","option2":"Multiply","option3":"Minus","option4":"Divide"},"displayName":"Radio","custom":{},"parent":"ROOT","hidden":false,"nodes":[],"linkedNodes":{}}},"members":[],
        "tasks":[
          {"id":"PCDF","status":"completed","owner":"Winter@gmail.com","name":"Sum 01","createdAt":1681670917779,"layout":{"ROOT":{"type":{"resolvedName":"Container"},"isCanvas":true,"props":{"background":"#ffffff","padding":3,"className":"overflow-auto flex-auto h-full w-full"},"displayName":"Container","custom":{},"hidden":false,"nodes":["XYtmFwIfYJ","5djyrXSaOE"],"linkedNodes":{}},"XYtmFwIfYJ":{"type":{"resolvedName":"Text"},"isCanvas":false,"props":{"text":" 3 + 7 = ","fontSize":25},"displayName":"Text","custom":{},"parent":"ROOT","hidden":false,"nodes":[],"linkedNodes":{}},"5djyrXSaOE":{"type":{"resolvedName":"Radio"},"isCanvas":false,"props":{"option":"10","option2":"13","option3":"24","option4":"9"},"displayName":"Radio","custom":{},"parent":"ROOT","hidden":false,"nodes":[],"linkedNodes":{}}},"members":[]},
          {"id":"PCDR","status":"completed","owner":"Winter@gmail.com","name":"PCDR Sum 02","createdAt":1681670917779,"layout":{"ROOT":{"type":{"resolvedName":"Container"},"isCanvas":true,"props":{"background":"#ffffff","padding":3,"className":"overflow-auto flex-auto h-full w-full"},"displayName":"Container","custom":{},"hidden":false,"nodes":["XYtmFwIfYJ","5djyrXSaOE"],"linkedNodes":{}},"XYtmFwIfYJ":{"type":{"resolvedName":"Text"},"isCanvas":false,"props":{"text":" 3 + 9 = ","fontSize":25},"displayName":"Text","custom":{},"parent":"ROOT","hidden":false,"nodes":[],"linkedNodes":{}},"5djyrXSaOE":{"type":{"resolvedName":"Radio"},"isCanvas":false,"props":{"option":"10","option2":"13","option3":"12","option4":"9"},"displayName":"Radio","custom":{},"parent":"ROOT","hidden":false,"nodes":[],"linkedNodes":{}}},"members":[]},
          {"id":"PCDT","status":"completed","owner":"Winter@gmail.com","name":"Sum 03","createdAt":1681670917779,"layout":{"ROOT":{"type":{"resolvedName":"Container"},"isCanvas":true,"props":{"background":"#ffffff","padding":3,"className":"overflow-auto flex-auto h-full w-full"},"displayName":"Container","custom":{},"hidden":false,"nodes":["XYtmFwIfYJ","5djyrXSaOE"],"linkedNodes":{}},"XYtmFwIfYJ":{"type":{"resolvedName":"Text"},"isCanvas":false,"props":{"text":" 3 + 10 = ","fontSize":25},"displayName":"Text","custom":{},"parent":"ROOT","hidden":false,"nodes":[],"linkedNodes":{}},"5djyrXSaOE":{"type":{"resolvedName":"Radio"},"isCanvas":false,"props":{"option":"10","option2":"13","option3":"12","option4":"9"},"displayName":"Radio","custom":{},"parent":"ROOT","hidden":false,"nodes":[],"linkedNodes":{}}},"members":[]},
        ]
      },
      
      ]
    },
  ]
  },
  //end
    { "id":"AB2T14SF9l",
      "status": "progress",
      "owner":"Anny@gmail.com",
      "name":"Task-Two",
      "createdAt":1681670712779,
      "layout":{
        "ROOT": {
          "type": {
              "resolvedName": "Container"
          },
          "isCanvas": true,
          "props": {
              "background": "#ffffff",
              "padding": 3,
              "className": "overflow-auto flex-auto h-full w-full"
          },
          "displayName": "Container",
          "custom": {},
          "hidden": false,
          "nodes": [
              "QSFef7z5WT"
          ],
          "linkedNodes": {}
      },
      "QSFef7z5WT": {
          "type": {
              "resolvedName": "Email"
          },
          "isCanvas": false,
          "props": {
              "nametag": "Email"
          },
          "displayName": "Email",
          "custom": {},
          "parent": "ROOT",
          "hidden": false,
          "nodes": [],
          "linkedNodes": {}
      }
      },
      "tasks":[],
      "members" : []
    },
    { "id":"Xv2TSD5SF9l",
      "status": "progress",
      "owner":"Rock@gmail.com",
      "name":"Task-Three",
      "createdAt":1681670300779,
      "layout":{
      "ROOT":{
      "type":{"resolvedName":"Container"},"isCanvas":true,"props":{"background":"#ffffff","padding":3,"className":"overflow-auto flex-auto h-full w-full"},"displayName":"Container","custom":{},"hidden":false,"nodes":["8SB_AIYMlR"],"linkedNodes":{}},"8SB_AIYMlR":{"type":{"resolvedName":"Button"},"isCanvas":false,"props":{"size":"small","variant":"contained","color":"primary","text":"Click me 3"},"displayName":"Button","custom":{},"parent":"ROOT","hidden":false,"nodes":[],"linkedNodes":{}}
        },
      "members" : [],
      "tasks":[]
    },
];
export const getAllTasks = () => {
    let tasks = [];
    try {
        tasks = JSON.parse(localStorage.getItem(taskKeyName)) || [];
    } catch (e) {
      console.log(e);
    }
    if(tasks.length === 0){
      saveAllTasks([]);
      return [];
    }
    return tasks;
  };
export const saveAllTasks = (userTasks)=>{
    localStorage.setItem(taskKeyName, JSON.stringify(userTasks));
}

export const addUpdateTask = (userTask,parentTaskId) => {
  if(!userTask._id){
    userTask._id = `${generateId()}`;
    userTask.status = "progress";
    userTask.owner = JSON.parse(localStorage.getItem('currentUser')).email;
    userTask.members = [];
    userTask.createdAt = Date.now();
    addUserTask(userTask,parentTaskId);
  }else {
    updateUserTask(userTask,parentTaskId);
  }
  return userTask;
}

export const deleteUserTask = (taskId,parentTaskId) => {
  const userTasks = getAllTasks();
  let updatedTasks;
  if(!parentTaskId || parentTaskId === 'root' ){
    updatedTasks = userTasks.filter((item)=>(item._id !== taskId));
  }else{
    updatedTasks = userTasks;
    let idArr = []
    if(parentTaskId && typeof(parentTaskId)==='string'){
      idArr = parentTaskId.split("-");
    }
    let task,taskArr = userTasks; 
    for(let i=0;i<idArr.length; i++){
        task = getTaskById(idArr[i],taskArr);
        taskArr= task.tasks?task.tasks:[];
      }
      task.tasks = taskArr.filter((item)=>(item._id !== taskId))
  }
  saveAllTasks(updatedTasks)
};

export const updateUserTask = (userTask,parentTaskId) => {
  const userTasks = getAllTasks();
  let updatedTasks;
  if(!parentTaskId || parentTaskId === 'root' ){
    let idFound = false;
    updatedTasks = userTasks.map((item)=>{
      if(item._id === userTask._id){
        idFound = true;
        return userTask;
      }else{
        return item;
      }
    })
    if(!idFound){
      updatedTasks.push(userTask)
    }
  }else{
    updatedTasks = userTasks;
    let idArr = []
    if(parentTaskId && typeof(parentTaskId)==='string'){
      idArr = parentTaskId.split("-");
    }
    let task,taskArr = userTasks; 
    for(let i=0;i<idArr.length; i++){
        task = getTaskById(idArr[i],taskArr);
        taskArr= task.tasks?task.tasks:[];
      }
      for(let i=0;i<taskArr.length; i++){
        if(taskArr[i]._id === userTask._id){
          taskArr[i] = userTask;
          break;
        }
      }
  }
  saveAllTasks(updatedTasks)
};

const addUserTask = (userTask,parentTaskId) => {
    let userTasks = getAllTasks();
    if(!parentTaskId || parentTaskId === 'root'){
      if (userTasks && userTasks.length) {
        userTasks.push(userTask);
      } else {
        userTasks = [userTask];
      }
    }else {
        let idArr = []
        if(parentTaskId && typeof(parentTaskId)==='string'){
          idArr = parentTaskId.split("-");
        }
        let task,taskArr = userTasks; 
        for(let i=0;i<idArr.length; i++){
            task = getTaskById(idArr[i],taskArr);
            taskArr= task.tasks?task.tasks:[];
          }
          if(!task.tasks){
            task.tasks = []
          }
          task.tasks.push(userTask);
    }
    saveAllTasks(userTasks);
  };
  
  const getTaskById = (id,tasksArr) => {
    if(!tasksArr){
      tasksArr = getAllTasks();
    }
      const tasks = tasksArr.filter((item) => item._id === id);
      if(tasks.length){
        return tasks[0];
      }else{
        return  {name:""};
      }
  };
  export const getUserTask = (id) => {
    if(!id){
      return {name:''};
    }
    let idArr = []
    if(id && typeof(id)==='string'){
      idArr = id.split("-");
    }
    let task,taskArr = undefined; 
    for(let i=0;i<idArr.length; i++){
        task = getTaskById(idArr[i],taskArr);
        taskArr= task.tasks?task.tasks:[];
      }
      return task;
    };
  
  export const deleteUserTasks = (id) => {
    let userTasks = getAllTasks();
    if (userTasks) {
      userTasks?.splice(id, 1);
    }
    saveAllTasks(userTasks);
  };

  const hasDashIndex = (stringId) =>{
    return (stringId.indexOf('-') < 0)
    //stringId.in
  }
  export const getAllChildTasksCount = (taskId) => {
    const tasks = getAllTasks();
    if(!taskId){
      return "";
    }
    const subTasks = tasks.filter((item)=>(item._id.startsWith(`${taskId}`)))
    return subTasks.length;
  };

  export const getAllSubTasks = (taskId) => {
    const tasks = getAllTasks();
    if(!taskId){
      return tasks;
    }
    
    const parentTasks = tasks.filter((item)=>(item._id === taskId));
    if(parentTasks.length > 0 && parentTasks[0].tasks){
      return parentTasks[0].tasks;
    }else{
      return [];
    }
  };

  export const arrangeTasks = (input) => {
    const output = [];
    const taskMap = {};

    for (const task of input) {
      taskMap[task._id] = task;
    }

    for (const task of input) {
      const parentId = task.parentId;
  
      if (parentId) {
        if(!taskMap[parentId]){
          output.push(task);
        }else{
        const parentTask = taskMap[parentId];
        if (parentTask) {
          parentTask.tasks.push(task);
        }
        }
      } else {
        output.push(task);
      }
    }
    return output;
  }
  
export const generateId = (length = 10) => {
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = "";
    for (let i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
