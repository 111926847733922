import React from "react";
// import Sidebar from "./Sidebar";
import { UserCard } from "./UserCard";

const UserCardDemo = () => {
  // const imgType = {
  //   Square:
  //     "w-full h-60 object-cover rounded-lg sm:h-52 sm:col-span-2 lg:col-span-full",
  //   Rectangle:
  //     "w-full h-52 object-cover rounded-lg sm:block sm:col-span-2 md:col-span-1 lg:row-start-2 lg:col-span-2 lg:h-32",
  //   Circle:
  //     "w-16 h-16 object-cover rounded-full sm:w-24 sm:h-24 md:w-32 md:h-32 lg:w-32 lg:h-32 xl:w-40 xl:h-40",
  // };

  const activityStatus = {
    Online: "bg-green-500",
    Offline: "bg-red-500",
  };

  const dummyUser = Array.from({ length: 6 }, (_, i) => i + 1).map((i) => ({
    id: i,
    name: `User ${i}`,
    email: `user@${i}.com`,
    img: `https://source.unsplash.com/random/?music=${i}`,
    imgType: "Circle",
    activityStatus: Object.values(activityStatus)[i],
  }));

  return (
    <>
        <div className="w-full h-full bg-slate-200">
          <div className="flex flex-wrap justify-center items-center">
            {dummyUser.map((user, index) => (
              <UserCard
                key={index}
                userData={user}
                // imgClassName={imgType[user.imgType]}
              />
            ))}
          </div>
        </div>
    </>
  );
};

export default UserCardDemo;
