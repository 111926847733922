import { useNode } from "@craftjs/core";
import React, { useState, useEffect, useRef } from "react";
import { MdOutlineClear, MdAudioFile } from "react-icons/md";
import { Slider, FormControl, FormLabel } from "@material-ui/core";

export const Audio = ({ audioSize,text,audioSrc, ...props }) => {
  const {
    connectors: { connect, drag },
    selected,
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);

  const [audioPreview, setAudioPreview] = useState(audioSrc || null);
  const isReadOnly = window.location.href.includes('view')

  // FIle Picker Ref because we are not useing the standard File picker input
  const filePicekerRef = useRef(null);

  function previewFile(e) {
    // Reading New File (open file Picker Box)
    const reader = new FileReader();

    // Gettting Selected File (user can select multiple but we are choosing only one)
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    // As the File loaded then set the stage as per the file type
    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes("video")) {
        setAudioPreview(readerEvent.target.result);
        setProp(
          (props) =>
            (props.audioSrc = readerEvent.target.result),
          500
        )
      }
    };
  }

  function clearFiles() {
    setAudioPreview(null);
  }

  return (
    <div
      {...props}
      ref={(ref) => connect(drag(ref))}
      onClick={() => selected && setEditable(true)}
    >
      <div>
        <div className="flex flex-wrap rounded-lg m-2 bg-gray-300 w-fit lg:m-2 xl:m-2 2xl:m-2">
        <div className="btn-container" style={{
            display: isReadOnly ? 'none' : 'block',
          }}>
            <input
              ref={filePicekerRef}
              accept="audio/*"
              onChange={previewFile}
              type="file"
              hidden
            />
            <div className="flex flex-wrap">
              <button
                className="btn flex bg-white font-semibold text-gray-500 p-2 m-2 rounded"
                onClick={() => filePicekerRef.current.click()}
              >
                Choose Audio
                <MdAudioFile className="ml-2 mt-1 mr-1" />
              </button>
              {audioPreview && (
                <button
                  className="btn text-red-200 border bg-red-500 m-2 rounded p-2"
                  onClick={clearFiles}
                >
                  <MdOutlineClear />
                </button>
              )}
            </div>
          </div>

          <div className="preview">
            {audioPreview != null && (
              <audio
                className="m-2"
                type="audio/mpeg"
                controls
                src={audioPreview}
                style={{ width: `${audioSize}px` }}
              ></audio>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const AudioSettings = () => {
  const {
    actions: { setProp },
    audioSize,
  } = useNode((node) => ({
    audioSize: node.data.props.audioSize,
  }));

  return (
    <div className="m-1 ml-4">
      <FormControl size="small" component="fieldset">
        <FormLabel style={{fontWeight:"800",color:"white" }} component="legend">Audio size</FormLabel>
        <Slider
          value={audioSize || 200}
          step={10}
          min={200}
          max={350}
          color="secondary"
          onChange={(_, value) => {
            setProp((props) => (props.audioSize = value), 1000);
          }}
        />
      </FormControl>
    </div>
  );
};

export const ImageDefaultProps = {
  audioSize: 200,
};

Audio.craft = {
  props: ImageDefaultProps,
  related: {
    settings: AudioSettings,
  },
};

