import React from "react";
import { useParams } from 'react-router';
import { TaskRead } from "./TaskRead";
import { NavBar } from "../NavBar";
import { getUserTask } from "../../services/UserTaskService";
import { getCurrentUser } from "../../services/UserService";
import {TaskEditor} from "./TaskEditor";
import { getRoles } from "../../services/RoleService";

export const Task = () => {
  let { id,parentId} = useParams();
  if(parentId && parentId!='root'){
    id = `${parentId}-${id}`;
  }
  const task = getUserTask(id);
  let rolePermission = getRoles();
  const isEditor =
    task?.owner?.toLocaleLowerCase() ==
      getCurrentUser()?.email?.toLocaleLowerCase() ||
    task?.members?.find(
      (m) =>
        (m?.email?.toLocaleLowerCase() ===
        getCurrentUser()?.email?.toLocaleLowerCase()) && m?.roleId._id === (rolePermission.find((data)=> data?._id === m?.roleId._id && data.permissions?.includes('Owner'))?._id || rolePermission.find((data)=> data?._id === m?.roleId._id && data?.permissions.includes('Executor'))?._id)
    );

  if (!task) {return <div> <NavBar/> <p> No task found with id: {id} .</p></div>};
  if (isEditor) return <TaskEditor/>;
  return <TaskRead/>;
};
