import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../Reusables/Button";
import { TextInput } from "../Reusables/TextInput";
import { RolesCheckbox } from "./RolesCheckBox";
import { generateId, getUserTask } from "../../services/UserTaskService";
import { addRole, getRoles } from "../../services/RoleService";
import { allRole, createRole } from "../../services/ApiServices/RoleApiServices";

export const AddRole = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const inputRef = useRef(null);
  const [role, setRole] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isRoleExists, setIsRoleExists] = useState(false);
  const [roles,setRoles] = useState([]);

  const getAllRoles = () => {
    allRole().then((data)=>{
      if(data.result){
        setRoles(data.result)
        localStorage.setItem("roles", JSON.stringify(data.result));
        if(data.result){
          if(!id){
            navigate(`/roles/all`);
          }else{
            navigate(`/roles/invite/${role}/${id}`);
          }
        }
      }
    }
    );
  }

  const checkRoleExists = () => {
    const role = roles;
    const roleExists = role.length > 0 && role?.find(
      (r) => r?.name?.toLowerCase() === role?.toLowerCase()
    );
    return roleExists;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!role?.length) return;
    if (checkRoleExists()) {
      setIsRoleExists(true);
      setRole("");
      inputRef.current?.focus();
      return;
    }
    // Need to verify (Dhaval)
      const payload = {
            name: role,
            permissions: selectedRoles?.length
              ? selectedRoles?.map((r) => r?.name)
              : []
      };
      // addRole(payload);
      createRole(payload);
      setTimeout(()=>{
        getAllRoles();
      },1000)
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen bg-gradient-to-l from-slate-500 to-slate-100">
      <div className="absolute top-4 left-4">
        <span
          onClick={() => navigate(-1)}
          className="cursor-pointer rounded-full bg-gray-400 p-2 top-0 left-0 w-8 h-8 flex flex-row items-center justify-center text-white border-2 border-white"
        >
          <i className="pi pi-angle-left" />
        </span>
      </div>
      <div className="container self-center rounded-md h-auto max-h-full bg-white justify-center p-4 overflow-auto w-auto min-w-[25%]">
        <div className="flex flex-col items-center justify-center px-5 mx-auto my-8">
          <div className="max-w-md text-center">
            <p className="text-2xl font-semibold md:text-3xl">Add Role</p>
            <p className="mt-4 mb-8 dark:text-gray-500">
              Please add role to continue.
            </p>
            <form
              onSubmit={onSubmit}
              className="flex flex-col items-center justify-center"
            >
              <TextInput
                label="Role"
                placeholder="e.g. manager"
                type="text"
                name="role"
                mandatory={true}
                id="role"
                onChange={(e) => {
                  setRole(e.target.value);
                  setIsRoleExists(false);
                }}
                value={role}
                isSearchBar={true}
                ref={inputRef}
              />
              <br />
              <RolesCheckbox
                selectedRoles={selectedRoles}
                setSelectedRoles={setSelectedRoles}
              />
              <Button
                type="submit"
                label="Add Role"
                disabled={!role?.length || !selectedRoles?.length}
                children={<i className="mx-2 pi pi-plus" />}
              />
            </form>
            <p
              className={`m-2 text-red-400 text-sm ${
                isRoleExists ? "" : "hidden"
              }`}
            >
              Role already exists.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
