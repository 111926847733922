import { useNode } from "@craftjs/core";
import React, { useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import {FormControl,FormLabel} from '@material-ui/core';
import ContentEditable from 'react-contenteditable';

export const Time = ({ text,nametag, ...props }) => {
  const [time, setTime] = useState(null);

  const {
    connectors: { connect, drag },
    selected,
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));
  const [editable, setEditable] = useState(false);

  const selectedValue = (e) => {
    setTime(e.value)
    setProp(
      (props) =>
        (props.selectedvalue = e.value)
    )
  }

  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);

  return (
    <div
      {...props}
      ref={(ref) => connect(drag(ref))}
      onClick={() => selected && setEditable(true)}
    >
       <div className="w-full md:w-1/4 m-2 px-2">
        <label className="block  tracking-wide text-gray-700 text-md font-bold ">{nametag}</label>
        <Calendar
        id="calendar-timeonly"
          value={props.selectedvalue !== undefined ? new window.Date(props.selectedvalue) : time}
          timeOnly
          type="text"
          onChange={(e) =>
            selectedValue(e)
          }
        />
      </div>
    </div>
  );
};
export const ButtonSettings = () => {
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props,
  }));

  return (
    <div className="m-1 ml-4">
    <FormControl size="small" component="fieldset">
      <FormLabel style={{fontWeight:"800",color:"white" }} component="legend">Edit tag text</FormLabel>
        <ContentEditable
          html={props.nametag}
          className="my-2 p-2 text-white border border-white rounded-lg"
          onChange={(e) =>
            setProp(
              (props) =>
                (props.nametag = e.target.value.replace(
                  /<\/?[^>]+(>|$)/g,
                  "",
                  " "
                ))
            )
          }
          tagName="p"
        />
      </FormControl>
    </div>
  );
};

export const ButtonDefaultProps = {
  nametag: "Time",
};

Time.craft = {
  props: ButtonDefaultProps,
  related: {
    settings: ButtonSettings,
  },
};

