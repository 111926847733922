import React, { useEffect } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import {BiUserCircle} from "react-icons/bi";
//import DummyUser from "../assets/Images/user.webp";
import { getCurrentUser } from "../services/UserService";
import { FaUserCog } from "react-icons/fa";
import { GrStorage } from "react-icons/gr";
import { initDummyUsers } from "../services/UserService";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export const NavBar = ({title}) => {
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  const currentUserEmail = currentUser !== null && currentUser["email"];
  let emailUser ='dhaval.jani@pyther.com'

  useEffect(() => {
    if (!currentUser) {
      navigate("/user/select");
    }
  }, []);
  return (
    <div className="h-16 bg-cyan-800 flex flex-auto ">
      <div
        // onClick={() => navigate("/task/")}
        className="h-full flex"
      >
        <img
          src="/task-manager.png"
          alt="logo"
          onClick={() => navigate(`/task/${currentUserEmail}`)}
          className="w-10 h-10 rounded-full m-2"
        />
      </div>
      {title && 
      <div className="p-2 m-1 items-center flex flex-wrap rounded-lg" style={{color:'white', fontWeight:'800',marginLeft:'auto'}}>
      <span className="title-data">{title}</span>
      </div>
      }
      {/* <div className="flex flex-auto mr-4 items-center justify-space-between ">
      <div className="w-11/12 h-full flex">
        <img
          src={DummyUser}
          alt="logo"
          className="w-10 h-10 rounded-full m-2"
        />
      </div>
      </div> */}
      {/* <div
        onClick={() => {
          navigate("/user/select");
        }}
        className="p-1 m-1 items-center bg-gray-300 rounded-lg"
      >
        </div> */}
      {/* <label className="text-sky-700 ml-3  text-md font-bold  w-fit  whitespace-nowrap ">
          {currentUser?.email}
        </label>
        <div>
          <label className="ml-3 text-sm font-bold text-gray-700 w-fit  whitespace-nowrap ">
            Change User
          </label>
      </div> */}

      <div className="p-2 m-1 items-center flex flex-wrap rounded-lg" style={{marginLeft:'auto'}}>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex  justify-center gap-x-1.5  text-cyan-500 rounded-md bg-white px-3 py-2 text-xs font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-17 h-8 sm:w-full sm:h-10 sm:text-sm">
              {currentUser && currentUser?.email}<MdKeyboardArrowDown
                className="-mr-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1 ">
              {currentUser && currentUser?.email !== emailUser ? null :
                <Menu.Item>
                  {({ active }) => (
                    <a
                      onClick={() => {
                        navigate("/user/select");
                      }}
                      className={classNames(
                        active ? "flex bg-gray-100  text-gray-900" : "flex text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    ><BiUserCircle className="flex flex-wrap mr-2" style={{fontSize:"1rem"}}/>
                      Change User
                    </a>
                  )}
                </Menu.Item>}
                {/* <Menu.Item>
                  {({ active }) => (
                    <a
                      onClick={() => {
                        navigate("/roles/all");
                      }}
                      className={classNames(
                        active ? "flex bg-gray-100  text-gray-900" : "flex text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    ><FaUserCog className="flex flex-wrap mr-2" style={{fontSize:"1rem"}}/>
                      Change Role
                    </a>
                  )}
                </Menu.Item> */}
                <Menu.Item>
                  {({ active }) => (
                    <a
                      onClick={() => {
                        localStorage.clear();
                        initDummyUsers();
                        navigate('/')
                      }}
                      className={classNames(
                        active ? "flex bg-gray-100  text-gray-900" : "flex text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    ><GrStorage className="flex flex-wrap mr-2" style={{fontSize:"1rem"}}/>
                      Clear Storage
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};
