import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { TaskRead } from "./../TaskMaker/TaskRead";
import TaskCreated from "../../assets/Images/taskCreated.png";
import TaskMembers from "../../assets/Images/taskMembers.png";
// import TaskPreview from "../../assets/Images/taskPreview.png";
import TaskBlankImg from "../../assets/Images/task-blank.png";
import { getAllSubTasks, getAllTasks, getUserTask, deleteUserTask, saveAllTasks, updateUserTask, deleteUserTasks } from "../../services/UserTaskService";
import { getCurrentUser } from "../../services/UserService";
import { BiEdit } from "react-icons/bi";
import { getOutput, getUserAllOutput } from "../../services/OutputService";
import { getRoles } from "../../services/RoleService";
import { FaTasks, FaUsers, FaUserPlus } from "react-icons/fa";
import { MdOutlineManageAccounts } from "react-icons/md";
import { RxDragHandleDots2 } from "react-icons/rx";
import { MdQuestionAnswer, MdOutput } from "react-icons/md";
import { TbHierarchy } from "react-icons/tb"
import { GrStatusInfo, GrView } from "react-icons/gr"
import { AiOutlineArrowRight, AiFillEdit, AiFillSetting, AiFillFileAdd, AiFillFolderOpen, AiFillPlusCircle, AiFillDelete, AiOutlinePlus } from "react-icons/ai";
import { BiSitemap } from "react-icons/bi"
import { BsSendFill } from "react-icons/bs"
import { RxCross1, RxCross2, RxMagnifyingGlass } from "react-icons/rx";
import "react-tooltip/dist/react-tooltip.css";
import "./TaskCard.css";
import { getChildTaskId, getEditTaskUrl } from "../../utils";
import { Tooltip } from 'react-tooltip';
import { Snackbar } from "@material-ui/core";
import { Button } from "../Reusables/Button";
import { BsFillGridFill } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { FaThList, FaPlus, FaUserAlt } from "react-icons/fa";
import { HiArrowSmRight, HiArrowSmDown, HiInformationCircle, HiDuplicate } from "react-icons/hi";
import { RiEditBoxFill } from "react-icons/ri";
import { MdEditSquare, MdManageAccounts, MdPreview } from "react-icons/md";
import { ImCancelCircle } from "react-icons/im";
import { MdOutlineZoomOutMap } from "react-icons/md";
import { LayoutPreview } from "../TaskMaker/LayoutPrieview";
import { Container, Link, Button as Btn } from 'react-floating-action-button';
import { IoIosArrowDropdown, IoMdAddCircleOutline, IoIosSearch } from "react-icons/io";
import { Switch } from "../Reusables/Switch";
import { Snack } from "../Reusables/Snack";
import DropdownMenu from "../Reusables/Dropdown";
import NavbarFooter from "../Reusables/NavbarFooter";
import View from "../Reusables/View";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { updateTask } from "../../services/ApiServices/TaskApiServices";
import { createTask } from "../../services/ApiServices/TaskApiServices";
import DebounceInput from 'react-debounce-input';

const TaskCard = ({ item, tasks, taskId, reload, setSelected, isSelected, setDeletedTask, userEmailId, setCopyTask, isCopied }) => {
    const [selectedTask, setSelectedTask] = useState();
    const [snackbarMessage, setSnackbarMessage] = useState();
    let outputData = getOutput();
    let rolePermission = getRoles();
    const navigate = useNavigate();
    const [isModalView, setModalView] = useState(false)
    const [isContentView, setContentView] = useState(true)
    const [isAcceptView, setAcceptView] = useState(false)
    const [isGridView, setIsGridView] = useState(false);
    const [isChildView, setChildView] = useState(false);
    const [selectedListTask, setSelectedListTask] = useState();
    const [expandedTasks, setExpandedTasks] = useState({});
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [dataempty, setDataEmpty] = useState(false);
    const [isarrowDropdownOpen, arrowsetDropdownOpen] = useState(false);
    const [value, setValue] = useState(false);
    const [showSnack, setShowSnack] = useState(false);
    const [selectedValue, setSelectedValue] = useState('List View');
    const outputoftask = getUserAllOutput(item.id);
    const [alltasks, setalltasks] = useState(item.tasks);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeTab, setActiveTab] = useState(null);
    const parentRef = useRef(null);
    const childRef = useRef(null);
    const [selectedRoleName, setSelectedRoleName] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const [breadcrumbData, setbreadcrumbData] = useState([
        {
            "name": activeTab ? capitalizeFirstLetter(activeTab) : null,
            "child": selectedRoleName ? capitalizeFirstLetter(selectedRoleName) : null,
        }
    ]);

    useEffect(() => {
        if (activeTab !== null && selectedRoleName !== null) {
            setbreadcrumbData([{ "name": capitalizeFirstLetter(activeTab), "child": capitalizeFirstLetter(selectedRoleName) }])
        } else if (activeTab !== null && selectedRoleName === null) {
            setbreadcrumbData([{ "name": capitalizeFirstLetter(activeTab), "child": null }])
        } else {
            setbreadcrumbData([{ "name": null, "child": null }])
        }
    }, [activeTab, selectedRoleName])

    const checkParentData = (name) => {
        if (name !== null) {
            setSelectedRoleName(null)
            setSelectedRole(null)
        }
    }

    const onhandlecopytask = () => {
        const copyTask = { ...item };
        copyTask.name = `copy_${item.name}`;
        delete copyTask.createdAt;
        delete copyTask.updatedAt;
        delete copyTask.members;
        delete copyTask._id;
        delete copyTask.__v;
        createTask(copyTask);
        setSnackbarMessage("Task Copied Successfully !!")
        setCopyTask(true)
    }

    const handleDragEnd = (e) => {
        if (!e.destination) return;
        let tempData = Array.from(item.tasks);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        tempData.map((data, index) => {
            data['sequenceId'] = index;
        })
        // Add with sequence
        // Update with sequence
        // Delete with sequence
        const payload = {
            ...item,
            tasks: tempData
        }
        item = payload;
        // var a=updateTask(item._id,payload);
        // updateTask(item._id,payload)
    };

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value)
        const filteredData = item?.tasks.filter((data) => {
            return data?.name?.toLowerCase().includes(event.target.value.toLowerCase())
        });
        setTimeout(() => {
            setalltasks(filteredData);
        }, 1000);
    };

    const toggle = () => {
        setShowSnack(true);
        setShowSnack(!showSnack);
        arrowsetDropdownOpen(false);
        setOpenDropdownId(false);
    };

    const toggleTaskItem = (taskId) => {
        setExpandedTasks((prevExpandedTasks) => ({
            ...prevExpandedTasks,
            [taskId]: !prevExpandedTasks[taskId],
        }));
    };

    const arrowtoggleDropdown = () => {
        arrowsetDropdownOpen(!isarrowDropdownOpen);
    };

    const handleOptionClick = (value) => {
        setSelectedValue(value);
    };

    const toggleDropdown = (taskId) => {
        setOpenDropdownId((prevId) => (prevId === taskId ? null : taskId));
    };

    useEffect(() => {
        setDataEmpty(!item?.tasks?.length > 0);
    }, [item?.tasks]);

    const handleContentViewClick = () => {
        setChildView(value);
        setContentView(!value);
        arrowsetDropdownOpen(value);
    };

    const handleChildViewClick = () => {
        setChildView(!value)
        setContentView(value);
        arrowsetDropdownOpen(false);
    };

    const handleGridViewClick = () => {
        setIsGridView(true);
        arrowsetDropdownOpen(false);
    };

    const handleListViewClick = () => {
        setIsGridView(false);
        arrowsetDropdownOpen(false);
    };

    const handleTaskItemClick = (taskId) => {
        setSelectedListTask(taskId);
    };

    const checkRolePermission = (item) => {
        return (item?.owner === getCurrentUser()?.email || item?.members?.find(
            (m) =>
                (m?.email?.toLocaleLowerCase() ===
                    getCurrentUser()?.email?.toLocaleLowerCase()) && m?.roleId._id === (rolePermission.find((data) => data?._id === m?.roleId._id && data?.permissions?.includes('Owner'))?._id || rolePermission.find((data) => data?._id === m?.roleId._id && data?.permissions.includes('Executor'))?._id)
        ))
    }

    const checkStatusName = (item) => {
        let data = item?.members?.filter((m) => (
            m?.email?.toLocaleLowerCase() === getCurrentUser()?.email?.toLocaleLowerCase()))[0]?.status;
        return data === 'I' ? 'Invited' : data === 'A' ? 'Accepted' : data === 'D' ? 'Declined' : '';
    }

    const updateStatus = (name) => {
        const task = getUserTask(item._id);
        let updatedUser = task?.members.find(user => user?.email.toLocaleLowerCase() === getCurrentUser()?.email?.toLocaleLowerCase());
        updatedUser.status = name == 'Accept' ? "A" : 'D';
        const payload = {
            ...task
        };
        updateUserTask(payload);
    }

    const statusDecline = () => {
        updateStatus('Decline')
        setSelected(false)
        setAcceptView(false)
    }

    const statusConfirmation = () => {
        updateStatus('Accept')
        setSelected(false)
        setAcceptView(false)
    }

    const checkInvitedStatus = (id) => {
        setSelected(true)
        setSelectedTask(id);
    }
    const handleListDeleteTask = (id, taskId) => {
        deleteUserTask(taskId, id);
        setDeletedTask(true);
    };

    const handleGridDeleteTask = (id, taskId) => {
        deleteUserTask(taskId, id);
        setDeletedTask(true);
    };
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isarrowDropdownOpen && parentRef.current && !parentRef.current.contains(e.target)) {
                arrowsetDropdownOpen(null);
            }
        };

        document.addEventListener("click", checkIfClickedOutside);

        return () => {
            document.removeEventListener("click", checkIfClickedOutside);
        };
    }, [isarrowDropdownOpen]);

    useEffect(() => {
        const checkIfClickedOutsideDropdown = (e) => {
            if (openDropdownId && childRef.current && !childRef.current.contains(e.target)) {
                setOpenDropdownId(null);
            }
        };

        document.addEventListener("click", checkIfClickedOutsideDropdown);

        return () => {
            document.removeEventListener("click", checkIfClickedOutsideDropdown);
        };
    }, [openDropdownId]);

    return (
        <>
            <div key={item._id} className="max-w-sm  border m-5 rounded-lg shadow-lg bg-gray-200 ">
                <div className="p-0" style={{ cursor: 'pointer', width: '20rem' }}>
                    <div className="head-text" >
                        <div className="head-image">
                            <div className="vip-card">
                                <div className="music-pack">
                                    <p className="title">{tasks ? (item.name) : ("No Name")}</p>
                                </div>
                                <div className="floating-switch-button">
                                    <label htmlFor="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
                                        <input id="Toggle3" type="checkbox" className="hidden peer" />
                                        <Switch
                                            isOn={value}
                                            handleToggle={() => setValue(!value)}
                                            handleContentViewClick={() => handleContentViewClick()}
                                            handleChildViewClick={() => handleChildViewClick()}
                                            keyValue={item._id}
                                            disabled={showSnack}
                                        />
                                    </label>
                                </div>
                                <div className="information">
                                    {!showSnack ? (
                                        <MdManageAccounts onClick={toggle} />
                                    ) : (
                                        <ImCancelCircle onClick={toggle} />
                                    )}
                                </div>
                            </div>

                            <div className="flex breadcrumb" aria-label="Breadcrumb" hidden={showSnack}>
                                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                                    <li className="inline-flex items-center">
                                        <span className="breadcrumb-data inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white" onClick={() => setActiveTab(null)} style={{ width: item?.name?.length > 7 ? '6rem' : '3rem' }}>
                                            {tasks ? (item.name) : ("No Name")}
                                        </span>
                                    </li>
                                    {breadcrumbData?.length > 0 && breadcrumbData.map((data,index) => {
                                        return (
                                            <li key={index} className="inline-flex items-center" style={{ margin: '0px' }}>
                                                <svg hidden={data.name === null} aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                                <span style={{ paddingLeft: data?.name?.length > 7 ? '3rem' : '0rem' }} className="breadcrumb-data inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white" onClick={() => checkParentData(data?.name)}>
                                                    {data.name}
                                                </span>
                                                <svg hidden={data.name === null || data.child === null} aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                                <span hidden={data.name === null} style={{ paddingLeft: data?.child?.length > 7 ? '3rem' : '0rem' }} className="breadcrumb-data inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                                    {data.child}
                                                </span>
                                            </li>
                                        )
                                    })}
                                </ol>
                            </div>

                            {item?.tasks?.length > 0 &&
                                <div className="parent">
                                    <div className="head-btn main-button" hidden={showSnack}>
                                        <div className="arrow-value" hidden={!isChildView}>
                                            <div className="arrow-value-preview">
                                                <DebounceInput
                                                    minLength={2}
                                                    debounceTimeout={100}
                                                    onChange={(e) => handleInputChange(e)}
                                                    value={searchTerm}
                                                    className="search-input" placeholder="Search..."
                                                />
                                            </div>
                                        </div>
                                        <div className="dropdown-arrow" hidden={!isChildView} ref={parentRef}>

                                            <MdPreview title="Preview Task" onClick={() => { item?.tasks?.length > 0 && navigate(`/task/preview/${getChildTaskId(item._id, taskId)}`) }} />
                                            {item?.tasks?.length > 5 && checkRolePermission(item) ?
                                                <IoMdAddCircleOutline
                                                    title={'Add Subtask'}
                                                    className="sub-task-icon"
                                                    onClick={() => { navigate(`/task/${getChildTaskId(item._id, taskId)}/create`) }} />
                                                : null
                                            }
                                            <IoIosArrowDropdown onClick={() => { arrowtoggleDropdown(); setOpenDropdownId(null) }} />
                                            {isarrowDropdownOpen && (
                                                <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1" style={{ width: '8.5rem' }}>
                                                    <div className="py-1" role="none">
                                                        <span className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-1" style={{ lineHeight: '1.75rem' }}>
                                                            <div className="flex items-center dropdown-parents" style={{ backgroundColor: `${!isGridView === true ? "#448995" : ""}`, color: `${!isGridView === true ? "white" : "black"}` }} onClick={() => { handleListViewClick(); handleOptionClick('List View'); arrowsetDropdownOpen(false); }}>
                                                                <FaThList className="mr-2" style={{ margin: '0.2rem' }} />
                                                                <span className="dropdown-items">List View</span>
                                                            </div>
                                                            <div className="flex items-center dropdown-parents" style={{ backgroundColor: `${isGridView === true ? "#448995" : ""}`, color: `${isGridView === true ? "white" : "black"}` }} onClick={() => { handleGridViewClick(); handleOptionClick('Grid View'); arrowsetDropdownOpen(false); }}>
                                                                <BsFillGridFill className="mr-2" style={{ margin: '0.2rem' }} />
                                                                <span className="dropdown-items">Grid View</span>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="toggle-preview">
                                {showSnack &&
                                    <Snack
                                        item={item}
                                        show={showSnack}
                                        outputoftask={outputoftask}
                                        setShowSnack={setShowSnack}
                                        showSnack={showSnack}
                                        value={value}
                                        setValue={setValue}
                                        isContentView={isContentView}
                                        setContentView={setContentView}
                                        setChildView={setChildView}
                                        isChildView={isChildView}
                                        arrowsetDropdownOpen={arrowsetDropdownOpen}
                                        isarrowDropdownOpen={isarrowDropdownOpen}
                                    />
                                }
                            </div>
                            <div hidden={showSnack} className={isContentView || item?.tasks?.length === 0 ? 'card-listing setting-card text-on-image-menu contentview' : 'card-listing setting-card text-on-image-menu'} style={{ background: 'transparent', marginTop: (isContentView || item?.tasks?.length === 0 ? '22%' : '32%'), height: (isContentView || item?.tasks?.length === 0 ? '18.5rem' : '16rem'), padding: (showSnack ? '0' : '0 8px') }} >

                                {checkRolePermission(item) && isChildView ?
                                    item?.tasks?.length < 6 &&
                                    <Container className="floating-action-button execute-button" hidden={item?.tasks?.length < 6}>
                                        <Button
                                            style={{ color: "rgb(250, 250, 250)" }}
                                            className="fab-item btn btn-link btn-lg text-red"
                                            tooltip="Add Sub Task"
                                            onClick={() => { navigate(`/task/${getChildTaskId(item._id, taskId)}/create`) }} >
                                            <FiPlus />
                                        </Button>
                                    </Container>
                                    :
                                    checkRolePermission(item) &&
                                    <>{item.owner !== getCurrentUser()?.email ? <>
                                        <div className="floating-button" hidden={!isContentView || showSnack}>
                                            <Container className="floating-action-button">
                                                <Button
                                                    style={{ color: "rgb(250, 250, 250)" }}
                                                    className="fab-item btn btn-link btn-lg text-red"
                                                    tooltip="Execute Task"
                                                    onClick={() => { navigate(`/task/view/${getChildTaskId(item._id, taskId)}`) }}>
                                                    <AiFillEdit />
                                                </Button>
                                            </Container>
                                        </div>
                                    </> :
                                        <div className="floating-button" hidden={!isContentView || showSnack || activeTab}>
                                            <Container className="floating-action-button">
                                                <Link
                                                    tooltip="Copy Task">
                                                    <HiDuplicate
                                                        onClick={onhandlecopytask}
                                                        // onClick={() => { item.members.length > 0 ? navigate(`/task/members/${getChildTaskId(item._id, taskId)}`) : navigate(`/roles/all/${getChildTaskId(item._id, taskId)}`) }} 
                                                        className="flex flex-wrap" style={{ float: 'right', cursor: 'pointer' }}
                                                    /></Link>
                                                <Link
                                                    tooltip="Show Members">
                                                    <FaUsers onClick={() => { item.members.length > 0 ? navigate(`/task/members/${getChildTaskId(item._id, taskId)}`) : navigate(`/roles/all/${getChildTaskId(item._id, taskId)}`) }} className="flex flex-wrap" style={{ float: 'right', cursor: 'pointer' }} /></Link>
                                                <Link
                                                    tooltip="Output">
                                                    <MdQuestionAnswer className="flex flex-wrap" style={{ float: 'right', cursor: 'pointer' }} onClick={() => { checkRolePermission(item) ? navigate(`/task/execution/${getChildTaskId(item._id, taskId)}`) : setSnackbarMessage("You don't have permission !!") }} /></Link>
                                                <Button
                                                    style={{ color: "rgb(250, 250, 250)" }}
                                                    className="fab-item btn btn-link btn-lg text-red"
                                                    tooltip="Edit"
                                                    onClick={() => { navigate(`/task/${getEditTaskUrl(item._id, taskId)}`) }} >
                                                    <MdEditSquare />
                                                </Button>
                                            </Container>
                                        </div>}</>}
                                <div className="content-parent"
                                    // style={{marginBottom:'15.7rem'}}
                                    hidden={!activeTab || isChildView}>
                                    <View selectedRole={selectedRole} setSelectedRole={setSelectedRole} breadcrumbData={breadcrumbData} setbreadcrumbData={setbreadcrumbData} selectedRoleName={selectedRoleName} setSelectedRoleName={setSelectedRoleName} setActiveTab={setActiveTab} activeTab={activeTab} selectedValue={selectedValue} setSelectedValue={setSelectedValue} item={item} tasks={tasks} taskId={taskId} />
                                </div>
                                {isContentView ? (
                                    <div className="content-view" hidden={activeTab}>
                                        <LayoutPreview layout={item?.layout} />
                                    </div>
                                ) : isGridView && item?.tasks?.length > 0 ?
                                    (
                                        <div className="card-grid-view">
                                            {alltasks?.length > 0 && alltasks?.map((task) => (
                                                <>
                                                    {item.owner === getCurrentUser()?.email || task.members.some(el => el.email === getCurrentUser()?.email) ?
                                                        <div key={task._id} className="box">
                                                            <div className="task-box" style={{ width: '18.5rem' }}>
                                                                <div className="header" onClick={() => { setOpenDropdownId(null) }} ref={childRef}>
                                                                    {task.name}
                                                                    <AiFillSetting onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        toggleDropdown(task._id);
                                                                    }} />
                                                                    {openDropdownId === task._id && (
                                                                        <DropdownMenu task={task} navigate={navigate} handleListDeleteTask={handleListDeleteTask} item={item} getChildTaskId={getChildTaskId} taskId={taskId} isGridView={isGridView} />
                                                                    )}
                                                                </div>

                                                                <div className="content" style={{ height: '22rem' }}>
                                                                    <LayoutPreview layout={task?.layout} />
                                                                </div>
                                                            </div>
                                                        </div> : ''} </>
                                            ))}
                                        </div>
                                    ) : !isGridView && item?.tasks?.length > 0 ? (
                                        <><div className="card-list-view">
                                            <div className="list-box">
                                                <div className="task-box">
                                                    {/* <Accordion allowZeroExpanded style={{ border: '0', marginBottom: '5%', marginTop: '3%' }}>
                                                        {item.tasks.length > 0 && item.tasks.map((task) => (
                                                            <>{item.owner === getCurrentUser()?.email ||task.members.some(el => el.email === getCurrentUser()?.email) ? 
                                                            <AccordionItem key={task.id} onClick={() => {setOpenDropdownId(null); arrowsetDropdownOpen(false)}} style={{ borderTop: '0px', backgroundColor: '#6aa7ab'}}>
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton className="list-header" style={{ borderTop: '0px' }}>
                                                                        {task.name}
                                                                        <div className="list-icons-right" ref={childRef} style={{ display: 'flex', columnGap: '10px', fontSize: '18px' }}>
                                                                            <AiFillSetting onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                toggleDropdown(task.id);
                                                                            }} />
                                                                            {openDropdownId === task.id && (
                                                                                <DropdownMenu task={task} navigate={navigate} handleListDeleteTask={handleListDeleteTask} item={item} getChildTaskId={getChildTaskId} taskId={taskId} isGridView={isGridView}/>
                                                                            )}
                                                                        </div>
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel className="list-content-preview" style={{ backgroundColor: 'white', margin: 'auto', width: '19rem', height: '18vh', marginBottom: '5%' }}>
                                                                    <LayoutPreview layout={task?.layout} />
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                            : '' }</>
                                                        ))}
                                                    </Accordion> */}
                                                    <div className="App mt-4" style={{ marginTop: '-0.8rem' }}>
                                                        <DragDropContext onDragEnd={handleDragEnd}>
                                                            <div className="super parent">
                                                                <Droppable droppableId="droppable-1">
                                                                    {(provider) => (
                                                                        <div
                                                                            className="parent-list"
                                                                            ref={provider.innerRef}
                                                                            {...provider.droppableProps}
                                                                        >
                                                                            <Accordion allowZeroExpanded style={{ border: '0', marginBottom: '5%', marginTop: '3%' }}>
                                                                                {alltasks?.length > 0 && alltasks?.map((task, index) => (
                                                                                    <Draggable
                                                                                        key={task._id}
                                                                                        draggableId={task._id}
                                                                                        index={index}
                                                                                    >
                                                                                        {(provider) => (
                                                                                            <div className="list-draggable" {...provider.draggableProps} ref={provider.innerRef}>
                                                                                                <AccordionItem key={task._id} {...provider.draggableProps} ref={provider.innerRef} onClick={() => { setOpenDropdownId(null); arrowsetDropdownOpen(false) }} style={{ borderTop: '0px', backgroundColor: '#6aa7ab' }}>
                                                                                                    <AccordionItemHeading>
                                                                                                        <AccordionItemButton className="list-header" style={{ borderTop: '0px' }}>
                                                                                                            <div className="list-icon" {...provider.dragHandleProps}><RxDragHandleDots2 /></div>{task.name}
                                                                                                            <div className="list-icons-right" ref={childRef} style={{ display: 'flex', columnGap: '10px', fontSize: '18px' }}>
                                                                                                                <AiFillSetting onClick={(e) => {
                                                                                                                    e.stopPropagation();
                                                                                                                    toggleDropdown(task._id);
                                                                                                                }} />
                                                                                                                {openDropdownId === task._id && (
                                                                                                                    <DropdownMenu task={task} navigate={navigate} handleListDeleteTask={handleListDeleteTask} item={item} getChildTaskId={getChildTaskId} taskId={taskId} isGridView={isGridView} />
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </AccordionItemButton>
                                                                                                    </AccordionItemHeading>
                                                                                                    <AccordionItemPanel className="list-content-preview" style={{ backgroundColor: 'white', margin: 'auto', width: '18.6rem', height: '18vh', marginBottom: '5%' }}>
                                                                                                        <LayoutPreview layout={task?.layout} />
                                                                                                    </AccordionItemPanel>
                                                                                                </AccordionItem>
                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                ))}</Accordion>
                                                                            {provider.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </div>
                                                        </DragDropContext>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></>
                                    ) : !item?.tasks?.length > 0 &&
                                    <div className="no-preview">
                                        <div className="no-preview-child">No Subtask Found !!</div>
                                        {/* <div className="no-preview-add-subtask">
                                            {checkRolePermission(item) ?
                                                <button
                                                    title={'Add Subtask'}
                                                    style={{ backgroundColor: '#155f75', width: '6rem' }}
                                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 m-4 p-4 rounded"
                                                    onClick={() => { navigate(`/task/${getChildTaskId(item._id, taskId)}/create`) }} >Add Subtask</button>

                                                : null
                                            }
                                        </div> */}
                                    </div>
                                }
                                <div className="navbar" hidden={isChildView}>
                                    <NavbarFooter setSelectedRoleName={setSelectedRoleName} item={item} userEmailId={userEmailId} setActiveTab={setActiveTab} activeTab={activeTab} />
                                </div>
                            </div>
                            {/* {!isModalView && !isAcceptView &&
                                    <div className="card-listing setting-card text-on-image-menu">
                                    <div style={{opacity: checkRolePermission(item) ? '1' : '0.4'}} className="item" onClick={() => { checkRolePermission(item) && navigate(`/task/${getEditTaskUrl(item._id,taskId)}`) }}>
                                        <BiEdit style={{display:'inline'}}/>
                                        <span className="title">Edit</span>
                                        <AiOutlineArrowRight style={{display:'inline'}}/>
                                    </div>
                                    <div className="item" onClick={() => { !checkRolePermission(item) ? checkStatusName(item) === 'Accepted' ? navigate(`/task/${getEditTaskUrl(item._id,taskId)}`) : setSnackbarMessage("You don't have permission to access !!") : navigate(`/view/${getChildTaskId(item._id,taskId)}`) }}>
                                        <BsSendFill style={{display:'inline'}}/>
                                        <span className="title">Execute / Preview</span>
                                    </div>
                                    <div style={{opacity: checkRolePermission(item) ? '1' : '0.4'}} className="item">
                                        <BiSitemap style={{display:'inline'}}/>
                                        <span className="title">Sequence</span>
                                        {checkRolePermission(item) && 
                                        <span className="title-desc">
                                        <div contentEditable={true} suppressContentEditableWarning={true}>
                                        {"0"}
                                        </div></span>}
                                    </div>
                                    <div className="item">
                                        <GrStatusInfo style={{display:'inline'}}/>
                                        <span className="title">Invite Status</span>
                                        {!checkRolePermission(item) ? 
                                        <span className="title-desc" onClick={(e)=> checkStatusName(item) === 'Invited' && (checkInvitedStatus(item._id),setAcceptView(true))}>
                                            {checkStatusName(item)}
                                        </span> : <span onClick={()=>setModalView(true)} className="title-desc">{item?.members?.length}</span>}
                                    </div>
                                    <div style={{opacity: checkRolePermission(item) ? '1' : '0.4'}} className="item">
                                        <TbHierarchy style={{display:'inline'}}/>
                                        <span className="title">Add Dependencies</span>
                                    </div>
                                    <div style={{opacity: checkRolePermission(item) ? '1' : '0.4'}} className="item" onClick={() => {
                                        const currentTaskId = item._id;
                                        const parentTaskId = taskId;
                                        checkRolePermission(item) && 
                                        deleteUserTask(currentTaskId, parentTaskId);
                                        reload();
                                    }} >
                                        <AiFillDelete style={{display:'inline'}}/>
                                        <span className="title">Delete</span>
                                    </div>
                                    </div>} */}

                            {isAcceptView && tasks?.length && tasks.map((data, index) => {
                                return (data._id === selectedTask && isSelected &&
                                    <div key={index} className="card-listing setting-card text-on-image-menu">
                                        <div id="myModal" className="modal fade">
                                            <div className="modal-dialog modal-confirm">
                                                <div className="modal-content">
                                                    <div className="modal-body">
                                                        <p>Are you sure you want to accept this invitation ? </p>
                                                    </div>
                                                    <div className="modal-footer mt-3">
                                                        <button type="button" className="btn btn-secondary"
                                                            onClick={() => statusDecline()} data-dismiss="modal">No</button>
                                                        <button type="button" className="btn btn-primary" onClick={() => statusConfirmation()}>Yes</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                            {isModalView &&
                                <div className="card-listing-status setting-card text-on-image-menu">
                                    <div className="flex items-center justify-between mb-1">
                                        <h1 className="font-bold">Status Details</h1>
                                        <RxCross2 onClick={() => setModalView(false)} style={{ display: 'inline' }} />
                                    </div>
                                    <div className="table">
                                        <div className="row header blue">
                                            <div className="cell">
                                                Email
                                            </div>
                                            <div className="cell">
                                                Status
                                            </div>
                                        </div>
                                        {item?.members.length > 0 ? item?.members.map((data, index) => {
                                            return (
                                                <div className="row" key={index}>
                                                    <div className="cell" data-title="Username">
                                                        {data.email}
                                                    </div>
                                                    <div className="cell" data-title="Email">
                                                        {data.status === 'A' ? 'Accepted' : data.status === 'D' ? 'Declined' : 'Invited'}
                                                    </div>
                                                </div>)
                                        }) : 'No Users Found !!'}
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div >
            </div >

            <Snackbar
                autoHideDuration={2000}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={!!snackbarMessage}
                onClose={() => setSnackbarMessage(null)}
                message={<span>{snackbarMessage}</span>}
                className="z-10"
            />
        </>
    );
};

export default TaskCard;