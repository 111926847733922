import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addUser } from "../../services/UserService";
import { Button } from "../Reusables/Button";
import { TextInput } from "../Reusables/TextInput";
import { createUser } from "../../services/ApiServices/UserApiServices";
import "./AddUser.css"

export const AddUser = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact_number, setNumber] = useState("");
  const [parents_number, setparentsNumber] = useState("");
  const [address, setAddress] = useState("");
  const [isUserExists, setIsUserExists] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!email?.length && !name.length && !contact_number.length && !parents_number.length && !address.length) return;
    setIsUserExists(false);
    const payload = {
      name: e.target.name.value,
      email: e.target.email.value,
      contact_number: e.target.contact_number.value,
      parents_number: e.target.parents_number.value,
      address: e.target.address.value,
      id: Date.now(),
    };
    createUser(payload).then((data) => {
      if (data.result) {
        setEmail("");
        setName("");
        setNumber("");
        setparentsNumber("");
        setAddress("");
        navigate("/user/select");
      } else {
        setIsUserExists(true);
        setEmail("");
        setName("");
        setNumber("");
        setparentsNumber("");
        setAddress("");
      }
    })
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen bg-gradient-to-l from-slate-500 to-slate-100 main-layout">
      <div className="absolute top-4 left-4">
        <span
          onClick={() => navigate(-1)}
          className="cursor-pointer rounded-full bg-gray-400 p-2 top-0 left-0 w-8 h-8 flex flex-row items-center justify-center text-white border-2 border-white"
        >
          <i className="pi pi-angle-left" />
        </span>
      </div>
      <div className="container self-center rounded-md h-auto max-h-full bg-white justify-center p-4 overflow-auto w-auto min-w-[25%]">
        <div className="flex flex-col items-center justify-center px-5 mx-auto my-8 user-form-parent">
          <div className="max-w-md text-center">
            <p className="text-2xl font-semibold md:text-3xl">Add User</p>
            <p className="mt-4 mb-8 dark:text-gray-500">
              Please add a user to continue.
            </p>
            <form
              onSubmit={onSubmit}
              // className="flex flex-col items-center justify-center user-form"
              className="user-form"
            >
              <TextInput
                label="Name"
                placeholder="Enter User's Name"
                type="name"
                name="name"
                className="name"
                mandatory={true}
                id="name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                isSearchBar={true}
              />
              <TextInput
                label="Email"
                placeholder="Enter User's Email"
                type="email"
                className="email"
                name="email"
                mandatory={true}
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                isSearchBar={true}
              />
              <TextInput
                label="Mobile Number"
                placeholder="Enter User's Number"
                type="number"
                name="mobileNumber"
                className="mobileNumber"
                minLength="10"
                mandatory={true}
                id="contact_number"
                onChange={(e) => setNumber(e.target.value)}
                value={contact_number}
                isSearchBar={true}
              />
              <TextInput
                label="Parents Contact Number"
                placeholder="Enter Parents Number"
                type="number"
                name="parents_number"
                className="parents_number"
                mandatory={true}
                id="parents_number"
                onChange={(e) => setparentsNumber(e.target.value)}
                value={parents_number}
                isSearchBar={true}
              />
              <TextInput
                label="Address"
                placeholder="Enter User's Address"
                type="textarea"
                name="address"
                className="text-address"
                mandatory={true}
                id="address"
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                isSearchBar={true}
              />
              <Button
                type="submit"
                label="Add User"
                // className="submit-btn"
                className="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 m-4 p-4 rounded disabled:opacity-50 flex items-center submit-btn"
                disabled={!email?.length}
                children={<i className="mx-2 pi pi-plus" />}
              />
            </form>
            {isUserExists && (
              <p className="m-2 text-red-400 text-sm">User already exists.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
