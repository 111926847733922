import React, { forwardRef } from "react";

export const TextInput = forwardRef((props, ref) => {
  const {
    className,
    label,
    mandatory,
    id,
    isSearchBar,
    labelClassName,
    ...restProps
  } = props;
  return (
    <div className="flex flex-col">
      {label && (
        <label
          htmlFor={id}
          className={`text-slate-500 bg-white rounded-sm relative left-4 top-2 px-1 flex text-xs w-fit ${
            labelClassName ? labelClassName : ""
          }`}
        >
          {label}
          {mandatory && <span className="text-red-500">*</span>}
        </label>
      )}
      {/* {isSearchBar && <FiSearch className="relative top-8" />} */}
      <input
        className={`border border-slate-500 rounded-md p-2 active:outline-none focus:outline-none focus:ring-2 focus:ring-slate-500 focus:border-transparent ${
          className ? className : ""
        }`}
        ref={ref}
        id={id}
        {...restProps}
      />
    </div>
  );
});
