import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { publicRoutes } from "./routes-config";

const AppRoutes = () => {
  const router = createBrowserRouter(publicRoutes);
  return <RouterProvider router={router} />;
};

export default AppRoutes;
