import React, { useEffect, useState, useRef } from "react";
import "./View.css";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../../services/UserService";
import { getChildTaskId, getEditTaskUrl } from "../../utils";
import { getOutput, getUserAllOutput } from "../../services/OutputService";
import { getRoles } from "../../services/RoleService";
import { BsFillGridFill } from "react-icons/bs";
import { FaThList, FaPlus, FaUserAlt, FaUsers, FaUser } from "react-icons/fa";
import { MdEditSquare, MdManageAccounts, MdOutlineCancel, MdPreview } from "react-icons/md";
import { IoIosArrowDropdown, IoMdAddCircleOutline, IoIosSearch } from "react-icons/io";
import { AiOutlineArrowRight, AiFillEdit, AiFillSetting, AiFillFileAdd, AiFillFolderOpen, AiFillPlusCircle, AiFillDelete, AiOutlinePlus, AiFillHome } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import { HiArrowSmRight, HiArrowSmDown, HiInformationCircle, HiOutlineMail } from "react-icons/hi";
import { RiEditBoxFill, RiParentFill } from "react-icons/ri";
import { ImCancelCircle, ImPhone } from "react-icons/im";
import { MdOutlineZoomOutMap, MdOutlineArrowBack } from "react-icons/md";
import { LayoutPreview } from "../TaskMaker/LayoutPrieview";
import { Container, Link, Button as Btn } from 'react-floating-action-button';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import DropdownMenu from "../Reusables/Dropdown";
import { allUser } from "../../services/ApiServices/UserApiServices";
import DebounceInput from 'react-debounce-input';


const View = ({ selectedRole, setSelectedRole, item, tasks, taskId, setActiveTab, activeTab, selectedValue, setSelectedValue, setSelectedRoleName, selectedRoleName, breadcrumbData, setbreadcrumbData }) => {
    const [selectedTask, setSelectedTask] = useState();
    const [snackbarMessage, setSnackbarMessage] = useState();
    let outputData = getOutput();
    let rolePermission = getRoles();
    const navigate = useNavigate();
    const [isGridView, setIsGridView] = useState(false);
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [isarrowDropdownOpen, arrowsetDropdownOpen] = useState(false);
    const [value, setValue] = useState(false);
    const outputoftask = getUserAllOutput(item.id);
    const parentRef = useRef(null);
    const childRef = useRef(null);
    const [membersWithTasks, setMembersWithTasks] = useState(item.members);
    const [memberStatusWithTasks, setMembersStatusWithTasks] = useState(item.members);
    const [membersOutput, setmembersOutput] = useState();

    const handleGridViewClick = () => {
        setIsGridView(true);
        arrowsetDropdownOpen(false);
    };

    const handleListViewClick = () => {
        setIsGridView(false);
        arrowsetDropdownOpen(false);
    };

    const handleOptionClick = (value) => {
        setSelectedValue(value);
    };

    const arrowtoggleDropdown = () => {
        arrowsetDropdownOpen(!isarrowDropdownOpen);
    };

    const toggleDropdown = (taskId) => {
        setOpenDropdownId((prevId) => (prevId === taskId ? null : taskId));
    };

    const checkRolePermission = (item) => {
        return (item?.owner === getCurrentUser()?.email || item?.members?.find(
            (m) =>
                (m?.email?.toLocaleLowerCase() ===
                    getCurrentUser()?.email?.toLocaleLowerCase()) && m?.roleId._id === (rolePermission.find((data) => data?._id === m?.roleId._id && data?.permissions?.includes('Owner'))?._id || rolePermission.find((data) => data?._id === m?.roleId._id && data?.permissions.includes('Executor'))?._id)
        ))
    }

    const updatemembersOutput = membersWithTasks.reduce((result, item) => {
        const roleId = item.roleId._id;
        const roleName = item.roleId.name;
        const member = {
            _id: item.userId._id,
            name: item.userId.name,
            email: item.userId.email,
            ...(item.userId.contact_number && { contact_number: item.userId.contact_number }),
            ...(item.userId.parents_number && { parents_number: item.userId.parents_number }),
            ...(item.userId.address && { address: item.userId.address })
        };

        const existingRole = result.find(role => role.name === roleName);

        if (existingRole) {
            existingRole.members.push(member);
        } else {
            result.push({
                _id: roleId,
                name: roleName,
                members: [member]
            });
        }

        return result;
    }, []);

    const handleActiveTab = () => {
        setActiveTab(null);
        setSelectedRoleName(null);
        setbreadcrumbData([{"name":null,"child":null}])
    };

    useEffect(() => {
        setSelectedRole(null);
        setMembersWithTasks(item.members);
        setmembersOutput(updatemembersOutput)
    }, [activeTab, isGridView, !isGridView]);

    const HeaderGUI = ({ name, selectedRole, membersOutput, parentRef, setActiveTab, activeTab, isarrowDropdownOpen, arrowtoggleDropdown, setOpenDropdownId, isGridView, handleListViewClick, handleGridViewClick, handleOptionClick, arrowsetDropdownOpen, membersWithTasks, setMembersWithTasks }) => {
        const [searchTerm, setSearchTerm] = useState('');

        const backButtonTask = () => {
            setSelectedRole(null)
            setSelectedRoleName(null);
        }

        const handleInputChange = (event) => {
            setSearchTerm(event.target.value)
            if (name === 'role' && selectedRole === null) {
                const filteredData = membersOutput.filter((data) => {
                    return data?.name?.toLowerCase().includes(event.target.value.toLowerCase())
                });

                setTimeout(() => {
                    setmembersOutput(filteredData);
                }, 1000);
            }
            else if (name === 'role' && selectedRole !== null) {
                let checkMembers = membersOutput.find((data) => data._id == selectedRole)

                const filteredData = checkMembers?.members.length > 0 && checkMembers?.members?.filter((data) => {
                    return data?.name?.toLowerCase().includes(event.target.value.toLowerCase())
                });

                const updatedObject = {
                    ...checkMembers,
                    members: filteredData
                };

                setTimeout(() => {
                    setmembersOutput([updatedObject]);
                }, 1000);
            }
            else if (name === 'communication') {
                const filteredData = item.members.filter((data) => {
                    return data?.userId?.name?.toLowerCase().includes(event.target.value.toLowerCase())
                });
                setTimeout(() => {
                    setMembersWithTasks(filteredData);
                }, 1000);
            }
        };

        return (
            <><div className="head-btn parent-button">
                {selectedRole !== undefined && selectedRole !== null &&
                    <div className="back-icon">
                        <MdOutlineArrowBack onClick={() => backButtonTask()} />
                    </div>
                }
                <div className="search-icon">
                    <div className="search-icon-preview">
                        <DebounceInput
                            minLength={2}
                            debounceTimeout={100}
                            onChange={(e) => handleInputChange(e)}
                            value={searchTerm}
                            className="search-input" placeholder="Search..."
                        />
                    </div>
                </div>
                <div className="dropdown-icon-parent">
                    <IoIosArrowDropdown onClick={() => { arrowtoggleDropdown(); setOpenDropdownId(null) }} />
                    {isarrowDropdownOpen && (
                        <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1" style={{ width: '8.5rem' }}>
                            <div className="py-1" role="none">
                                <span className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-1" style={{ lineHeight: '1.75rem' }}>
                                    <div className="flex items-center dropdown-icon-paren" style={{ backgroundColor: `${!isGridView === true ? "#448995" : ""}`, color: `${!isGridView === true ? "white" : "black"}` }} onClick={() => { handleListViewClick(); handleOptionClick('List View'); arrowsetDropdownOpen(false); }}>
                                        <FaThList className="mr-2" style={{ margin: '0.2rem' }} />
                                        <span className="dropdown-items">List View</span>
                                    </div>
                                    <div className="flex items-center dropdown-icon-paren" style={{ backgroundColor: `${isGridView === true ? "#448995" : ""}`, color: `${isGridView === true ? "white" : "black"}` }} onClick={() => { handleGridViewClick(); handleOptionClick('Grid View'); arrowsetDropdownOpen(false); }}>
                                        <BsFillGridFill className="mr-2" style={{ margin: '0.2rem' }} />
                                        <span className="dropdown-items">Grid View</span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    )}
                    <MdOutlineCancel onClick={() => handleActiveTab()} />
                </div>
            </div></>
        )
    }

    const GridGUI = ({ membersOutput, membersWithTasks, name, selectedRole, setSelectedRole, setSelectedRoleName, selectedRoleName }) => {
        const selectRoleFunc = (data) => {
            setSelectedRole(data._id);
            setSelectedRoleName(data.name)
        }
        return (
            name === 'role' && selectedRole === null ?
                <div className="content-child">
                    <div className="content-preview">
                        <div className="content-role-box">
                            {membersOutput.length === 0 && (
                                <div className="view-no-preview-no-data">
                                    <div className="no-preview-child">No Data Found !!</div>
                                </div>
                            )}
                            {membersOutput.map((data, index) =>
                                <div key={index} className="content-role-child-parent">
                                    <div className="content-role-header-name">
                                        {data.name}</div>
                                    <div className="content-role-data" onClick={() => selectRoleFunc(data)}>
                                        {`${data.members.length}`}
                                    </div>
                                </div>
                            )
                            }
                        </div>
                    </div>
                </div>
                : name === 'role' && selectedRole !== null ?
                    (
                        <div className="content-child">
                            <div className="content-preview">
                                <div className="content-role-box">
                                    {membersOutput[0].members.length === 0 && (
                                        <div className="view-no-preview-no-data">
                                            <div className="no-preview-child">No Data Found !!</div>
                                        </div>
                                    )}
                                    {membersOutput?.map((member, index) => (
                                        member._id === selectedRole && member.members.length > 0 ? member.members.map((data, index) => {
                                            return (
                                                <div key={index} className="content-role-child-subchild">
                                                    <div className="content-role-header-name">{data.name}</div>
                                                    <div className="content-role-data-subchild">
                                                        <span className="flex"><HiOutlineMail className="mr-4 flex-shrink-0" />: {data.email}</span>
                                                        <span className="flex"><ImPhone className="mr-4 flex-shrink-0" />: {data.contact_number}</span>
                                                        <span className="flex"><RiParentFill className="mr-4 flex-shrink-0" />: {data.parents_number}</span>
                                                        <span className="flex"><AiFillHome className="mr-4 flex-shrink-0" />: {data.address}</span>
                                                    </div>
                                                </div>
                                            )
                                        }) : ''
                                    ))}
                                </div>
                            </div>
                        </div>
                    )
                    : name === 'communication' ?
                        <div className="content-child">
                            <div className="content-preview">
                                <div className="content-role-box">
                                    {membersWithTasks.length === 0 && (
                                        <div className="view-no-preview-no-data">
                                            <div className="no-preview-child">No Data Found !!</div>
                                        </div>
                                    )}
                                    {membersWithTasks.map((data, index) =>
                                        <div key={index} className="content-role-child-communication">
                                            <div className="content-role-header-name">{data.userId.name}</div>
                                            <div className="content-role-data-communication">
                                                <span className="flex"><HiOutlineMail className="mr-4 flex-shrink-0" />: {data.userId.email}</span>
                                                <span className="flex"><ImPhone className="mr-4 flex-shrink-0" />: {data.userId.contact_number}</span>
                                                <span className="flex"><RiParentFill className="mr-4 flex-shrink-0" />: {data.userId.parents_number}</span>
                                                <span className="flex"><AiFillHome className="mr-4 flex-shrink-0" />: {data.userId.address}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        : null
        )
    }

    const ListGUI = ({ membersOutput, membersWithTasks, name, selectedRole, setSelectedRole, setSelectedRoleName, selectedRoleName, setMembersStatusWithTasks, memberStatusWithTasks }) => {

        const output = memberStatusWithTasks?.length > 0 && memberStatusWithTasks?.map(obj => {
            if (obj.status === "I") {
              obj.status = "Invited";
            }
            return obj;
          });
          
        const finalOutput = [{"Invited": output}];

        const selectRoleFunc = (data) => {
            setSelectedRole(data._id);
            setSelectedRoleName(data.name)
        }

        return (
            name === 'role' && selectedRole === null ?
                <div className="view-card-list-view">
                    <div className="view-list-box">
                        <div className="task-box">
                            <div className="view-App mt-4">
                                {membersOutput?.length === 0 && (
                                    <div className="view-no-preview-no-data">
                                        <div className="no-preview-child">No Data Found !!</div>
                                    </div>
                                )}
                                <Accordion allowZeroExpanded style={{ border: '0', marginBottom: '5%', marginTop: '3%' }}>
                                    {membersOutput?.map((data, index) =>
                                        <div key={index} >
                                            <AccordionItem onClick={() => { setOpenDropdownId(null); arrowsetDropdownOpen(false) }} style={{ borderTop: '0px', backgroundColor: '#6aa7ab' }}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton className="view-list-header" style={{ borderTop: '0px', padding: '0.3rem' }}>
                                                        {data.name}
                                                        <div className="view-list-icons-right" style={{ display: 'flex', columnGap: '10px', fontSize: '18px' }}>
                                                            {openDropdownId === data._id && (
                                                                <DropdownMenu data={data} navigate={navigate} item={item} getChildTaskId={getChildTaskId} taskId={taskId} isGridView={isGridView} />
                                                            )}
                                                        </div>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel onClick={() => selectRoleFunc(data)} className="list-content-preview" style={{ backgroundColor: 'white', margin: 'auto', width: '18.6rem', height: '7vh', marginBottom: '5%', padding: '0.3rem' }}>
                                                    Assigned Users : {`${data.members.length}`}
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        </div>
                                    )}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
                : name === 'role' && selectedRole !== null ?
                    (
                        <div className="view-card-list-view">
                            <div className="view-list-box">
                                <div className="task-box">
                                    <div className="view-App mt-4">
                                        {membersOutput[0].members.length === 0 && (
                                            <div className="view-no-preview-no-data">
                                                <div className="no-preview-child">No Data Found !!</div>
                                            </div>
                                        )}
                                        <Accordion allowZeroExpanded style={{ border: '0', marginBottom: '5%', marginTop: '3%' }}>
                                            {membersOutput?.map((member, index) => (
                                                member._id === selectedRole && member.members.length > 0 ? member.members.map((data, index) => {
                                                    return (
                                                        <div key={index} >
                                                            <AccordionItem onClick={() => { setOpenDropdownId(null); arrowsetDropdownOpen(false) }} style={{ borderTop: '0px', backgroundColor: '#6aa7ab' }}>
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton className="view-list-header" style={{ borderTop: '0px' }}>
                                                                        {data.name}
                                                                        <div className="view-list-icons-right" style={{ display: 'flex', columnGap: '10px', fontSize: '18px' }}>
                                                                            {openDropdownId === member._id && (
                                                                                <DropdownMenu data={data} navigate={navigate} item={item} getChildTaskId={getChildTaskId} taskId={taskId} isGridView={isGridView} />
                                                                            )}
                                                                        </div>
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel className="list-content-preview-roles">
                                                                    <span className="flex"><HiOutlineMail className="mr-4 flex-shrink-0" />: {data.email}</span>
                                                                    <span className="flex"><ImPhone className="mr-4 flex-shrink-0" />: {data.contact_number}</span>
                                                                    <span className="flex"><RiParentFill className="mr-4 flex-shrink-0" />: {data.parents_number}</span>
                                                                    <span className="flex"><AiFillHome className="mr-4 flex-shrink-0" />: {data.address}</span>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                        </div>
                                                    )
                                                }) : ''
                                            ))}
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    : name === 'communication' ?
                        <div className="view-card-list-view">
                            <div className="view-list-box">
                                <div className="task-box">
                                    <div className="view-App mt-4">
                                        {membersWithTasks.length === 0 && (
                                            <div className="view-no-preview-no-data">
                                                <div className="no-preview-child">No Data Found !!</div>
                                            </div>
                                        )}
                                        <Accordion allowZeroExpanded style={{ border: '0', marginBottom: '5%', marginTop: '3%' }}>
                                            {membersWithTasks.map((data, index) =>
                                                <div key={index}>
                                                    <AccordionItem onClick={() => { setOpenDropdownId(null); arrowsetDropdownOpen(false) }} style={{ borderTop: '0px', backgroundColor: '#6aa7ab' }}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton className="view-list-header" style={{ borderTop: '0px' }}>
                                                                {data.userId.name}
                                                                <div className="view-list-icons-right" style={{ display: 'flex', columnGap: '10px', fontSize: '18px' }}>
                                                                    {openDropdownId === data.userId._id && (
                                                                        <DropdownMenu membersWithTasks={membersWithTasks} navigate={navigate} item={item} getChildTaskId={getChildTaskId} taskId={taskId} isGridView={isGridView} />
                                                                    )}
                                                                </div>
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel className="list-content-preview-communication">
                                                            <span className="flex"><HiOutlineMail className="mr-4 flex-shrink-0" />: {data.userId.email}</span>
                                                            <span className="flex"><ImPhone className="mr-4 flex-shrink-0" />: {data.userId.contact_number}</span>
                                                            <span className="flex"><RiParentFill className="mr-4 flex-shrink-0" />: {data.userId.parents_number}</span>
                                                            <span className="flex"><AiFillHome className="mr-4 flex-shrink-0" />: {data.userId.address}</span>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                </div>
                                            )}
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : name === 'status' ? <div className="view-card-list-view">
                        <div className="view-list-box">
                            <div className="task-box">
                                <div className="view-App mt-4">
                                    {finalOutput.length === 0 && (
                                        <div className="view-no-preview-no-data">
                                            <div className="no-preview-child">No Data Found !!</div>
                                        </div>
                                    )}
                                    <Accordion allowZeroExpanded style={{ border: '0', marginBottom: '5%', marginTop: '3%' }}>
                                        {finalOutput.map((data, index) =>
                                            <div key={index}>
                                                {data['Invited'] !== undefined && <>
                                                <AccordionItem onClick={() => { setOpenDropdownId(null); arrowsetDropdownOpen(false) }} style={{ borderTop: '0px', backgroundColor: '#6aa7ab' }}>
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton className="view-list-header" style={{ borderTop: '0px' }}>
                                                                        Invited Users - {data['Invited'].length}
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel className="list-content-preview-roles">
                                                                    {data['Invited'].length > 0 && data['Invited'].map((data)=> {
                                                                        return <><span className="flex"><FaUser className="mr-4 flex-shrink-0" />: {data.email}</span></>
                                                                    })}
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                </>}
                                            </div>
                                        )}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div> : null
        )
    }

    useEffect(() => {
        setmembersOutput(updatemembersOutput);
    }, [])

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isarrowDropdownOpen && parentRef.current && !parentRef.current.contains(e.target)) {
                arrowsetDropdownOpen(null);
            }
        };

        document.addEventListener("click", checkIfClickedOutside);

        return () => {
            document.removeEventListener("click", checkIfClickedOutside);
        };
    }, [isarrowDropdownOpen]);

    return (
        <div className="content" >
            {activeTab === "roles" &&
                <div className="roles">
                    {item.members?.length > 0 &&
                        <HeaderGUI name={"role"} membersOutput={membersOutput} parentRef={parentRef} isarrowDropdownOpen={isarrowDropdownOpen} isGridView={isGridView} arrowtoggleDropdown={arrowtoggleDropdown} setOpenDropdownId={setOpenDropdownId} handleListViewClick={handleListViewClick} handleGridViewClick={handleGridViewClick} handleOptionClick={handleOptionClick} arrowsetDropdownOpen={arrowsetDropdownOpen} setMembersWithTasks={setMembersWithTasks} selectedRole={selectedRole} setSelectedRole={setSelectedRole} />
                    }
                    {isGridView && item.members?.length > 0 ? (
                        <GridGUI membersOutput={membersOutput} selectedRole={selectedRole} setSelectedRole={setSelectedRole} name={"role"} setSelectedRoleName={setSelectedRoleName} selectedRoleName={selectedRoleName} />
                    ) : !isGridView && item.members?.length > 0 ? (
                        <ListGUI membersOutput={membersOutput} selectedRole={selectedRole} setSelectedRole={setSelectedRole} name={"role"} setSelectedRoleName={setSelectedRoleName} selectedRoleName={selectedRoleName} />
                    ) : !item.members?.length > 0 &&
                    <div className="view-no-preview">
                        <div className="no-preview-child">No Roles Found !!</div>
                    </div>
                    }
                </div>
            }

            {activeTab === "communication" &&
                <div className="communication">
                    {item.members?.length > 0 &&
                        <HeaderGUI name={"communication"} membersWithTasks={membersWithTasks} parentRef={parentRef} isarrowDropdownOpen={isarrowDropdownOpen} isGridView={isGridView} arrowtoggleDropdown={arrowtoggleDropdown} setOpenDropdownId={setOpenDropdownId} handleListViewClick={handleListViewClick} handleGridViewClick={handleGridViewClick} handleOptionClick={handleOptionClick} arrowsetDropdownOpen={arrowsetDropdownOpen} setMembersWithTasks={setMembersWithTasks} selectedRole={selectedRole} setSelectedRole={setSelectedRole} />
                    }
                    {isGridView && item.members.length > 0 ? (
                        <GridGUI membersWithTasks={membersWithTasks} name={"communication"} />
                    ) : !isGridView && item.members.length > 0 ? (
                        <ListGUI membersWithTasks={membersWithTasks} name={"communication"} />
                    ) : !item.members?.length > 0 &&
                    <div className="view-no-preview">
                        <div className="no-preview-child">No Users Found !!</div>
                    </div>
                    }
                </div>
            }

            {activeTab === "status" &&
                <div className="status">
                    {item.members?.length > 0 &&
                        <HeaderGUI parentRef={parentRef} isarrowDropdownOpen={isarrowDropdownOpen} isGridView={isGridView} arrowtoggleDropdown={arrowtoggleDropdown} setOpenDropdownId={setOpenDropdownId} handleListViewClick={handleListViewClick} handleGridViewClick={handleGridViewClick} handleOptionClick={handleOptionClick} arrowsetDropdownOpen={arrowsetDropdownOpen} setMembersWithTasks={setMembersWithTasks} memberStatusWithTasks={memberStatusWithTasks} setMembersStatusWithTasks={setMembersStatusWithTasks} />
                    }
                    {isGridView && item.members.length > 0 ? (
                        <GridGUI membersWithTasks={membersWithTasks} name={"status"} memberStatusWithTasks={memberStatusWithTasks} setMembersStatusWithTasks={setMembersStatusWithTasks} />
                    ) : !isGridView && item.members.length > 0 ? (
                        <ListGUI membersWithTasks={membersWithTasks} name={"status"} memberStatusWithTasks={memberStatusWithTasks} setMembersStatusWithTasks={setMembersStatusWithTasks} />
                    ) : !item.members?.length > 0 &&
                    <div className="view-no-preview">
                        <div className="no-preview-child">No Data Found !!</div>
                    </div>
                    }
                </div>
            }

            {activeTab === "type" &&
                <div>Type Content</div>
            }
        </div>
        //   </div >
    );
};

export default View;