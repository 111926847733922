import React from "react";

export const Button = ({ className, label, children, ...props }) => {
  return (
    <button
      className={
        className
          ? className
          : "bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 m-4 p-4 rounded disabled:opacity-50 flex items-center"
      }
      {...props}
    >
      {label}
      {children}
    </button>
  );
};
